import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios.config';
import { ServerError } from 'lms-types';
import { addNotification, addLmsError } from 'store/slices/globalSlice';
import { defaultErrorMessage } from 'Constants';
import { RequestDisbursementResponse } from './RequestDisbursementModalThunk.types';
import { RequestDisbursementForm } from '../ReqeustDisbursementModal.types';

export const requestDisbursement = createAsyncThunk<
  RequestDisbursementResponse,
  { loanId: string; body: RequestDisbursementForm },
  {
    rejectValue: null;
  }
>(
  'disbursementsSlice/requestDisbursement',
  async ({ loanId, body }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post(
        `/loans/${loanId}/disbursementRequests`,
        body,
      )) as AxiosResponse<RequestDisbursementResponse>;

      dispatch(
        addNotification({
          severity: 'success',
          message: 'Disbursement requested successfully!',
        }),
      );

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      if (serverError.response?.data?.error?.displayError !== undefined) {
        dispatch(addLmsError(serverError.response?.data?.error?.displayError));
      } else {
        dispatch(
          addNotification({
            severity: 'error',
            message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
          }),
        );
      }
      return rejectWithValue(null);
    }
  },
);
