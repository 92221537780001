import { GroupedFieldsType } from '../../CustomerAndLoanModal.types';

export const initialFormData = {
  name: '',
  companyIdentifier: '',
  loanOfficerUserId: '',
  principalAmount: '',
  interestAmount: '0',
  loanType: 'RBF',
  rbfRevenueShare: '',
  gracePeriodDays: '',
  tenor: '',
  penaltyPercent: '',
  penaltyType: '',
  monthlyRate: '',
  interestAllocationType: 'INTEREST_UPFRONT',
  repaymentType: '',
  invoicesAmount: '',
  invoicesDiscountRate: '',
  annualRate: '',
  firstInstalmentDate: '',
};

export const CURRENCY_PLACEHOLDER = '{currency}';

export type FormDataType = typeof initialFormData;

export const groupedFields: GroupedFieldsType<FormDataType> = {
  'General data': [
    { name: 'companyIdentifier', label: 'Company Id', type: 'select' },
    { name: 'loanOfficerUserId', label: 'Owner', type: 'select' },
  ],
  'Loan data': [
    { name: 'name', label: 'Loan name', type: 'text' },
    { name: 'loanType', label: 'Loan Type', type: 'select' },
    { name: 'principalAmount', label: `Principal(${CURRENCY_PLACEHOLDER})`, type: 'number' },
    { name: 'monthlyRate', label: 'Monthly Rate(%)', type: 'number' },
    { name: 'annualRate', label: 'Annual Percentage Rate', type: 'number' },
    { name: 'interestAmount', label: 'Interest', type: 'number' },
    { name: 'invoicesAmount', label: 'Invoice(s)', type: 'number' },
    { name: 'invoicesDiscountRate', label: 'Invoice Discount Rate', type: 'number' },
    { name: 'tenor', label: 'Tenor(days)', type: 'number' },
    { name: 'rbfRevenueShare', label: 'Revenue Share(%)', type: 'number' },
    { name: 'interestAllocationType', label: 'Interest Allocation', type: 'select' },
    { name: 'repaymentType', label: 'Repayment Type', type: 'select' },
    {
      name: 'firstInstalmentDate',
      label: 'First instalment date',
      type: 'text',
      inputType: 'date',
    },
  ],
  'Penalty data': [
    { name: 'penaltyType', label: 'Penalty Type', type: 'select' },
    { name: 'penaltyPercent', label: 'Penalty(%)', type: 'number' },
    { name: 'gracePeriodDays', label: 'Grace Period(days)', type: 'number' },
  ],
};
