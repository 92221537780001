import { styled } from '@mui/material';

export const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;

  & > div {
    border: 1px solid ${(props) => props.theme.palette.grey[900]};
    border-radius: 4px;
    padding: 2rem;
  }
`;

export const CreditScoreWrapper = styled('div')`
  grid-column: 1/4;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const CreditLimitWrapper = styled('div')`
  grid-column: 4/-1;
`;

export const IbfLimitWrapper = styled('div')`
  grid-column: 1/7;
`;

export const RbfLimitWrapper = styled('div')`
  grid-column: 7/-1;
`;
