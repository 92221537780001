import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getAllCompanies } from './bankStatements.thunk';
import { Company } from 'bst-types';

interface BankStatmentsState {
  isLoading: boolean;
  companies: Array<Company>;
}

const initialState: BankStatmentsState = {
  isLoading: false,
  companies: [],
};

const bankStatementsSlice = createSlice({
  name: 'bankStatementsSlice',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(getAllCompanies.fulfilled, (state, action) => {
      state.companies = action.payload.companies;
    });
  },
});

export const { setIsLoading } = bankStatementsSlice.actions;

export default bankStatementsSlice.reducer;

// Selectors
export const bankStatementsSliceSelector = (state: RootState) => state.bankStatementsSlice;

export const isBankStatementsLoadingSelector = createSelector(
  bankStatementsSliceSelector,
  (state) => state.isLoading,
);

export const bankingStatementCompaniesSelector = createSelector(
  bankStatementsSliceSelector,
  (state) => state.companies,
);
