import { ModalHeader, ModalFooter } from 'components';
import { Modal } from '@mui/material';
import { Props } from './LmsModal.types';
import { Wrapper, Content } from './LmsModal.styles';

export const LmsModal = ({ open, headerProps, footerProps, children, wrapperProps }: Props) => {
  return (
    <Modal open={open}>
      <Wrapper {...wrapperProps}>
        <ModalHeader {...headerProps} />
        <Content>{children}</Content>
        <ModalFooter {...footerProps} />
      </Wrapper>
    </Modal>
  );
};
