import { LmsModal, TextField, Typography } from 'components';
import { ReconcilePaymentModalProps } from './ReconcilePaymentModal.types';
import { formData as initialFormData } from './ReconcilePaymentModal.config';
import { useDispatch, useFormData, useSelector } from 'hooks';
import { reconcileNewPayment, validateReconcileField } from './store/reconcilePaymentSlice.thunk';
import { detailedLoanSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';
import { CurrenciesMap } from 'Constants';
import emptyStringToNull from 'utils/emptyStringToNull';
import { useState } from 'react';

export const ReconcilePaymentModal = ({ isOpen, onClose }: ReconcilePaymentModalProps) => {
  const dispatch = useDispatch();
  const {
    formData,
    handleChange,
    setFieldError,
    fieldHelpers,
  } = useFormData(initialFormData);
  const loan = useSelector(detailedLoanSelector);
  const [isLoading, setIsLoading] = useState(false);

  type FormField = keyof typeof formData;

  const removeValidationError = (field: keyof typeof formData) => () => {
    setFieldError(field);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const replacedFormData = emptyStringToNull(formData);

      const { payload } = await dispatch(validateReconcileField(replacedFormData));

      const payloadKeys = payload ? Object.keys(payload) : [];

      if (payloadKeys.length) {
        payloadKeys.map((key) => {
          setFieldError(key as FormField, payload ? payload[key] : undefined);
        });
        setIsLoading(false);
      } else {
        const { payload } = await dispatch(reconcileNewPayment(formData));

        if (payload) {
          onClose?.();
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LmsModal
      open={isOpen}
      wrapperProps={{ width: '25rem', height: '45vh' }}
      headerProps={{
        onClose,
        title: 'Reconcile a payment',
      }}
      footerProps={{ label: 'Save payment', onClick: handleSubmit, isLoading }}
    >
      <TextField
        fullWidth
        name='amount'
        label='Amount'
        onChange={handleChange}
        value={formData.amount}
        type='number'
        inputProps={{ step: 0.1 }}
        endIcon={
          <Typography variant='body2' sx={{ mr: '.5rem', color: 'grey.700' }}>
            {CurrenciesMap[loan.currency]}
          </Typography>
        }
        error={Boolean(fieldHelpers.amount)}
        helperText={fieldHelpers.amount}
        onFocus={removeValidationError('amount')}
      />
      <TextField
        fullWidth
        name='documentNumber'
        label='Document Number'
        onChange={handleChange}
        value={formData.documentNumber}
        error={Boolean(fieldHelpers.documentNumber)}
        helperText={fieldHelpers.documentNumber}
        onFocus={removeValidationError('documentNumber')}
      />
      <TextField
        fullWidth
        name='paymentDate'
        label='Payment Date'
        onChange={handleChange}
        value={formData.paymentDate}
        type='date'
        error={Boolean(fieldHelpers.paymentDate)}
        helperText={fieldHelpers.paymentDate}
        onFocus={removeValidationError('paymentDate')}
      />
    </LmsModal>
  );
};
