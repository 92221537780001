import { Box } from '@mui/material';
import { AuthLayout, Loader } from 'components';
import { LogIn, NewPassword } from 'pages';
import { createBrowserRouter } from 'react-router-dom';

export const authRouter = createBrowserRouter([
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      { index: true, element: <LogIn /> },
      {
        path: 'new-password',
        element: <NewPassword />,
      },
      {
        path: '*',
        element: (
          <Box height='100vh'>
            <Loader />
          </Box>
        ),
      },
    ],
  },
]);
