import { OptionType } from 'components/common/Select/Select.types';

const curDate = new Date();
const curYear = curDate.getFullYear();
const curMonth = curDate.getMonth().toString().padStart(2, '0');

export const initialFormData = {
  amount: '',
  month: curMonth,
  year: curYear,
};

const yearsOpts: Array<OptionType> = [];

for (let i = -2; i <= 1; i++) {
  const pastYear = curYear + i;
  yearsOpts.push({ name: String(pastYear), value: pastYear });
}

export default yearsOpts;
