import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { CustomerWithLinks } from 'lms-types';
import { RootState } from 'store';
import { getAllCustomers } from './customerSliceThunk';
import { CustomersResponse } from './customerSlice.types';

interface CustomersState extends CustomersResponse {
  isLoading: boolean;
}

const initialState: CustomersState = {
  companies: [],
  _embedded: {
    includeValues: [],
    sortFields: [],
  },
  _links: {},
  metaData: {
    itemsCount: 0,
    limit: 10,
    page: 1,
  },
  isLoading: false,
};

const customersSlice = createSlice({
  name: 'customersSlice',
  initialState,
  reducers: {
    addCustomer(state, action: PayloadAction<CustomerWithLinks>) {
      state.companies = [...state.companies, action.payload];
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllCustomers.fulfilled, (state, action) => {
      state.companies = action.payload.companies;
      state._embedded = action.payload._embedded;
      state.metaData = action.payload.metaData;
      state._links = action.payload._links;
    });
  },
});

export const { addCustomer } = customersSlice.actions;

export default customersSlice.reducer;

const customersSliceSelector = (state: RootState) => state.customersSlice;

export const customersSelector = createSelector(customersSliceSelector, (customers) => customers);
