import { LoanWithLinks } from 'lms-types';
import { TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import { Status, Typography } from 'components';
import { formatNumber } from 'utils/formatNumber';
import { formatPercent } from 'utils/formatPercent';

export const renderCells = (
  key: keyof Omit<LoanWithLinks, '_links' | 'loanOfficerUser'> | 'repaid' | 'stickyHeader',
  loan: LoanWithLinks,
) => {
  const { currency } = loan;
  switch (key) {
    case 'repaid':
      return (
        <TableCell key={key}>
          <Typography variant='body2' fontSize='0.85rem'>
            {formatNumber(
              loan.principalAmount + loan.interestAmount - loan.netPayableTotal,
              currency,
            )}
          </Typography>
        </TableCell>
      );
    case 'loanId':
      return (
        <TableCell key={key}>
          <Link to={`/loans/${loan.loanId}`} state={false}>
            {loan.loanId}
          </Link>
        </TableCell>
      );
    case 'principalAmount':
    case 'interestAmount':
    case 'totalDisbursedAmount':
    case 'originationFee':
    case 'netPayableTotal':
    case 'delinquentAmount':
    case 'totalOutstandingAmount':
      return (
        <TableCell key={key}>
          <Typography variant='body2'>{formatNumber(loan[key] || 0, currency)}</Typography>
        </TableCell>
      );

    case 'loanStatus':
      return (
        <TableCell key={key}>
          <div>
            <Status status={loan[key]} />
          </div>
        </TableCell>
      );
    case 'loanOfficerUserId':
      return <TableCell key={key}>{loan.loanOfficerUser.email ?? '-'}</TableCell>;
    case 'rbfRevenueShare':
    case 'loanRate':
    case 'penaltyPercent':
      return (
        <TableCell key={key}>
          <Typography variant='body2'>{loan[key] ? formatPercent(loan[key]) : '-'}</Typography>
        </TableCell>
      );
    case 'loanApprovalDate':
    case 'payoffDate':
    case 'restructuredAmount':
    case 'salesforceOpportunityId':
    case 'wavedAmount':
    case 'writtenOffAmount':
      return <TableCell key={key}>{loan[key] ?? '-'}</TableCell>;
    case 'stickyHeader':
      return <TableCell />;
    case 'name':
      return <TableCell key={key}>{loan.name ?? '-'}</TableCell>;
    default:
      return <TableCell key={key}>{loan[key]}</TableCell>;
  }
};
