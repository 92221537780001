import { Box } from '@mui/material';
import { AppPageWrapper, Loader, Typography } from 'components';
import { Card } from './components';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'hooks';
import { isDashboardLoadingSelector, loanStatsSelector } from './store/dashboardSlice';
import { getDashboardData } from './store/dashboardSliceThunk';
import { useNavigate } from 'react-router';
import { computeLoansBasePath, loanStatsList } from './Dashboard.config';
import { LoanStats } from './store/dashboardSlice.types';

export const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(isDashboardLoadingSelector);
  const loanStats = useSelector(loanStatsSelector);

  useEffect(() => {
    dispatch(getDashboardData());
  }, []);

  const loansBasePath = useMemo(() => {
    return computeLoansBasePath();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const handleLoanCardClick = (status: string) => () => {
    navigate(`${loansBasePath}loan_statuses=${status}`);
  };

  return (
    <AppPageWrapper>
      <Box display={'flex'} mt={3}>
        <Typography variant='h2'>Loan stats</Typography>
      </Box>
      <Box display={'grid'} gridTemplateColumns={'repeat(5, 1fr)'} mt={3} gap='1rem'>
        {loanStatsList.map(({ id, loanStatus, title }) => (
          <Card
            key={id}
            title={title}
            count={loanStats[id as keyof LoanStats]}
            onClick={handleLoanCardClick(loanStatus)}
          />
        ))}
      </Box>
    </AppPageWrapper>
  );
};
