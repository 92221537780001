import { Typography } from 'components';
import { Wrapper } from './Card.styles';
import { Props } from './Card.types';
import { Box } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

export const Card = ({ title, onClick }: Props) => {
  return (
    <Wrapper onClick={onClick}>
      <Typography variant='h2'>{title}</Typography>
      <Box minHeight='3rem' />
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box overflow='hidden'>
          <EastIcon />
        </Box>
      </Box>
    </Wrapper>
  );
};
