import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { DetailedLoanWithLinks, ServerError } from 'lms-types';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import { LoansResponse } from './loansSlice.types';

export const getAllLoans = createAsyncThunk<
  LoansResponse,
  URLSearchParams,
  {
    rejectValue: null;
  }
>('loansSlice/getLoans', async (params, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.get(`/loans?${params}`)) as AxiosResponse<LoansResponse>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});

export const approveLoan = createAsyncThunk<
  DetailedLoanWithLinks,
  string,
  {
    rejectValue: null;
  }
>('loansSlice/approveLoan', async (loanId, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.post(
      `/loans/${loanId}/approve`,
    )) as AxiosResponse<DetailedLoanWithLinks>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});
