import { LmsModal, Typography } from 'components';
import React from 'react';
import { globalLmsErrorMessagesSelector, clearLmsErrors } from '../../../store/slices/globalSlice';
import { useDispatch, useSelector } from '../../../hooks';
import { Divider, List, ListItem, ListItemText } from '@mui/material';

export const LmsErrorModal = () => {
  const dispatch = useDispatch();
  const lmsErrors = useSelector(globalLmsErrorMessagesSelector);
  const handleCloseModal = () => {
    dispatch(clearLmsErrors());
  };
  return (
    <LmsModal
      open={lmsErrors.length > 0}
      headerProps={{
        onClose: handleCloseModal,
        title: '',
      }}
      footerProps={{
        label: 'Close',
        onClick: handleCloseModal,
      }}
      wrapperProps={{ width: '35rem', height: '45vh' }}
    >
      <List disablePadding style={{ margin: '-1.5rem' }}>
        {lmsErrors.map((errorMessage, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <ListItemText primary={<Typography variant='h6'>{errorMessage.title}</Typography>} />
            </ListItem>
            {errorMessage.messages.map((msg, idx) => (
              <ListItem key={idx} alignItems='flex-start'>
                <ListItemText primary={<Typography variant='body1'>{msg}</Typography>} />
              </ListItem>
            ))}
            {index < lmsErrors.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </LmsModal>
  );
};
