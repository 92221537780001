import React from 'react';
import { Button } from 'components';
import { Wrapper } from './ModalFooter.styled';
import { ModalFooterProps } from './ModalFooter.types';

export const ModalFooter = ({ label, onClick, isLoading, isDisabled }: ModalFooterProps) => {
  return (
    <Wrapper>
      <Button
        onClick={onClick}
        isLoading={isLoading}
        disabled={isDisabled}
        sx={{ minWidth: '150px' }}
      >
        {label}
      </Button>
    </Wrapper>
  );
};
