import { useMemo } from 'react';
import { TextField, Typography } from 'components';
import { AddDisbursementsProps } from './AddDisbursement.types';
import { CurrenciesMap } from 'Constants';
import { FormContainer, Wrapper } from './AddDisbursement.styles';

export const AddDisbursement = ({
  formData,
  fieldIssues,
  handleChange,
  onValidate,
  removeValidationError,
  loanData,
}: AddDisbursementsProps) => {
  const derivedFormData = useMemo(() => {
    const amountLeftToDisburse =
      Number(loanData.principalAmount) -
      loanData.principalDisbursedAmount -
      (loanData.originationFee || 0) +
      (loanData.originationFeeAmount || 0);
    const isDisbursedMoreThanPrincipal = Number(formData.amount) > amountLeftToDisburse;
    const differenceAmount = Number(formData.amount) - amountLeftToDisburse;
    const differencePercent = loanData.principalAmount
      ? Math.round((differenceAmount / loanData.principalAmount) * 10000) / 100
      : 0;
    const differenceAmountWarning =
      differenceAmount < 0 ? 'The current amount will not cover the disbursement' : '';
    const differencePercentWarning =
      differencePercent > 5 ? 'The current amount is more than 5% over the principal' : '';
    return {
      amountLeftToDisburse,
      isDisbursedMoreThanPrincipal,
      differenceAmount,
      differencePercent,
      differenceAmountWarning,
      differencePercentWarning,
    };
  }, [
    loanData.principalAmount,
    loanData.principalDisbursedAmount,
    loanData.originationFee,
    formData.amount,
  ]);
  return (
    <FormContainer>
      <TextField
        label='Date'
        name='disbursementDate'
        value={formData.disbursementDate}
        onChange={handleChange}
        error={fieldIssues.disbursementDate?.isError}
        helperText={fieldIssues.disbursementDate?.message}
        type='date'
        onBlur={() => onValidate('disbursementDate')}
        onFocus={() => removeValidationError('disbursementDate')}
        fullWidth
      />
      <TextField
        label='Document number'
        name='documentNumber'
        value={formData.documentNumber}
        onChange={handleChange}
        error={fieldIssues.documentNumber?.isError}
        helperText={fieldIssues.documentNumber?.message}
        onBlur={() => onValidate('documentNumber')}
        onFocus={() => removeValidationError('documentNumber')}
        fullWidth
      />
      <Wrapper>
        <TextField
          label='Disbursement amount'
          name='amount'
          value={formData.amount}
          onChange={handleChange}
          type='number'
          error={fieldIssues.amount?.isError}
          warning={fieldIssues.amount?.isWarning}
          helperText={fieldIssues.amount?.message}
          onBlur={() => onValidate('amount')}
          onFocus={() => removeValidationError('amount')}
          fullWidth
          endIcon={
            <Typography variant='body2' sx={{ mr: '.5rem', color: 'grey.700' }}>
              {CurrenciesMap[loanData.currency]}
            </Typography>
          }
          sx={{ gridColumn: loanData.principalDisbursedAmount == 0 ? '1/-1' : undefined }}
        />
        {loanData.principalDisbursedAmount > 0 ? (
          <TextField
            label='Already disbursed'
            name='alreadyDisbursed'
            value={loanData.principalDisbursedAmount}
            type='number'
            fullWidth
            disabled
            endIcon={
              <Typography variant='body2' sx={{ mr: '.5rem', color: 'grey.700' }}>
                {CurrenciesMap[loanData.currency]}
              </Typography>
            }
          />
        ) : null}
        {derivedFormData.isDisbursedMoreThanPrincipal ? (
          <TextField
            label='Note'
            name='note'
            value={formData.note}
            onChange={handleChange}
            error={fieldIssues.note?.isError}
            helperText={fieldIssues.note?.message}
            fullWidth
            sx={{ gridColumn: '1/-1' }}
            multiline
            minRows={2}
            placeholder='Please enter your explanation here ...'
            onBlur={() => onValidate('note')}
            onFocus={() => removeValidationError('note')}
          />
        ) : null}

        <TextField
          label='Origination fee'
          name='originationFee'
          value={loanData.originationFee || 0}
          type='number'
          fullWidth
          disabled
          endIcon={
            <Typography variant='body2' sx={{ mr: '.5rem', color: 'grey.700' }}>
              {CurrenciesMap[loanData.currency]}
            </Typography>
          }
        />
        <TextField
          label='Principal'
          name='principal'
          value={loanData.principalAmount}
          type='number'
          fullWidth
          disabled
          endIcon={
            <Typography variant='body2' sx={{ mr: '.5rem', color: 'grey.700' }}>
              {CurrenciesMap[loanData.currency]}
            </Typography>
          }
        />
        <TextField
          label='Difference amount'
          name='differenceAmount'
          value={derivedFormData.differenceAmount}
          type='number'
          fullWidth
          warning={Boolean(derivedFormData.differenceAmountWarning)}
          helperText={derivedFormData.differenceAmountWarning}
          disabled
          endIcon={
            <Typography variant='body2' sx={{ mr: '.5rem', color: 'grey.700' }}>
              {CurrenciesMap[loanData.currency]}
            </Typography>
          }
        />
        <TextField
          label='Difference percent'
          name='differencePercent'
          value={derivedFormData.differencePercent}
          type='percent'
          fullWidth
          warning={Boolean(derivedFormData.differencePercentWarning)}
          helperText={derivedFormData.differencePercentWarning}
          disabled
          endIcon={
            <Typography variant='body2' sx={{ mr: '.5rem', color: 'grey.700' }}>
              %
            </Typography>
          }
        />
      </Wrapper>
    </FormContainer>
  );
};
