import { styled } from 'theme';

export const ActionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;

  & > div {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
`;
