import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { CognitoUser } from 'lms-types';
import { RootState } from 'store';

type InitialAuthState = {
  user: CognitoUser | null;
  isLoading: boolean;
};

const initialState: InitialAuthState = {
  user: null,
  isLoading: false,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<CognitoUser>) {
      state.user = action.payload;
      state.isLoading = false;
    },

    dropUser(state) {
      state.user = null;
      state.isLoading = false;
    },
  },
});

export const { dropUser, setUser } = authSlice.actions;

export default authSlice.reducer;

// Selectors

const authSliceSelector = (state: RootState) => state.authSlice;

export const authUserSelector = createSelector(authSliceSelector, (authSlice) => authSlice.user);

export const authTokenSelector = createSelector(
  authUserSelector,
  (user) => user?.signInUserSession.accessToken.jwtToken,
);
