import { DefaultCurrency } from 'Constants';
import { Currency } from 'lms-types';

export const formatNumber = (num: number, currency: Currency = DefaultCurrency) => {
  if (!num) return '0';
  const formatter = Intl.NumberFormat('en-US', {
    currency,
    style: 'currency',
  });

  return formatter.format(num);
};
