import React from 'react';
import { TextField, Button, Typography } from 'components';
import { useAuth, useFormData } from 'hooks';
import { Form } from './LogIn.styles';

const initialFormData = {
  email: '',
  password: '',
};

export const LogIn = () => {
  const { signIn } = useAuth();
  const { formData, handleChange } = useFormData(initialFormData);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    signIn(formData.email, formData.password);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Typography variant='h1'>Sign in</Typography>
      <TextField
        label='Email'
        name={'email'}
        onChange={handleChange}
        value={formData.email}
        fullWidth
      />
      <TextField
        label='Password'
        type='password'
        name='password'
        onChange={handleChange}
        value={formData.password}
        fullWidth
      />
      <Button type='submit' disabled={!formData.email || !formData.password}>
        Sign in
      </Button>
    </Form>
  );
};
