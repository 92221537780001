import { format } from 'date-fns';

const today = format(new Date(), 'yyyy-MM-dd');

export const formData = {
  amount: '',
  documentNumber: '',
  paymentDate: today,
};

export type ReconcilePaymentFormData = typeof formData;
