import { useState } from 'react';
import {
  CreditScore,
  Typography,
  Avatar,
  Button,
  IconButton,
  TextField,
  SearchField,
  Dropdown,
  Checkbox,
  AppPageWrapper,
} from 'components';
import { ScoreType } from 'lms-types';
import CallIcon from '@mui/icons-material/Call';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import logo from 'assets/images/flow48-logo.png';
import { Tab, Tabs } from '@mui/material';

const allStatuses = new Array(8).fill(0).map((_, idx) => idx + 1);

function Components() {
  const [activeDropdown, setActiveDropdown] = useState('');

  const handleClick = (id?: string) => {
    if (!id) return;
    setActiveDropdown(id);
  };

  return (
    <AppPageWrapper>
      <Typography variant='h1'>Components</Typography>
      <div>
        <Typography variant='h1'>Credit score demo</Typography>
        <div style={{ display: 'flex', gap: '1rem' }}>
          {allStatuses.map((status) => {
            return <CreditScore key={status} score={status as ScoreType} />;
          })}
        </div>
        <br />

        <Typography variant='h1'>Typographies demo</Typography>
        <div>
          <Typography variant='h1'>This is demo of text h1</Typography>
          <Typography variant='h2'>This is demo of text h2</Typography>
          <Typography variant='h3'>This is demo of text h3</Typography>
          <Typography variant='body1'>This is demo of text body1</Typography>
          <Typography variant='body2'>This is demo of text body2</Typography>
          <Typography variant='caption'>This is demo of text caption</Typography>
        </div>
        <br />
        <Typography variant='h1'>Avatar demo</Typography>
        <div>
          <Avatar name='Dick Chasey' />
          <Avatar name='Dick Johnson' size='small' />
          <Avatar name='Dick Chasey' img={logo} />
          <Avatar name='Dick Johnson' size='small' img={logo} />
        </div>
        <br />
        <Typography variant='h1'>Button demo</Typography>
        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
          <Button>Click me</Button>
          <Button disabled>Click me</Button>
          <Button isLoading onClick={() => alert('Button clicked')}>
            Click me
          </Button>
        </div>
        <br />
        <Typography variant='h1'>Icon-Button demo</Typography>
        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'start' }}>
          <IconButton>
            <CallIcon />
          </IconButton>
          <IconButton>
            <SmsOutlinedIcon />
          </IconButton>
        </div>
        <br />
        <Typography variant='h1'>TextField demo</Typography>
        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'end' }}>
          <TextField label='Label' />
          <SearchField />
        </div>
        <br />
        <Typography variant='h1'>Dropdown demo</Typography>
        <div>
          <Dropdown
            label='Title'
            options={[]}
            isActive={activeDropdown === 'first'}
            onClick={handleClick}
            id='first'
          />
          <br />
          <Dropdown
            label='Carabach-barabach'
            options={[]}
            isActive={activeDropdown === 'second'}
            onClick={handleClick}
            id='second'
          />
        </div>
        <br />
        <Typography variant='h1'>Checkbox demo</Typography>
        <div>
          <Checkbox label='Test task' name='asd' />
        </div>
        <br />
        <Typography variant='h1'>Tabs demo</Typography>
        <div style={{ margin: '1rem' }}>
          <Tabs value={1}>
            <Tab label='first' />
            <Tab label='second' />
            <Tab label='third' />
          </Tabs>
        </div>
      </div>
    </AppPageWrapper>
  );
}

export default Components;
