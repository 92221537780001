import { Dropdown, PopperMenu } from 'components';
import { FiltersContainer, Wrapper, CloseIcon } from './Filters.styles';
import { FilterTypes, QueryFilterNames, Props } from './Filters.types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { filterTitles } from './Filters.config';
import { useSearchParams } from 'react-router-dom';
import deleteParamsEntry from 'utils/deleteParamsEntry';
import { PopperOption } from 'components/organisms/PopperMenu/PopperMenu.types';
import { useDispatch } from 'hooks';
import { setBankFilter } from 'pages/RefinementRules/store/refinementRulesSlice';

export const Filters = ({ banksList }: Props) => {
  const dispatch = useDispatch();
  const [activeDropdown, setActiveDropdown] = useState<FilterTypes | ''>('');
  const bankRef = useRef<HTMLDivElement | null>(null);
  const [filterOptions, setFilterOptions] = useState<{ [key in FilterTypes]: Array<PopperOption> }>(
    { bank: [] },
  );
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!banksList.length) return;

    setFilterOptions((filterOptions) => ({
      ...filterOptions,
      bank: banksList,
    }));
  }, [banksList]);

  const filterRefs = useMemo(() => ({ bank: bankRef }), [bankRef.current]);

  const hasAppliedFilters = useMemo(() => {
    const { bank } = filterOptions;

    const hasAnyBankFilter = bank.some((filter) => filter.selected);

    return hasAnyBankFilter;
  }, [filterOptions]);

  useEffect(() => {
    const queryBankData = searchParams.get('bank') ?? '';

    dispatch(setBankFilter(queryBankData));

    setFilterOptions(({ bank }) => {
      const mappedBank = bank.map((option) => ({
        ...option,
        selected: queryBankData === option.id,
      }));

      return { bank: mappedBank };
    });
  }, [banksList]);

  const handleCloseMenu = () => {
    setActiveDropdown('');
  };

  const handleDropdownClick = (id?: string) => {
    if (!id) return;
    setActiveDropdown(id as FilterTypes);
  };

  const handleSelect = (filterType: QueryFilterNames) => (id: string, selected: boolean) => {
    const activeFiltersOptionList = filterOptions[activeDropdown as FilterTypes];

    setFilterOptions((opts) => ({
      ...opts,
      [activeDropdown]: activeFiltersOptionList.map((popperOption) =>
        popperOption.id === id
          ? { ...popperOption, selected }
          : { ...popperOption, selected: false },
      ),
    }));

    let updatedParams = searchParams;

    if (selected) {
      updatedParams = deleteParamsEntry(updatedParams, filterType);
      updatedParams.append(filterType, id);
      dispatch(setBankFilter(id));
    } else {
      updatedParams = deleteParamsEntry(updatedParams, filterType, id);
      dispatch(setBankFilter(''));
    }

    setSearchParams(updatedParams);
  };

  const handleDropAllFilters = () => {
    let updatedParams = searchParams;

    const { bank } = filterOptions;

    const deselectAndRemoveFromParams =
      (filterType: QueryFilterNames) => (option: PopperOption) => {
        if (option.selected) {
          updatedParams = deleteParamsEntry(updatedParams, filterType, option.id);
        }

        return { ...option, selected: false };
      };

    const mappedBank = bank.map(deselectAndRemoveFromParams('bank'));

    setFilterOptions({ bank: mappedBank });
    setSearchParams(updatedParams);
    dispatch(setBankFilter(''));
  };

  return (
    <>
      <Wrapper>
        <FiltersContainer>
          <Dropdown
            ref={bankRef}
            label='Bank'
            onClick={handleDropdownClick}
            id='bank'
            isActive={activeDropdown === 'bank'}
          />
          {hasAppliedFilters ? <CloseIcon onClick={handleDropAllFilters} /> : null}
        </FiltersContainer>
      </Wrapper>
      <PopperMenu
        open={Boolean(activeDropdown)}
        onClose={handleCloseMenu}
        title={filterTitles[activeDropdown as FilterTypes] ?? ''}
        options={filterOptions[activeDropdown as FilterTypes] ?? []}
        anchorEl={filterRefs[activeDropdown as FilterTypes]?.current}
        multiple
        onSelect={handleSelect('bank')}
        placement='bottom-start'
      />
    </>
  );
};
