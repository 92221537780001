import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { Checkbox, Dropdown, PopperMenu } from 'components';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import { useRef } from 'react';
import { useModalState } from 'hooks';
import { TableHeadProps } from './Table.types';

const TableHeadComponent = ({
  sortBy,
  orderBy,
  tableHeaders,
  removableColumns,
  sortFields,
  headerCheck,
  hideheaderDropdown,
  onSort,
  onSelect,
  onHeaderCheckClick,
}: TableHeadProps) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [openModal, closeModal, isModalOpen] = useModalState(false);

  const handleSort = (id: string) => (evt: React.MouseEvent) => {
    evt.stopPropagation();
    onSort?.(id);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          {tableHeaders.map((header) => {
            return header.id === 'stickyHeader' ? (
              <TableCell
                key={header.id}
                sx={{ position: 'sticky', right: 0, bgcolor: 'common.white' }}
              >
                {hideheaderDropdown ? null : (
                  <Dropdown ref={dropdownRef} onClick={openModal} isActive={isModalOpen}>
                    <CalendarViewWeekIcon />
                  </Dropdown>
                )}
              </TableCell>
            ) : header.id === 'select-all' ? (
              <TableCell key={header.id} sx={{ pr: 0 }}>
                <Checkbox checked={headerCheck} onChange={onHeaderCheckClick} />
              </TableCell>
            ) : (
              <TableCell key={header.id}>
                {sortFields.includes(header.id) ? (
                  <TableSortLabel
                    active={sortBy === header.id}
                    direction={sortBy === header.id ? orderBy : 'asc'}
                    onClick={handleSort(header.id)}
                  >
                    {header.name}
                  </TableSortLabel>
                ) : (
                  header.name
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <PopperMenu
        open={isModalOpen}
        onClose={closeModal}
        title={'Include columns'}
        options={removableColumns}
        anchorEl={dropdownRef.current}
        multiple
        onSelect={onSelect}
      />
    </>
  );
};

export default TableHeadComponent;
