import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { defaultMetaData } from 'Constants';
import { registerRevenue, getRevenues } from 'store/thunks/revenueSliceThunk';
import { RevenuesResponse } from 'types/api';

interface RevenuesState {
  revenues: RevenuesResponse | null;
  isLoading: boolean;
}

const initialState: RevenuesState = {
  isLoading: true,
  revenues: null,
};

const revenuesSlice = createSlice({
  name: 'revenuesSlice',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(getRevenues.fulfilled, (state, action) => {
      state.revenues = action.payload;
    });
    builder.addCase(registerRevenue.fulfilled, (state, action) => {
      state.revenues = action.payload;
    });
  },
});

export const { setIsLoading } = revenuesSlice.actions;

export default revenuesSlice.reducer;

// Selectors

const revenuesSliceSelector = (state: RootState) => state.revenuesSlice;

export const revenuesSelector = createSelector(
  revenuesSliceSelector,
  (state) => state.revenues ?? { revenues: [], metaData: defaultMetaData },
);
