import { createBrowserRouter } from 'react-router-dom';
import { AppLayout } from 'components';
import { BankStatments, DetailedCompany, RefinementRules } from 'pages';
import { Routes } from 'Constants';

export const bankStatementRouter = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <BankStatments />,
      },
      {
        path: `${Routes.COMPANIES}/:companyId`,
        element: <DetailedCompany />,
      },
      {
        path: Routes.REFINEMENT_RULES,
        element: <RefinementRules />,
      },
    ],
  },
]);
