import { TabsWrapper as Wrapper } from '../../CustomerAndLoanModal.styles';
import { groupedFields, FormDataType, CURRENCY_PLACEHOLDER } from './CreateCustomer.config';
import { useDispatch } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { getCustomerModalSelectOptions } from 'store/thunks/customerAndLoanModalThunk';
import { CustomerFormSection } from './CustomerFormSection';
import { EventType } from 'hooks/useFormData';
import { GroupedFieldsType } from '../../CustomerAndLoanModal.types';
import { CountryCurrencies } from 'Constants';
import { getCountryCurrency } from 'utils/getCountryCurrency';

type Props = {
  formData: FormDataType;
  fieldHelpers: Partial<FormDataType>;
  handleChange(evt: EventType): void;
  onSubmit(): void;
  setFieldError(evt: keyof FormDataType, value?: string): void;
  onValidate?(field: keyof FormDataType): void;
};

export const CreateCustomer = ({
  formData,
  fieldHelpers,
  onSubmit,
  handleChange,
  setFieldError,
  onValidate,
}: Props) => {
  const dispatch = useDispatch();

  const mappedGroupedFields = useMemo(() => {
    const mappedLoadDetailsFields = groupedFields['Risk data'].map((field) => ({
      ...field,
      label: field.label.replace(
        CURRENCY_PLACEHOLDER,
        getCountryCurrency(formData.incorporationCountry as keyof typeof CountryCurrencies),
      ),
    }));

    return {
      ...groupedFields,
      'Risk data': mappedLoadDetailsFields,
    } as GroupedFieldsType<FormDataType>;
  }, [formData.incorporationCountry]);

  useEffect(() => {
    dispatch(getCustomerModalSelectOptions());
  }, []);

  const handleValidate = (field: keyof FormDataType) => async () => {
    onValidate?.(field);
  };

  const removeValidationError = (field: keyof FormDataType) => () => {
    setFieldError(field);
  };

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    onSubmit();
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      {Object.keys(mappedGroupedFields).map((groupKey) => {
        const fields = mappedGroupedFields[groupKey];

        return (
          <CustomerFormSection
            fields={fields}
            title={groupKey}
            fieldHelpers={fieldHelpers}
            formData={formData}
            handleChange={handleChange}
            handleValidate={handleValidate}
            removeValidationError={removeValidationError}
            key={groupKey}
          />
        );
      })}
    </Wrapper>
  );
};
