import { TableCell, Tooltip, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Disbursement, DisbursementWithLinks } from 'lms-types';
import { formatNumber } from 'utils/formatNumber';
import { Button, Checkbox, Status, Typography } from 'components';
import { Link } from 'react-router-dom';
import { openApproveDisbursementModal } from 'components/modals/ApproveDisbursementModal/store/approveDisbursementModalSlice';

import { useDispatch, useSelector } from 'hooks';
import {
  selectDisbursement,
  removeDisbursement,
  selectedDisbursementRequestIdsSelector,
} from './store/disbursementsSlice';
import { useMemo } from 'react';
import { EventType } from 'hooks/useFormData';
import { addLmsError } from '../../store/slices/globalSlice';

type CellProps = {
  disbursement: DisbursementWithLinks;
};

const ErrorCell = ({ disbursement }: { disbursement: DisbursementWithLinks }) => {
  const dispatch = useDispatch();

  return (
    <TableCell>
      <Typography variant='body2' fontSize='0.85rem'>
        {disbursement.isSuccess === false ? (
          <Tooltip
            title={
              <Box sx={{ maxHeight: 200, overflowY: 'auto', padding: 1 }}>{disbursement.error}</Box>
            }
          >
            <ErrorOutlineIcon
              onClick={() => {
                dispatch(addLmsError(disbursement.displayError));
              }}
              style={{ color: 'red' }}
            />
          </Tooltip>
        ) : null}
      </Typography>
    </TableCell>
  );
};

const StickyCell = ({ disbursement }: { disbursement: DisbursementWithLinks }) => {
  const dispatch = useDispatch();

  const handleApprove = () => {
    dispatch(openApproveDisbursementModal({ action: 'APPROVE', disbursement: disbursement }));
  };

  const handleCancel = () => {
    dispatch(openApproveDisbursementModal({ action: 'CANCEL', disbursement: disbursement }));
  };
  return (
    <TableCell key={disbursement.disbursementRequestId}>
      {disbursement._links.approveDisbursementRequest ? (
        <Button onClick={handleApprove}>Approve</Button>
      ) : null}{' '}
      {disbursement._links.cancelDisbursementRequest ? (
        <Button onClick={handleCancel}>Cancel</Button>
      ) : null}
    </TableCell>
  );
};

const TableCellCheckbox = ({ disbursement }: CellProps) => {
  const dispatch = useDispatch();
  const selectedDisbursements = useSelector(selectedDisbursementRequestIdsSelector);

  const checked = useMemo(() => {
    return selectedDisbursements.includes(disbursement.disbursementRequestId);
  }, [selectedDisbursements]);

  const handleChange = (evt: EventType) => {
    const updatedState = evt.target.value as boolean;

    if (updatedState) {
      dispatch(selectDisbursement(disbursement.disbursementRequestId));
      return;
    }

    dispatch(removeDisbursement(disbursement.disbursementRequestId));
  };

  return (
    <TableCell key={disbursement.disbursementRequestId} sx={{ pr: 0 }}>
      {disbursement._links.approveDisbursementRequest ? (
        <Checkbox checked={checked} onChange={handleChange} />
      ) : null}
    </TableCell>
  );
};

export const renderCells = (
  key: keyof Disbursement & 'stickyHeader',
  disbursement: DisbursementWithLinks,
) => {
  const renderedValue = typeof disbursement[key] === 'string' ? (disbursement[key] as string) : '-';

  const renderKey = key + disbursement.disbursementRequestId;

  switch (key) {
    case 'error':
      return <ErrorCell key={renderKey} disbursement={disbursement} />;
    case 'checkbox':
      return <TableCellCheckbox key={renderKey} disbursement={disbursement} />;
    case 'requestedBy':
      return (
        <TableCell key={renderKey}>
          <Typography variant='body2' fontSize='0.85rem'>
            {disbursement.approvedBy.email}
          </Typography>
        </TableCell>
      );

    case 'loanId':
      return (
        <TableCell key={renderKey}>
          <Link to={`/loans/${disbursement.loanId}`}>{disbursement.loanName}</Link>
        </TableCell>
      );

    case 'companyIdentifier':
      return (
        <TableCell key={renderKey}>
          <Link to={`/customers/${disbursement.companyIdentifier}`}>
            {disbursement.companyLegalName}
          </Link>
        </TableCell>
      );

    case 'status':
      return (
        <TableCell key={renderKey}>
          <Status status={disbursement.status} />
        </TableCell>
      );

    case 'amount':
      return (
        <TableCell key={renderKey}>
          <Typography variant='body2' fontSize='0.85rem'>
            {formatNumber(disbursement.amount, disbursement.currency)}
          </Typography>
        </TableCell>
      );

    case 'disbursementRequestId':
      return (
        <TableCell key={renderKey}>
          <Typography variant='body2' fontSize='0.85rem'>
            {disbursement.disbursementRequestId}
          </Typography>
        </TableCell>
      );

    case 'stickyHeader':
      return <StickyCell key={renderKey} disbursement={disbursement} />;

    default:
      return <TableCell key={renderKey}>{renderedValue}</TableCell>;
  }
};
