import { useDispatch, useSelector } from 'hooks';
import { Typography, Table, Button } from 'components';
import useSortingParams from 'hooks/useSortingParams';
import { mappedColumnIdsToNames, tableHeaders as rawTableHeaders } from './Revenues.config';
import { useParams } from 'react-router-dom';
import { renderCells } from './Revenues.helpers';
import { Box } from '@mui/material';
import { openRevenueModal } from 'components/modals/RegisterRevenueModal/store/registerRevenueModalSlice';
import { revenuesSelector } from 'store/slices/revenuesSlice';
import { getRevenues } from 'store/thunks/revenueSliceThunk';
import { detailedLoanSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';

const emptyArr: Array<string> = [];

export const Revenues = () => {
  const dispatch = useDispatch();
  const revenues = useSelector(revenuesSelector);
  const loan = useSelector(detailedLoanSelector);
  const params = useParams();

  const customerId = params?.customerId ?? loan.companyIdentifier;

  const handleFetchRevenues = (urlParams: URLSearchParams) => {
    dispatch(getRevenues({ customerId, params: urlParams }));
  };

  const { tableHeaders, searchParams, handleChangePage, handleChangeRowsPerPage, handleSort } =
    useSortingParams(emptyArr, handleFetchRevenues, mappedColumnIdsToNames, rawTableHeaders);

  const handleOpenRevenueModal = () => {
    dispatch(openRevenueModal());
  };

  return (
    <div>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h3'>Revenue</Typography>
        <Button variant='outlined' onClick={handleOpenRevenueModal}>
          Add Revenue
        </Button>
      </Box>
      <Table
        data={revenues.revenues}
        metaData={revenues.metaData}
        tableHeaders={tableHeaders}
        sortBy={searchParams.get('sortBy') ?? ''}
        orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
        removableColumns={[]}
        sortFields={emptyArr}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onSort={handleSort}
        onSelect={() => null}
        renderCells={renderCells}
      />
    </div>
  );
};
