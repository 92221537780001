import { Wrapper, FlexContainer } from './Navbar.styles';
import { Avatar, PopperMenu } from 'components';
import { BankStatementsTabs, LmsTabs } from './components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth, useModalState, useSelector } from 'hooks';
import { navbarMenuOpts } from './Navbar.config';
import { useMemo, useRef } from 'react';
import { appMode } from 'lms.config';
import { authUserSelector } from 'store/slices/authSlice';

type Props = {
  onOpenModal?(): void;
};

const AppTabs = {
  LMS: LmsTabs,
  BANK_STATEMENT: BankStatementsTabs,
};

export const Navbar = ({ onOpenModal }: Props) => {
  const { logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [openModal, closeModal, isModalOpen] = useModalState();
  const avatarRef = useRef<HTMLDivElement | null>(null);
  const user = useSelector(authUserSelector);

  const handleTabChange = (_: React.SyntheticEvent, value: string) => {
    if (value === '/' || value === '/loans') return;
    navigate(value);
  };

  const userName = useMemo(() => {
    if (!user?.attributes.email) return '';
    const [email] = user.attributes.email.split('@');

    return email
      .split('.')
      .map((word) => word.charAt(0))
      .join('');
  }, [user]);

  const handleNavigate = (to: string) => () => {
    if (to === location.pathname) return;
    navigate(to);
  };

  const handleSelect = (id: string) => {
    switch (id) {
      case 'logout':
        void logout();
        break;

      default:
        break;
    }
  };

  const tabsValue = useMemo(() => {
    if (location.pathname.includes('customers')) return '/customers';
    else if (location.pathname.includes('loans')) return '/loans';
    else if (location.pathname.includes('companies')) return '/';
    else return location.pathname;
  }, [location.pathname]);

  const TabsComponent = useMemo(() => {
    return AppTabs[appMode];
  }, [appMode]);

  return (
    <Wrapper id='navbar'>
      <TabsComponent
        tabsValue={tabsValue}
        handleNavigate={handleNavigate}
        handleTabChange={handleTabChange}
        onOpenModal={onOpenModal}
      />

      <FlexContainer>
        <Avatar name={userName} active={isModalOpen} onClick={openModal} ref={avatarRef} />
      </FlexContainer>
      <PopperMenu
        open={isModalOpen}
        onClose={closeModal}
        options={navbarMenuOpts}
        placement='bottom-end'
        anchorEl={avatarRef.current}
        onSelect={handleSelect}
        title='Actions'
      />
    </Wrapper>
  );
};
