import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { ServerError } from 'lms-types';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import { CreateRefinementRulePayload, RefinementRulesResponse } from './refinementRulesSlice.types';
import { RefinementRule } from 'bst-types';

export const getRefinementRules = createAsyncThunk<
  RefinementRulesResponse,
  undefined,
  {
    rejectValue: number | null;
  }
>('refinementRulesSlice/getRefinementRules', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.get(
      '/refinement_rules',
    )) as AxiosResponse<RefinementRulesResponse>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(serverError.response?.status ?? null);
  }
});

export const createRefinementRule = createAsyncThunk<
  RefinementRulesResponse,
  CreateRefinementRulePayload,
  {
    rejectValue: number | null;
  }
>('refinementRulesSlice/createRefinementRule', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.post(
      '/refinement_rules',
      payload,
    )) as AxiosResponse<RefinementRulesResponse>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(serverError.response?.status ?? null);
  }
});

export const updateRefinementRule = createAsyncThunk<
  RefinementRulesResponse,
  RefinementRule,
  {
    rejectValue: number | null;
  }
>('refinementRulesSlice/updateRefinementRule', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.patch(
      `/refinement_rules/${payload.rowNumber}`,
      payload,
    )) as AxiosResponse<RefinementRulesResponse>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(serverError.response?.status ?? null);
  }
});

export const deleteRefinementRule = createAsyncThunk<
  RefinementRulesResponse,
  { ruleId: string | number },
  {
    rejectValue: number | null;
  }
>(
  'refinementRulesSlice/deleteRefinementRule',
  async ({ ruleId }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.delete(
        `/refinement_rules/${ruleId}`,
      )) as AxiosResponse<RefinementRulesResponse>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(serverError.response?.status ?? null);
    }
  },
);
