import { useDispatch } from 'hooks';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import deleteParamsEntry from 'utils/deleteParamsEntry';
import { setIsLoading } from '../store/bankStatementsSlice';
import { getAllCompanies } from '../store/bankStatements.thunk';

type BankingStatementsEffectsArgs = {
  searchValue: string;
};

export const useBankingStatementsEffects = ({ searchValue }: BankingStatementsEffectsArgs) => {
  const timerRef = useRef<NodeJS.Timeout>();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(getAllCompanies({ searchParams }));
  }, [searchParams]);

  const handleSearch = (searchValue: string) => {
    if (searchValue) {
      setSearchParams((searchParams) => {
        searchParams.set('search', searchValue);
        return searchParams;
      });
    } else {
      setSearchParams((searchParams) => deleteParamsEntry(searchParams, 'search'));
    }
  };

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      handleSearch(searchValue);
    }, 700);

    return () => clearTimeout(timerRef.current);
  }, [searchValue]);
};
