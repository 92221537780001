import { LmsModal, Select, TextField } from 'components';
import { useDispatch, useSelector } from 'hooks';
import {
  cancelTransactionModalSelector,
  setTransactionModalLoading,
  setTransactionModalOpen,
} from 'pages/DetailedLoan/store/detailedLoanSlice';
import { cancelReasonsOpts } from './CancelTransactionModal.config';
import { useState } from 'react';
import { Box } from '@mui/material';
import { handleCancelTransaction } from 'pages/DetailedLoan/store/detailedLoanThunk';
import { Props } from './CancelTransactionModal.types';

export const CancelTransactionModal = ({ loanId }: Props) => {
  const dispatch = useDispatch();
  const { isLoading, isOpen, operationId } = useSelector(cancelTransactionModalSelector);

  const [reason, setReason] = useState('');
  const [note, setNote] = useState('');

  const handleCloseModal = () => {
    dispatch(setTransactionModalLoading(false));
    dispatch(setTransactionModalOpen(false));
  };

  const isFormValid = () => {
    const isReasonSelected = !!reason && reason.length > 0;
    const isNoteProvidedWhenReasonOther = reason === 'OTHER' ? note.length > 0 : true;

    return isReasonSelected && isNoteProvidedWhenReasonOther;
  };

  const cancelTransaction = async () => {
    dispatch(setTransactionModalLoading(true));

    const { payload } = await dispatch(
      handleCancelTransaction({
        loanId,
        note: note.length ? note : null,
        operationId: operationId ?? '',
        reason,
      }),
    );

    dispatch(setTransactionModalLoading(false));

    if (payload) {
      handleCloseModal();
    }
  };

  return (
    <LmsModal
      open={isOpen}
      headerProps={{ onClose: handleCloseModal, title: 'Cancel transaction' }}
      footerProps={{
        label: 'Cancel transaction',
        isLoading,
        isDisabled: !isFormValid(),
        onClick: cancelTransaction,
      }}
      wrapperProps={{ width: '25rem', height: '45vh' }}
    >
      <Box display='flex' flexDirection='column' gap='1rem'>
        <Select
          fullWidth
          options={cancelReasonsOpts}
          label='Cancelation Reason'
          showDefaultOption={false}
          value={reason}
          onChange={(e) => setReason(e.target.value as string)}
        />
        {reason === 'OTHER' ? (
          <TextField
            fullWidth
            multiline
            minRows={3}
            label='Note'
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        ) : null}
      </Box>
    </LmsModal>
  );
};
