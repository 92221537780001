import { Table, Typography } from 'components';
import { useSelector, useDispatch, useSkipFirstRenderEffect } from 'hooks';
import { detailedLoanRevioPurchasesSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';
import useSortingParams from 'hooks/useSortingParams';
import {
  defaultTableHeaders,
  defaultMappedColumnIdsToNames,
  includableTableHeaders,
  includableMappedColumnIdsToNames,
  includableTableHeaderIds,
} from './RevioPurchases.config';

import { renderCells } from './RevioPurchases.helpers';
import { getDetailedLoanRevioPurchases } from 'pages/DetailedLoan/store/detailedLoanThunk';
import { DetailedLoanWithLinks } from 'lms-types';

type Props = {
  loan: DetailedLoanWithLinks;
};

const emptyArr: Array<string> = [];

export const RevioPurchases = ({ loan }: Props) => {
  const dispatch = useDispatch();

  const revioPurchasesData = useSelector(detailedLoanRevioPurchasesSelector);

  const fetchRevioPurchases = () => {
    dispatch(getDetailedLoanRevioPurchases({ loanId: loan.loanId }));
  };

  const {
    tableHeaders,
    includedColumns,
    searchParams,
    handleChangePage,
    handleIncludeRows,
    handleChangeRowsPerPage,
    handleSort,
  } = useSortingParams(
    includableTableHeaderIds,
    fetchRevioPurchases,
    defaultMappedColumnIdsToNames,
    defaultTableHeaders,
    includableMappedColumnIdsToNames,
    includableTableHeaders,
  );

  useSkipFirstRenderEffect(() => {
    if (!loan._links.getPurchases) return;

    fetchRevioPurchases();
  }, [loan.loanId]);

  return revioPurchasesData.purchases.length ? (
    <>
      <Table
        data={revioPurchasesData.purchases}
        removableColumns={includedColumns}
        metaData={revioPurchasesData.metaData}
        tableHeaders={tableHeaders}
        sortBy={searchParams.get('sortBy') ?? ''}
        orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
        sortFields={emptyArr}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onSort={handleSort}
        onSelect={handleIncludeRows}
        renderCells={renderCells}
      />
    </>
  ) : (
    <Typography variant='h3'>No collections information is available.</Typography>
  );
};
