import { LmsModal, Select, TextField, Typography } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { detailedLoanSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';
import { useState } from 'react';
import { Props } from './ReqeustDisbursementModal.types';
import { initialFormData, priorityOpts } from './ReqeustDisbursementModal.config';
import { requestDisbursement } from './store/RequestDisbursementModalThunk';
import { FlexContainer, VerticalFlex, Wrapper } from './RequestDisbursementModal.styles';
import { CurrenciesMap } from 'Constants';

export const RequestDisbursementModal = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch();
  const detailedLoan = useSelector(detailedLoanSelector);
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await dispatch(requestDisbursement({ loanId: detailedLoan.loanId, body: formData }));
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((formData) => ({ ...formData, [evt.target.name]: evt.target.value }));
  };

  const isFormValid = () => {
    const { paymentDate, priority } = formData;

    const isPaymentDateProvided = Boolean(paymentDate) && paymentDate.length > 0;
    const isPriorityProvided = Boolean(priority) && priority.length > 0;

    return isPaymentDateProvided && isPriorityProvided;
  };

  return (
    <LmsModal
      open={isOpen}
      headerProps={{
        onClose,
        title: 'Request disbursement',
      }}
      footerProps={{
        label: 'Request disbursement',
        onClick: handleSubmit,
        isLoading,
        isDisabled: !isFormValid(),
      }}
      wrapperProps={{ width: '25rem', height: '55vh' }}
    >
      <TextField
        type='date'
        name='paymentDate'
        label='Date'
        value={formData.paymentDate}
        onChange={handleChange}
        fullWidth
      />
      <Select
        options={priorityOpts}
        name='priority'
        label='Priority'
        fullWidth
        showDefaultOption={false}
        value={formData.priority}
        onChange={handleChange}
      />
      <Wrapper>
        <TextField
          label='Disbursement amount'
          name='originationFee'
          value={detailedLoan.principalAmount - (detailedLoan.originationFee || 0)}
          type='number'
          sx={{ gridColumn: '1/-1' }}
          fullWidth
          disabled
          endIcon={
            <Typography variant='body2' sx={{ mr: '.5rem', color: 'grey.700' }}>
              {CurrenciesMap[detailedLoan.currency]}
            </Typography>
          }
        />
        <TextField
          label='Origination fee'
          name='originationFee'
          value={detailedLoan.originationFee || 0}
          type='number'
          fullWidth
          disabled
          endIcon={
            <Typography variant='body2' sx={{ mr: '.5rem', color: 'grey.700' }}>
              {CurrenciesMap[detailedLoan.currency]}
            </Typography>
          }
        />
        <TextField
          label='Principal'
          name='principal'
          value={detailedLoan.principalAmount}
          type='number'
          fullWidth
          disabled
          endIcon={
            <Typography variant='body2' sx={{ mr: '.5rem', color: 'grey.700' }}>
              {CurrenciesMap[detailedLoan.currency]}
            </Typography>
          }
        />
      </Wrapper>
      <>
        <Typography variant='h3' style={{ marginTop: 20 }}>
          Bank details
        </Typography>
        <VerticalFlex>
          <FlexContainer>
            <Typography variant='body2'>Bank name</Typography>
            <Typography variant='body2' color='secondary'>
              {detailedLoan.company.bankName}
            </Typography>
          </FlexContainer>
          <FlexContainer>
            <Typography variant='body2'>Branch Code</Typography>
            <Typography variant='body2' color='secondary'>
              {detailedLoan.company.branchCode ?? ''}
            </Typography>
          </FlexContainer>
          <FlexContainer>
            <Typography variant='body2'>Account Number</Typography>
            <Typography variant='body2' color='secondary'>
              {detailedLoan.company.accountNumber ?? ''}
            </Typography>
          </FlexContainer>
          <FlexContainer>
            <Typography variant='body2'>Bank address</Typography>
            <Typography variant='body2' color='secondary' textAlign='right'>
              {detailedLoan.company.bankAddress}
            </Typography>
          </FlexContainer>
        </VerticalFlex>
      </>
    </LmsModal>
  );
};
