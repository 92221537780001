import { styled } from 'theme';
import MuiTextField from '@mui/material/TextField';

export const Input = styled(MuiTextField)<{ warning?: boolean }>((props) => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },

  '&: hover': {
    backgroundColor: 'transparent',
  },

  '& .MuiFormHelperText-root, & .MuiFormHelperText-root.Mui-disabled': {
    color: props.warning
      ? props.theme.palette.warning.main
      : props.error
      ? props.theme.palette.error.main
      : undefined,
  },

  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline.Mui-disabled':
    {
      borderColor: props.warning ? props.theme.palette.warning.main + '!important' : undefined,
    },
}));
