import { createBrowserRouter } from 'react-router-dom';
import { AppLayout } from 'components';
import {
  Loans,
  Reports,
  Customers,
  Dashboard,
  Repayments,
  Components,
  DetailedLoan,
  Disbursements,
  DetailedCustomer,
} from 'pages';

export const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: '/customers',
        element: <Customers />,
      },
      {
        path: 'customers/:customerId/*',
        element: <DetailedCustomer />,
      },
      {
        path: 'loans',
        element: <Loans />,
      },
      {
        path: 'loans/:loanId',
        element: <DetailedLoan />,
      },
      {
        path: 'repayments',
        element: <Repayments />,
      },
      {
        path: 'reports',
        element: <Reports />,
      },
      {
        path: 'components',
        element: <Components />,
      },
      { path: 'disbursements', element: <Disbursements /> },
    ],
  },
]);
