export const defaultTableHeaders = [
  { name: '', id: 'checkbox' },
  { name: 'Operation ID', id: 'disbursementRequestId' },
  { name: 'Loan', id: 'loanId' },
  { name: 'Company', id: 'companyIdentifier' },
  { name: 'Disbursement Date', id: 'paymentDate' },
  { name: 'Status', id: 'status' },
  { name: '', id: 'error'},
  { name: 'Amount', id: 'amount' },
  { name: 'Author', id: 'requestedBy' },
  { name: 'Priority', id: 'priority' },
  { name: 'Sticky', id: 'stickyHeader' },
];

export const defaultMappedColumnIdsToNames = defaultTableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);
