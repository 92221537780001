import { TableCell } from '@mui/material';
import { ActionLog } from 'lms-types';

export const renderCells = (key: string, log: ActionLog) => {
  switch (key) {
    case 'user':
      return <TableCell key={key}>{log.user.email ?? '-'}</TableCell>;

    default:
      return <TableCell key={key}>{log[key as keyof typeof log] as string}</TableCell>;
  }
};
