import { Table, Typography } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { customerActionLogsSelector } from 'pages/DetailedCustomer/store/detailedCustomerSlice';
import { tableHeaders as rawTableHeaders, mappedColumnIdsToNames } from './History.config';
import useSortingParams from 'hooks/useSortingParams';
import { getDetailedCustomersActionLogs } from 'pages/DetailedCustomer/store/detailedCustomerSliceThunk';
import { useParams } from 'react-router-dom';
import { renderCells } from './History.helpers';

export const History = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const actionLogs = useSelector(customerActionLogsSelector);

  const fetchActionLogs = (searchParams: URLSearchParams) => {
    dispatch(
      getDetailedCustomersActionLogs({
        customerId: params?.customerId ?? '',
        params: searchParams,
      }),
    );
  };

  const { tableHeaders, searchParams, handleChangePage, handleChangeRowsPerPage, handleSort } =
    useSortingParams(
      actionLogs._embedded.excludeValues,
      fetchActionLogs,
      mappedColumnIdsToNames,
      rawTableHeaders,
    );

  return (
    <div>
      <Typography variant='h3'>History</Typography>
      <Table
        data={actionLogs.actionLogs}
        metaData={actionLogs.metaData}
        tableHeaders={tableHeaders}
        sortBy={searchParams.get('sortBy') ?? ''}
        orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
        removableColumns={[]}
        sortFields={actionLogs._embedded.sortFields}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onSort={handleSort}
        onSelect={() => null}
        renderCells={renderCells}
      />
    </div>
  );
};
