import { Button } from 'components';
import { styled } from 'theme';

export const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;

export const DataGroup = styled('div')`
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.palette.grey[900]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const AlertButton = styled(Button)`
  border: 1px solid #e70033;
  color: #e70033;

  &:hover {
    background-color: #e70033;
    color: ${(props) => props.theme.palette.common.white};
    border-color: #e70033;
  }
`;

export const SummaryGroup = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
