import { LmsModal } from 'components';
import { useDispatch, useFormData, useSelector } from 'hooks';
import { formData } from './components/AddDisbursement/AddDisbursement.config';
import emptyStringToNull from 'utils/emptyStringToNull';
import {
  addNewDisbursement,
  validateNewDisbursementField,
} from './store/disbursementsModalSliceThunk';
import { detailedLoanSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';
import { AddDisbursement } from './components/AddDisbursement';
import { useState } from 'react';
import { formatNumber } from 'utils/formatNumber';

type Props = {
  onClose(): void;
};

export const AddDisbursementModal = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const loanData = useSelector(detailedLoanSelector);
  const disbursementProps = useFormData(formData(loanData));
  const [isLoading, setIsLoading] = useState(false);

  type FormField = keyof typeof formData;

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const { formData, setFieldError } = disbursementProps;

      const replacedFormData = emptyStringToNull(formData);

      const { payload } = await dispatch(validateNewDisbursementField(replacedFormData));

      const payloadKeys = payload ? Object.keys(payload) : [];

      if (payloadKeys.length) {
        payloadKeys.map((key) => {
          setFieldError(key as FormField, payload ? payload[key] : undefined);
        });
      } else {
        const { payload } = await dispatch(addNewDisbursement(replacedFormData));

        if (payload) {
          onClose?.();
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidateDisbursementField = async (field: string) => {
    const { formData, setFieldError, setFieldWarning } = disbursementProps;
    const { payload } = await dispatch(
      validateNewDisbursementField({ [field]: formData[field as FormField] }),
    );

    if (payload?.[field]) {
      setFieldError(field as FormField, payload[field]);
      return;
    }

    if (field === 'amount') {
      const totalDisbursingAmount =
        Number(formData.amount) +
        loanData.principalDisbursedAmount +
        (loanData.originationFee || 0) -
        (loanData.originationFeeAmount || 0);

      if (totalDisbursingAmount > loanData.maxAllowableDisbursements) {
        setFieldError('amount', 'Maximum allowed disbursement exceeded!');
      } else if (totalDisbursingAmount > loanData.principalAmount) {
        const amountLeftToDisburse =
          Number(loanData.principalAmount) -
          loanData.principalDisbursedAmount -
          (loanData.originationFee || 0) +
          (loanData.originationFeeAmount || 0);

        setFieldWarning(
          'amount',
          `The amount of ${formatNumber(
            Number(formData.amount),
            loanData.currency,
          )} exceeds the remainder of ${formatNumber(
            amountLeftToDisburse,
            loanData.currency,
          )} to be disbursed, please add an explanation!`,
        );
      }
    }
  };

  return (
    <LmsModal
      open
      headerProps={{
        onClose,
        title: 'Add disbursement',
      }}
      footerProps={{
        label: 'Save transaction',
        onClick: handleSubmit,
        isLoading,
      }}
      wrapperProps={{ width: '35rem', height: '80vh' }}
    >
      <AddDisbursement
        {...disbursementProps}
        loanData={loanData}
        onValidate={handleValidateDisbursementField}
        removeValidationError={disbursementProps.clearFieldIssues}
      />
    </LmsModal>
  );
};
