import { Dropdown, PopperMenu, SearchField } from 'components';
import { FiltersContainer, Wrapper, CloseIcon } from './Filters.styles';
import { FilterTypes, Props, QueryFilterNames } from './Filters.types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { defaultFilterOptions, filterTitles } from './Filters.config';
import { useSearchParams } from 'react-router-dom';
import deleteParamsEntry from 'utils/deleteParamsEntry';
import { PopperOption } from 'components/organisms/PopperMenu/PopperMenu.types';

export const Filters = ({ handleSearch }: Props) => {
  const [value, setValue] = useState('');
  const timerRef = useRef<NodeJS.Timeout>();
  const [activeDropdown, setActiveDropdown] = useState<FilterTypes | ''>('');
  const typeRef = useRef<HTMLDivElement | null>(null);
  const statusRef = useRef<HTMLDivElement | null>(null);
  const [filterOptions, setFilterOptions] = useState(defaultFilterOptions);
  const [searchParams, setSearchParams] = useSearchParams();

  const filterRefs = useMemo(
    () => ({ status: statusRef, type: typeRef }),
    [typeRef.current, statusRef.current],
  );

  const hasAppliedFilters = useMemo(() => {
    const { status, type } = filterOptions;

    const hasAnyStatusFilter = status.some((filter) => filter.selected);
    const hasAnyTypeFilter = type.some((filter) => filter.selected);

    return hasAnyStatusFilter || hasAnyTypeFilter;
  }, [filterOptions]);

  useEffect(() => {
    const queryLoanTypeColumns = searchParams.getAll('loan_type');
    const queryLoanStatusColumns = searchParams.getAll('loan_statuses');

    setFilterOptions(({ status, type }) => {
      const mappedStatus = status.map((option) => ({
        ...option,
        selected: queryLoanStatusColumns.includes(option.id),
      }));
      const mappedType = type.map((option) => ({
        ...option,
        selected: queryLoanTypeColumns.includes(option.id),
      }));

      return { status: mappedStatus, type: mappedType };
    });
  }, []);

  const handleValueChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
  };

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      handleSearch(value);
    }, 700);

    return () => clearTimeout(timerRef.current);
  }, [value]);

  const handleCloseMenu = () => {
    setActiveDropdown('');
  };

  const handleDropdownClick = (id?: string) => {
    if (!id) return;
    setActiveDropdown(id as FilterTypes);
  };

  const handleSelect = (filterType: QueryFilterNames) => (id: string, selected: boolean) => {
    const activeFiltersOptionList = filterOptions[activeDropdown as FilterTypes];

    setFilterOptions((opts) => ({
      ...opts,
      [activeDropdown]: activeFiltersOptionList.map((popperOption) =>
        popperOption.id === id ? { ...popperOption, selected } : popperOption,
      ),
    }));

    let updatedParams = searchParams;

    if (selected) {
      updatedParams.append(filterType, id);
    } else {
      updatedParams = deleteParamsEntry(updatedParams, filterType, id);
    }

    setSearchParams(updatedParams);
  };

  const handleDropAllFilters = () => {
    let updatedParams = searchParams;

    const { status, type } = filterOptions;

    const deselectAndRemoveFromParams =
      (filterType: QueryFilterNames) => (option: PopperOption) => {
        if (option.selected) {
          updatedParams = deleteParamsEntry(updatedParams, filterType, option.id);
        }

        return { ...option, selected: false };
      };

    const mappedStatus = status.map(deselectAndRemoveFromParams('loan_statuses'));
    const mappedType = type.map(deselectAndRemoveFromParams('loan_type'));

    setFilterOptions({ status: mappedStatus, type: mappedType });
    setSearchParams(updatedParams);
  };

  return (
    <>
      <Wrapper>
        <SearchField placeholder='Search' value={value} onChange={handleValueChange} />
        <FiltersContainer>
          <Dropdown
            ref={typeRef}
            label='Loan type'
            onClick={handleDropdownClick}
            id='type'
            isActive={activeDropdown === 'type'}
          />
          <Dropdown
            label='Loan status'
            onClick={handleDropdownClick}
            id='status'
            ref={statusRef}
            isActive={activeDropdown === 'status'}
          />
          {hasAppliedFilters ? <CloseIcon onClick={handleDropAllFilters} /> : null}
        </FiltersContainer>
      </Wrapper>
      <PopperMenu
        open={Boolean(activeDropdown)}
        onClose={handleCloseMenu}
        title={filterTitles[activeDropdown as FilterTypes] ?? ''}
        options={filterOptions[activeDropdown as FilterTypes] ?? []}
        anchorEl={filterRefs[activeDropdown as FilterTypes]?.current}
        multiple
        onSelect={handleSelect(activeDropdown === 'status' ? 'loan_statuses' : 'loan_type')}
        placement='bottom-start'
      />
    </>
  );
};
