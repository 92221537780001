import { format } from 'date-fns';

const today = format(new Date(), 'yyyy-MM-dd');

export const initformData = {
  amount: '',
  note: '',
  paymentDate: today,
  expenseCategory: '',
};

export const expenseCategoryOpts = [
  { name: 'Bank charge', value: 'BANK_CHARGE' },
  { name: 'Discounts', value: 'DISCOUNTS' },
  { name: 'Other', value: 'OTHER' },
];

export type RegisterExpensesFormData = typeof initformData;
