import { createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { format } from 'date-fns';
import { getServerTime } from 'store/thunks/timeMachineThunk';

interface TimeMachineState {
  currentDate: string;
}

const initialState: TimeMachineState = {
  currentDate: format(new Date(), 'yyyy-MM-dd'),
};

const timeMachineSlice = createSlice({
  name: 'timeMachineSlice',
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getServerTime.fulfilled, (state, action) => {
      state.currentDate = action.payload.currentTime;
    });
  },
});

export default timeMachineSlice.reducer;

// Selectors

const timeMachineSliceSelector = (state: RootState) => state.timeMachineSlice;

export const currentServerTimeSelector = createSelector(
  timeMachineSliceSelector,
  (state) => state.currentDate,
);
