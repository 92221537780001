import { useDispatch, useSelector } from 'hooks';
import { customerLoansSelector } from 'pages/DetailedCustomer/store/detailedCustomerSlice';
import {
  defaultMappedColumnIdsToNames,
  defaultQueryParams,
  defaultTableHeaders,
  includableMappedColumnIdsToNames,
  includableTableHeaders,
} from './RelatedLoans.config';
import useSortingParams from 'hooks/useSortingParams';
import { renderCells } from './RelatedLoans.helpers';
import { Table, Typography } from 'components';
import { getDetailedCustomersLoans } from 'pages/DetailedCustomer/store/detailedCustomerSliceThunk';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { ActionsPanel } from '..';

type Props = {
  showActions?: boolean;
};

export const RelatedLoans = ({ showActions = true }: Props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const customerLoans = useSelector(customerLoansSelector);

  const fetchRelatedLoans = (searchParams: URLSearchParams) => {
    if (!searchParams.size) return;
    dispatch(
      getDetailedCustomersLoans({ customerId: params?.customerId ?? '', params: searchParams }),
    );
  };

  const {
    searchParams,
    includedColumns,
    tableHeaders,
    handleChangePage,
    handleChangeRowsPerPage,
    handleIncludeRows,
    handleSort,
    setSearchParams,
  } = useSortingParams(
    customerLoans._embedded.includeValues,
    fetchRelatedLoans,
    defaultMappedColumnIdsToNames,
    defaultTableHeaders,
    includableMappedColumnIdsToNames,
    includableTableHeaders,
  );

  useEffect(() => {
    const queryColumns = searchParams.getAll('include');

    if (!searchParams.get('page')) {
      searchParams.append('page', '1');
    }

    if (!searchParams.get('limit')) {
      searchParams.append('limit', '50');
    }

    if (queryColumns.length) {
      setSearchParams(searchParams);
    } else {
      defaultQueryParams.forEach((param) => searchParams.append('include', param));
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <>
      {showActions ? <ActionsPanel /> : <Typography variant='h3'>Company Loans</Typography>}
      <Table
        data={customerLoans?.loans}
        metaData={customerLoans.metaData}
        tableHeaders={tableHeaders}
        sortBy={searchParams.get('sortBy') ?? ''}
        orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
        removableColumns={includedColumns}
        sortFields={customerLoans._embedded.sortFields}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onSelect={handleIncludeRows}
        onSort={handleSort}
        renderCells={renderCells}
      />
    </>
  );
};
