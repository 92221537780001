export const CreditColorsMap : { [key: number]: string } = {
  1: '#6FAE1E',
  2: '#6FAE1E',
  3: '#96DD3B',
  4: '#96DD3B',
  5: '#96DD3B',
  6: '#EDB700',
  7: '#EDB700',
  8: '#E70033',
};
