import { Button, Typography } from 'components';
import { useDispatch } from 'hooks';
import {
  setTransactionModalOpen,
  setTransactionModalOperationId,
} from 'pages/DetailedLoan/store/detailedLoanSlice';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useState } from 'react';
import { Box, Popover, TableCell } from '@mui/material';
import { computeOpacity } from '../../Transactions.helpers';
import { TransactionWithLinks } from 'lms-types';

export const CancelTransaction = ({ transaction }: { transaction: TransactionWithLinks }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleOpenCancelTransactionModal = () => {
    dispatch(setTransactionModalOperationId(transaction.operationId));
    dispatch(setTransactionModalOpen(true));
  };

  return (
    <>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{transaction.cancelingNote}</Typography>
      </Popover>
      {transaction.isCanceled ? (
        <TableCell
          sx={{
            p: '0 16px',
            position: 'sticky',
            right: 0,
            bgcolor: 'common.white',
            ...computeOpacity(transaction.isCanceled),
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'error.main', gap: '.3rem' }}>
            <NotInterestedOutlinedIcon
              sx={{ width: '1rem', height: '1rem', marginRight: '1 rem', color: 'error.main' }}
            />
            Canceled
            {transaction.cancelingNote ? (
              <Box
                display='flex'
                alignItems='center'
                sx={{ cursor: 'pointer' }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <HelpOutlineOutlinedIcon
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    marginRight: '1 rem',
                    color: 'primary.main',
                  }}
                />
              </Box>
            ) : null}
          </Box>
        </TableCell>
      ) : (
        <TableCell
          key={transaction.operationId}
          sx={{
            p: '0 16px',
            position: 'sticky',
            right: 0,
            bgcolor: 'common.white',
            ...computeOpacity(transaction.isCanceled),
          }}
        >
          {transaction._links.cancelOperation ? (
            <Button onClick={handleOpenCancelTransactionModal}>Cancel</Button>
          ) : null}
        </TableCell>
      )}
    </>
  );
};
