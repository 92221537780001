import { styled } from 'theme';

export const Wrapper = styled('div')`
  border: 1px solid ${(props) => props.theme.palette.grey[900]};
  padding: 1.5rem;
  transition: all 0.2s;

  & svg {
    transform: translateX(-30px);
    transition: all 0.5s;
    fill: ${(props) => props.theme.palette.primary.main};
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.grey[700]};
    transform: translateY(-2px);
    cursor: pointer;
    box-shadow: 0;
    border-width: 1.5px;

    & svg {
      transform: translateX(0);
    }
  }
`;
