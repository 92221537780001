import { useEffect, useMemo } from 'react';
import { AppPageWrapper, Typography } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { getRefinementRules } from './store/refinementRulesThunk';
import {
  refinementBanksAsOptionsSelector,
  filteredRefinementRulesSelector,
} from './store/refinementRulesSlice';
import { RefinementRuleView, Filters } from './components';
import { useSearchParams } from 'react-router-dom';

export const RefinementRules = () => {
  const dispatch = useDispatch();
  const refinementRules = useSelector(filteredRefinementRulesSelector);
  const bankOpts = useSelector(refinementBanksAsOptionsSelector);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getRefinementRules());
  }, []);

  const showRefinementRules = useMemo(() => {
    return Boolean(searchParams.get('bank'));
  }, [searchParams]);

  return (
    <AppPageWrapper>
      <Typography variant='h2'>Refinement Rules</Typography>
      <Filters banksList={bankOpts} />
      {showRefinementRules ? (
        <>
          {refinementRules.map((rule) => (
            <RefinementRuleView banksOpts={bankOpts} key={rule.rowNumber} rule={rule} />
          ))}
          <RefinementRuleView banksOpts={bankOpts} isNew />
        </>
      ) : (
        <Typography variant='h3' textAlign='center'>
          Please select a bank as a first step!
        </Typography>
      )}
    </AppPageWrapper>
  );
};
