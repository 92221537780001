import { DetailedLoanWithLinks } from 'lms-types';
import { DisbursementFormData } from './AddDisbursement.types';
import { format } from 'date-fns';

const today = format(new Date(), 'yyyy-MM-dd');
export const formData = (loanData: DetailedLoanWithLinks): DisbursementFormData => ({
  amount: `${loanData.principalAmount - loanData.principalDisbursedAmount - (loanData.originationFee || 0)}`,
  documentNumber: '',
  note: '',
  disbursementDate: today,
});

