import { styled } from 'theme';
import { GaugeFillerProps, MarkerProps, DescriptionItemTypes } from './CreditGauge.types';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const GaugePlaceholder = styled('div')`
  position: relative;
  width: 100%;
  height: 0.5rem;
  background-color: ${(props) => props.theme.palette.secondary.light};
  border-radius: 8px;
`;

export const GaugeFiller = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})<GaugeFillerProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  border-radius: 8px;
`;

export const DescriptionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export const DescriptionItem = styled('div')<DescriptionItemTypes>`
  display: flex;
  justify-content: space-between;
  border-radius: 50%;
  transform: ${(props) => (props.moveUp ? 'translateY(-0.4rem)' : '')};
`;

export const DescriptionItemContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  transform: translateX(-1.3rem);
`;

export const Marker = styled('div')<MarkerProps>`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${(props) =>
    props.marked ? props.theme.palette.secondary.main : props.theme.palette.secondary.light};
  border-radius: 50%;
`;

export const FlexContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;
