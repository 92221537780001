import { styled } from 'theme';

type LoanDataProps = {
  color?: keyof typeof colorMap;
};

const colorMap = {
  red: '#E70033',
  green: '#6FAE1E',
};

export const LoanData = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})<LoanDataProps>`
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;

  & > p {
    color: ${({ color }) => (color ? colorMap[color as keyof typeof colorMap] : '')} !important;
  }

  & > h3 {
    color: ${({ color }) => (color ? colorMap[color as keyof typeof colorMap] : '')} !important;
  }
`;
