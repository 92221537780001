import { CustomerWithLinks, ScoreType } from 'lms-types';
import { TableCell, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { mappedCountriesToFlags } from 'Constants';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SmsIcon from '@mui/icons-material/SmsOutlined';
import CloudIcon from '@mui/icons-material/CloudQueueOutlined';
import { Typography, IconButton, CreditScore } from 'components';
import { formatNumber } from 'utils/formatNumber';

export const renderCells = (
  key: keyof CustomerWithLinks | 'stickyHeader',
  customer: CustomerWithLinks,
) => {
  const { currency } = customer;
  switch (key) {
    case 'companyLegalName':
      return (
        <TableCell key={key}>
          <Link to={`/customers/${customer.companyIdentifier}`}>{customer.companyLegalName}</Link>
        </TableCell>
      );

    case 'incorporationCountry':
      return (
        <TableCell
          key={key}
          align='center'
          sx={{
            '& svg': {
              width: '2rem',
            },
          }}
        >
          {
            mappedCountriesToFlags[
              customer.incorporationCountry as keyof typeof mappedCountriesToFlags
            ]
          }
        </TableCell>
      );

    case 'partnerCompanyIdentifier':
      return (
        <TableCell key={key}>
          {customer.partnerCompanyIdentifier ? (
            <Link to={`/customers/${customer.partnerCompanyIdentifier}`}>
              {customer.partnerCompanyIdentifier}
            </Link>
          ) : (
            '-'
          )}
        </TableCell>
      );

    case 'isPartner':
    case 'isComplianceApproved':
      return (
        <TableCell align='center' key={key}>
          {customer.isPartner ? (
            <DoneIcon sx={{ color: 'success.main' }} />
          ) : (
            <CloseIcon sx={{ color: 'error.main' }} />
          )}
        </TableCell>
      );

    case 'website':
      return (
        <TableCell key={key}>
          {customer.website ? (
            <a href={customer.website} target='_blank' rel='noreferrer'>
              Visit Website
            </a>
          ) : (
            'No website'
          )}
        </TableCell>
      );
    case 'creditLimit':
    case 'ibfCreditLimit':
    case 'rbfCreditLimit':
    case 'utilizedCredit':
      return (
        <TableCell key={key}>
          <Typography variant='body2' fontSize='0.85rem'>
            {formatNumber(customer[key] ?? 0, currency)}
          </Typography>
        </TableCell>
      );
    case 'creditScore':
      return (
        <TableCell sx={{ pl: '2.5rem' }} key={key}>
          <CreditScore score={Number(customer.creditScore) as ScoreType} />
        </TableCell>
      );

    case 'stickyHeader':
      return (
        <TableCell
          key={key}
          sx={{ position: 'sticky', right: 0, bgcolor: 'common.white', width: '105px' }}
        >
          <Box display='flex' gap='.5rem'>
            <IconButton>
              <LocalPhoneIcon />
            </IconButton>
            <IconButton>
              <SmsIcon />
            </IconButton>
            {customer?._links.salesforce ? (
              <a href={customer._links.salesforce.href} target='_blank' rel='noreferrer'>
                <IconButton>
                  <CloudIcon />
                </IconButton>
              </a>
            ) : null}
          </Box>
        </TableCell>
      );
    case 'loanOfficerUserId':
      return <TableCell key={key}>{customer.loanOfficerUser.email}</TableCell>;
    case 'updatedByUserId':
    case 'updatedAt':
      return <TableCell key={key}>{customer[key] ?? '-'}</TableCell>;

    default:
      return <TableCell key={key}>{customer[key] as string}</TableCell>;
  }
};
