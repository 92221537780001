import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  classifySelectedTransactions,
  getDetailedCompany,
  editTransaction,
} from './detailedCompanySlice.thunk';
import { Transaction, TransactionClassificationTypes } from 'bst-types';

interface DetailedCompanyState {
  isLoading: boolean;
  transactions: Array<Transaction>;
  currencies: Array<string>;
  banks: Array<string>;
  selectedTransactions: Array<number>;
  editModal: {
    isOpen: boolean;
    transaction: Transaction | null;
  };
}

const initialState: DetailedCompanyState = {
  isLoading: false,
  transactions: [],
  banks: [],
  currencies: [],
  selectedTransactions: [],
  editModal: {
    isOpen: false,
    transaction: null,
  },
};

const detailedCOmpanySlice = createSlice({
  name: 'detailedCompanySlice',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    selectTransaction(state, action: PayloadAction<number>) {
      state.selectedTransactions = [...state.selectedTransactions, action.payload];
    },
    removeTransaction(state, action: PayloadAction<number>) {
      state.selectedTransactions = state.selectedTransactions.filter(
        (transaction) => transaction !== action.payload,
      );
    },
    deselectAllTransactions(state) {
      state.selectedTransactions = [];
    },
    selectAllTransactions(state) {
      state.selectedTransactions = state.transactions.map((transaction) => transaction.rowNumber);
    },
    resetState() {
      return initialState;
    },
    handleOpenEditTransactionModal(state, action: PayloadAction<Transaction>) {
      state.editModal.isOpen = true;
      state.editModal.transaction = action.payload;
    },
    handleCloseEditTransactionModal(state) {
      state.editModal.isOpen = false;
      state.editModal.transaction = null;
    },
  },

  extraReducers(builder) {
    builder.addCase(getDetailedCompany.fulfilled, (state, action) => {
      state.transactions = action.payload.transactions;
      state.banks = action.payload.banks;
      state.currencies = action.payload.currencies;
    });

    builder.addCase(editTransaction.fulfilled, (state, action) => {
      const updatedTransaction = state.transactions.findIndex(
        (transaction) => transaction.rowNumber === action.payload.rowNumber,
      );

      if (updatedTransaction < 0) return;

      state.transactions[updatedTransaction] = {
        ...state.transactions[updatedTransaction],
        ...action.payload,
      };
    });

    builder.addCase(
      classifySelectedTransactions.fulfilled,
      (state, { payload: { transactions, classification } }) => {
        state.transactions = state.transactions.map((transaction) => {
          const isUpdated = transactions.includes(transaction.rowNumber);

          if (!isUpdated) return transaction;

          return {
            ...transaction,
            classification: classification as TransactionClassificationTypes,
          };
        });
      },
    );
  },
});

export const {
  resetState,
  setIsLoading,
  removeTransaction,
  selectTransaction,
  selectAllTransactions,
  deselectAllTransactions,
  handleOpenEditTransactionModal,
  handleCloseEditTransactionModal,
} = detailedCOmpanySlice.actions;

export default detailedCOmpanySlice.reducer;

// Selectors
export const bankStatementsSliceSelector = (state: RootState) => state.detailedCompanySlice;

export const areCompanyTransactionsLoadingSelector = createSelector(
  bankStatementsSliceSelector,
  (state) => state.isLoading,
);

export const companyTransactionsSelector = createSelector(
  bankStatementsSliceSelector,
  (state) => state.transactions,
);

export const transactionsSliceBanksSelector = createSelector(
  bankStatementsSliceSelector,
  (state) => state.banks,
);

export const transactionsSliceCurrenciesSelector = createSelector(
  bankStatementsSliceSelector,
  (state) => state.currencies,
);

export const selectedTransactionsSelector = createSelector(
  bankStatementsSliceSelector,
  (state) => state.selectedTransactions,
);

export const editModalStateSelector = createSelector(
  bankStatementsSliceSelector,
  (state) => state.editModal,
);
