import { styled } from 'theme';
import { Link as RawLink } from 'react-router-dom';

export const Wrapper = styled('div')<{ isOpen: boolean }>`
  width: ${(props) => (props.isOpen ? '400px' : '88px')};
  background-color: ${(props) => props.theme.palette.grey[950]};
  height: 100%;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: all 0.2s;
`;

export const Container = styled('div')`
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.light};
  padding-bottom: 1rem;
`;

export const FlexContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const VerticalFlex = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;

export const Link = styled(RawLink)`
  font-size: 0.875rem;
  font-weight: 500 !important;
  line-height: 1.2rem;
  font-family: IBM Plex Sans, sans-serif;
  letter-spacing: 0.01071em;
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: none;
`;
