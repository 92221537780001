import { Typography, Button, Table } from 'components';
import { Box } from '@mui/material';
import { TransactionProps } from './Transactions.types';
import { useSelector, useDispatch } from 'hooks';
import {
  cancelTransactionModalSelector,
  detailedLoanTransactionsSelector,
} from 'pages/DetailedLoan/store/detailedLoanSlice';
import { getDetailedLoanTransactions } from 'pages/DetailedLoan/store/detailedLoanThunk';
import { useParams } from 'react-router-dom';
import useSortingParams from 'hooks/useSortingParams';
import { tableHeaders as rawTableHeaders, mappedColumnIdsToNames } from './Transactions.config';
import { renderCells } from './Transactions.helpers';
import { CancelTransactionModal } from './components';

const emptyArr: Array<string> = [];

export const Transactions = ({
  loan,
  openDisbursementModal,
  openRegisterExpensesModal,
  openReconcilePaymentModal,
}: TransactionProps) => {
  const dispatch = useDispatch();
  const params = useParams();
  const transactionData = useSelector(detailedLoanTransactionsSelector);
  const { isOpen } = useSelector(cancelTransactionModalSelector);

  const fetchTransactions = (searchParams: URLSearchParams) => {
    dispatch(getDetailedLoanTransactions({ loanId: params?.loanId ?? '', params: searchParams }));
  };

  const { tableHeaders, searchParams, handleChangePage, handleChangeRowsPerPage, handleSort } =
    useSortingParams(emptyArr, fetchTransactions, mappedColumnIdsToNames, rawTableHeaders);

  return (
    <div>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h2'>Transactions</Typography>
        <Box display='flex' gap='1rem'>
          {/* <Dropdown label='Filter by type' /> */}
          {loan._links.addDisbursement ? (
            <Button variant='outlined' onClick={openDisbursementModal}>
              Add disbursement
            </Button>
          ) : null}
          {loan._links.addRepayments ? (
            <Button variant='outlined' onClick={openReconcilePaymentModal}>
              Reconcile payment
            </Button>
          ) : null}
          {loan._links.addRepayments ? (
            <Button variant='outlined' onClick={openRegisterExpensesModal}>
              Register expenses
            </Button>
          ) : null}
        </Box>
      </Box>
      <Table
        hideheaderDropdown={true}
        data={transactionData.transactions}
        metaData={transactionData.metaData}
        tableHeaders={tableHeaders}
        sortBy={searchParams.get('sortBy') ?? ''}
        orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
        removableColumns={[]}
        sortFields={emptyArr}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onSort={handleSort}
        onSelect={() => null}
        renderCells={renderCells(loan.currency)}
      />
      {isOpen ? <CancelTransactionModal loanId={loan.loanId} /> : null}
    </div>
  );
};
