import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { ServerError } from 'lms-types';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import { RootState } from 'store';
import { ReconcilePaymentFormData } from '../ReconcilePaymentModal.config';
import { ReconcilePaymentResponse } from './reconcilePaymentSlice.types';

export const reconcileNewPayment = createAsyncThunk<
  ReconcilePaymentResponse,
  ReconcilePaymentFormData,
  { rejectValue: null; state: RootState }
>(
  'reconcileModalSlice/reconcileNewPayment',
  async (data, { dispatch, rejectWithValue, getState }) => {
    try {
      const store = getState();

      const response = (await axios.post(
        `/loans/${store.detailedLoanSlice.loan?.loanId}/repayments`,
        data,
      )) as AxiosResponse<ReconcilePaymentResponse>;

      dispatch(
        addNotification({
          severity: 'success',
          message: 'Repayment saved successfully',
        }),
      );

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const validateReconcileField = createAsyncThunk<
  { [key: string]: string } | null,
  { [key: string]: string | number | boolean | null },
  {
    rejectValue: null;
  }
>(
  'reconcileModalSlice/rvalidateReconcileNewPayment',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post('/loans/repaymentFormFields', body)) as AxiosResponse<{
        [key: string]: string;
      } | null>;

      return response.data;
    } catch (error) {
      dispatch(addNotification({ severity: 'error', message: 'something went wrong' }));
      return rejectWithValue(null);
    }
  },
);
