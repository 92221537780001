import { Props } from './CustomerAndLoanModal.types';
import { Modal } from '@mui/material';
import { Wrapper } from './CustomerAndLoanModal.styles';
import { CreateCustomer, CreateLoan } from './components';
import { ModalHeader, ModalFooter } from 'components';
import { useEffect, useState } from 'react';
import {
  FormDataType as CustomerFormDataType,
  initialFormData as customerInitialFormData,
} from './components/CreateCustomer/CreateCustomer.config';
import { useDispatch, useFormData, useSelector } from 'hooks';
import {
  validateCreateCustomerField,
  validateCreateLoanField,
} from 'store/thunks/customerAndLoanModalThunk';
import {
  loanModalInitialFormDataSelector,
  requiredFieldsSelector,
  resetModalState,
} from 'store/slices/customerAndLoanModalSlice';
import { addNotification } from 'store/slices/globalSlice';
import {
  FormDataType as LoanFormDataType,
  initialFormData as loanInitialFormData,
} from './components/CreateLoan/CreateLoan.config';
import { useNavigate } from 'react-router';
import { tabsOptions } from './CustomerAndLoanModal.config';
import { handleSubmit } from './CustomerAndLoanModal.helpers';
import { LoanWithLinks } from 'lms-types';
import {
  useFetchInterestAmount,
  useFetchIBFPrincipalAmount,
  useLoanOfficerDefault,
  useNextComplianceReviewDateCompute,
  useClearPrincipalAmount,
} from './CustomerAndLoanModal.hooks';

export const CustomerAndLoanModal = ({ open, onClose }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    IBF: ibfRequiredFields,
    RBF: rbfRequiredFields,
    customer: customerReqFields,
  } = useSelector(requiredFieldsSelector);
  const loanInitialData = useSelector(loanModalInitialFormDataSelector);

  const [tabsValue, setTabsValue] = useState(
    loanInitialData ? tabsOptions[1].value : tabsOptions[0].value,
  );
  const [isLoading, setIsLoading] = useState(false);

  const {
    formData: customerFormData,
    fieldHelpers: customerFieldHelpers,
    handleChange: customerHandleChange,
    setFieldError: customerSetFieldError,
  } = useFormData(customerInitialFormData);
  const {
    formData: loanFormData,
    fieldHelpers: loanFieldHelpers,
    handleChange: loanHandleChange,
    setFieldError: loanSetFieldError,
  } = useFormData({ ...loanInitialFormData, ...(loanInitialData ?? {}) });

  useFetchInterestAmount(loanFormData, loanHandleChange);
  useFetchIBFPrincipalAmount(loanFormData, loanHandleChange);
  useLoanOfficerDefault(loanFormData, loanHandleChange);
  useNextComplianceReviewDateCompute(customerFormData, customerHandleChange);
  useClearPrincipalAmount(loanFormData, loanHandleChange);

  useEffect(() => {
    return () => {
      dispatch(resetModalState());
    };
  }, []);

  const handleTabsChange = (tab: string) => {
    setTabsValue(tab);
  };

  const handleValidateCustomer = async (field: keyof CustomerFormDataType) => {
    let fieldValue: string | boolean | null = customerFormData[field];

    if (!customerReqFields.includes(field)) {
      if (typeof fieldValue === 'string') {
        fieldValue = fieldValue.length ? fieldValue : null;
      }
    }

    const body: { [key: string]: string | boolean | null } = {
      [field]: fieldValue,
    };

    if (field === 'nextReviewDate') {
      body['creditLimit'] = customerFormData.creditLimit;
    }

    const { payload } = await dispatch(validateCreateCustomerField(body));
    if (payload?.[field]) {
      customerSetFieldError(field, payload[field]);
    }
  };

  const handleValidateLoan = async (field: keyof LoanFormDataType) => {
    let fieldValue: string | boolean | number | null = loanFormData[field];

    const requiredFields = loanFormData.loanType === 'RBF' ? rbfRequiredFields : ibfRequiredFields;

    if (!requiredFields.includes(field)) {
      if (typeof fieldValue === 'string') {
        fieldValue = fieldValue.length ? fieldValue : null;
      }
    }

    const body: { [key: string]: string | boolean | number | null } = {
      [field]: fieldValue,
      loanType: loanFormData.loanType,
    };

    if (field === 'rbfRevenueShare') {
      body['repaymentType'] = loanFormData.repaymentType;
    }

    const { payload } = await dispatch(validateCreateLoanField(body));
    if (payload?.[field]) {
      loanSetFieldError(field, payload[field]);
    }
  };

  const handleSubmitCustomer = async () => {
    setIsLoading(true);

    const setFieldErrorWrap = (key: string, value?: string) => {
      customerSetFieldError(key as keyof CustomerFormDataType, value);
    };

    try {
      const response = await handleSubmit(
        tabsValue as 'customer',
        customerFormData,
        setFieldErrorWrap,
      );

      if (response?.payload?.companyIdentifier) {
        dispatch(
          addNotification({ message: 'Customer created successfully', severity: 'success' }),
        );
        onClose?.();
        navigate(`/customers/${response?.payload?.companyIdentifier}`);
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitLoan = async () => {
    setIsLoading(true);

    const setFieldErrorWrap = (key: string, value?: string) => {
      loanSetFieldError(key as keyof LoanFormDataType, value);
    };

    try {
      const { payload: loanPayload } = (await handleSubmit(
        tabsValue as 'loan',
        loanFormData,
        setFieldErrorWrap,
      )) as { payload: LoanWithLinks };

      if (loanPayload) {
        dispatch(addNotification({ message: 'Loan created successfully', severity: 'success' }));
        onClose?.();
        navigate(`/loans/${loanPayload?.loanId}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open}>
      <Wrapper>
        <ModalHeader
          tabsOptions={tabsOptions}
          tabsValue={tabsValue}
          onChange={handleTabsChange}
          onClose={onClose}
        />
        {tabsValue === tabsOptions[0].value ? (
          <CreateCustomer
            onSubmit={handleSubmitCustomer}
            formData={customerFormData}
            fieldHelpers={customerFieldHelpers}
            handleChange={customerHandleChange}
            setFieldError={customerSetFieldError}
            onValidate={handleValidateCustomer}
          />
        ) : null}
        {tabsValue === tabsOptions[1].value ? (
          <CreateLoan
            formData={loanFormData}
            fieldHelpers={loanFieldHelpers}
            handleChange={loanHandleChange}
            setFieldError={loanSetFieldError}
            onSubmit={handleSubmitLoan}
            onValidate={handleValidateLoan}
          />
        ) : null}
        <ModalFooter
          label={tabsValue === 'customer' ? 'Create Customer' : 'Create Loan'}
          onClick={tabsValue === 'customer' ? handleSubmitCustomer : handleSubmitLoan}
          isLoading={isLoading}
          isDisabled={
            tabsValue === 'customer'
              ? Boolean(Object.keys(customerFieldHelpers).length)
              : Boolean(Object.keys(loanFieldHelpers).length)
          }
        />
      </Wrapper>
    </Modal>
  );
};
