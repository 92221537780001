import { LoanWithLinks } from 'lms-types';
import { Box, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import { Button, Status, Typography } from 'components';
import { useDispatch } from 'hooks';
import { approveLoan } from './store/loansSlice.thunk';
import { formatNumber } from 'utils/formatNumber';
import { formatPercent } from 'utils/formatPercent';

const StickyCell = ({ loan }: { loan: LoanWithLinks }) => {
  const dispatch = useDispatch();

  const handleApprove = () => {
    dispatch(approveLoan(loan.loanId));
  };

  return (
    <TableCell sx={{ position: 'sticky', right: 0, bgcolor: 'common.white' }}>
      <Button onClick={handleApprove} sx={{ minHeight: '35px' }}>
        Approve
      </Button>
    </TableCell>
  );
};

export const renderCells = (
  key: keyof LoanWithLinks | 'repaid' | 'stickyHeader',
  loan: LoanWithLinks,
) => {
  const { currency } = loan;

  switch (key) {
    case 'companyIdentifier':
      return (
        <TableCell key={key}>
          <Link to={`/customers/${loan.companyIdentifier}`}>{loan.companyIdentifier}</Link>
        </TableCell>
      );
    case 'loanId':
      return (
        <TableCell key={key}>
          <Link to={`/loans/${loan.loanId}`}>{loan.loanId}</Link>
        </TableCell>
      );
    // Currency columns
    case 'principalAmount':
    case 'interestAmount':
    case 'totalDisbursedAmount':
    case 'originationFee':
    case 'netPayableTotal':
    case 'repaymentAmount':
    case 'delinquentAmount':
    case 'totalExpensesAmount':
    case 'totalOutstandingAmount':
    case 'paidTotalAmount':
    case 'paidInterestAmount':
    case 'paidPenaltiesAmount':
    case 'totalPenaltyAmount':
    case 'paidPrincipalAmount':
    case 'netPayablePrincipal':
    case 'netPayableInterest':
    case 'netPayablePenalties':
      return (
        <TableCell key={key}>
          <Typography variant='body2' fontSize='0.85rem'>
            {formatNumber(loan[key] || 0, currency)}
          </Typography>
        </TableCell>
      );
    case 'loanStatus':
      return (
        <TableCell key={key}>
          <div>
            <Status status={loan[key]} />
          </div>
        </TableCell>
      );
    // Percent columns
    case 'rbfRevenueShare':
    case 'loanRate':
    case 'penaltyPercent':
    case 'annualRate':
    case 'monthlyRate':
    case 'dailyRate':
      return (
        <TableCell key={key}>
          <Typography variant='body2'>
            {loan[key as keyof typeof loan] !== null && loan[key as keyof typeof loan] !== undefined
              ? formatPercent(loan[key as keyof typeof loan] as number)
              : '-'}
          </Typography>
        </TableCell>
      );
    case 'loanOfficerUserId':
      return <TableCell key={key}>{loan.loanOfficerUser.email ?? '-'}</TableCell>;
    case 'stickyHeader':
      return loan._links.approveLoan ? (
        <StickyCell loan={loan} key={key} />
      ) : (
        <TableCell
          key={key}
          sx={{ position: 'sticky', right: 0, bgcolor: 'common.white', width: '104px' }}
        >
          <Box minHeight='35px' />
        </TableCell>
      );
    default:
      return <TableCell key={key}>{(loan[key as keyof typeof loan] as string) ?? '-'}</TableCell>;
  }
};
