import { TabOption } from 'lms-types';
import React from 'react';
import { StyledTabs, Wrapper } from './PageTabs.styles';
import { Tab } from '@mui/material';

type Props = {
  onChange(value: string): void;
  value: string;
  variant?: 'primary' | 'secondary';
  options: TabOption[];
};

export const PageTabs = ({ onChange, value, options, variant = 'primary' }: Props) => {
  const handleTabsChange = (_: React.SyntheticEvent, value: string) => {
    if (!value) return;
    onChange(value);
  };

  return (
    <Wrapper variant={variant}>
      <StyledTabs indicatorColor='secondary' value={value} onChange={handleTabsChange}>
        {options.map(({ label, value }, idx) => (
          <Tab key={idx} label={label} value={value} />
        ))}
      </StyledTabs>
    </Wrapper>
  );
};
