import { ChangeEvent, useMemo, useState } from 'react';
import { Props } from './RefinementRuleView.types';
import { Button, Select, TextField, Typography } from 'components';
import {
  Wrapper,
  Actions,
  ActionsContainer,
  InfoRow,
  AlertButton,
  WrapperOverlay,
} from './RefinementRuleVIew.styles';
import { RefinementRule } from 'bst-types';
import {
  editableFields,
  editableTextFields,
  initFormData,
  refinementSelectOptions,
} from './RefinementRuleView.config';
import { useDispatch } from 'hooks';
import {
  updateRefinementRule,
  createRefinementRule,
  deleteRefinementRule,
} from 'pages/RefinementRules/store/refinementRulesThunk';
import emptyStringToNull from 'utils/emptyStringToNull';

export const RefinementRuleView = ({ rule = initFormData, banksOpts, isNew = false }: Props) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(rule);
  const [isLoading, setIsLoading] = useState(false);
  const [showOverlay, setShowOverlay] = useState(isNew);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((formData) => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const isSomeFieldChanged = useMemo(() => {
    return editableFields.some(
      (field) => rule[field as keyof RefinementRule] !== formData[field as keyof RefinementRule],
    );
  }, [rule, formData]);

  const isNewRuleFormValid = useMemo(() => {
    const isBankSelected = formData.bank.length > 0;
    const isOperationSelected = formData.operation.length > 0;

    return isBankSelected && isOperationSelected;
  }, [formData]);

  const handleUpdateRule = async () => {
    try {
      setIsLoading(true);
      await dispatch(updateRefinementRule(formData));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateRule = async () => {
    try {
      setIsLoading(true);
      await dispatch(createRefinementRule(emptyStringToNull(formData)));
      setFormData(initFormData);
      handleCancelAddNew();
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteRule = async () => {
    try {
      setIsLoading(true);
      await dispatch(deleteRefinementRule({ ruleId: rule.rowNumber }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickOnAddNew = () => {
    setShowOverlay(false);
  };

  const handleCancelAddNew = () => {
    setShowOverlay(true);
  };

  return (
    <Wrapper>
      {showOverlay ? (
        <WrapperOverlay>
          <Button onClick={handleClickOnAddNew}>Add refinement rule</Button>
        </WrapperOverlay>
      ) : null}
      <ActionsContainer>
        <Typography variant='h3'>Rule {rule.rowNumber ?? '-'}</Typography>
        <Actions>
          {isNew ? (
            <>
              <Button
                isLoading={isLoading}
                disabled={!isNewRuleFormValid}
                onClick={handleCreateRule}
              >
                Add rule
              </Button>
              <AlertButton disabled={isLoading} onClick={handleCancelAddNew}>
                Cancel
              </AlertButton>
            </>
          ) : (
            <>
              <Button
                onClick={handleUpdateRule}
                isLoading={isLoading}
                disabled={!isSomeFieldChanged}
              >
                Save
              </Button>
              <AlertButton
                disabled={!rule.rowNumber}
                isLoading={isLoading}
                onClick={handleDeleteRule}
              >
                Delete
              </AlertButton>
            </>
          )}
        </Actions>
      </ActionsContainer>
      <InfoRow>
        <Select
          name='bank'
          fullWidth
          options={banksOpts}
          label='Bank'
          value={formData.bank}
          onChange={handleChange}
        />

        <Select
          name='operation'
          fullWidth
          options={refinementSelectOptions}
          label='Operation'
          value={formData.operation}
          onChange={handleChange}
        />

        {editableTextFields.map((field) => (
          <TextField
            fullWidth
            key={field}
            value={formData[field as keyof RefinementRule]}
            name={field}
            onChange={handleChange}
            label={field}
            sx={{
              '.MuiTypography-caption': {
                textTransform: 'capitalize',
              },
            }}
          />
        ))}
      </InfoRow>
    </Wrapper>
  );
};
