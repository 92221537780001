import { Box, IconButton, TableCell } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useModalState, useSelector } from 'hooks';
import { LmsModal, Loader, Table, Typography } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import axios from 'axios.config';
import {
  OperationsModalProps,
  TransactionOperations,
  ViewTransactionOperationProps,
} from './ViewTransactionOperations.types';
import { defaultErrorMessage, defaultMetaData } from 'Constants';
import { tableHeaders as loanScheduleTableHeaders } from '../../../LoanSchedule/LoanSchedule.config';
import { renderCells as renderLoanScheduleCells } from '../../../LoanSchedule/LoanSchedule.helpers';
import { tableHeaders as transactionTableHeaders } from '../../Transactions.config';
import { renderCells as renderTransactionCells } from '../../Transactions.helpers';
import { detailedLoanSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';
import { addNotification } from 'store/slices/globalSlice';
import { Transaction, TransactionWithLinks } from 'lms-types';

const emptyArr: Array<string> = [];

export const ViewTransactionOperations = ({ transaction }: ViewTransactionOperationProps) => {
  const [openModal, closeModal, isModalOpen] = useModalState();

  return (
    <>
      <TableCell key={transaction.operationId}>
        {transaction._links.getOperation ? (
          <IconButton sx={{ p: '3px' }} onClick={openModal}>
            <VisibilityIcon />
          </IconButton>
        ) : null}
      </TableCell>
      {isModalOpen ? (
        <OperationsModal
          onClose={closeModal}
          transactionId={transaction.operationId}
          url={transaction._links.getOperation?.href ?? ''}
        />
      ) : null}
    </>
  );
};

const OperationsModal = ({ url, transactionId, onClose }: OperationsModalProps) => {
  const dispatch = useDispatch();
  const loan = useSelector(detailedLoanSelector);
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<TransactionOperations>();

  useEffect(() => {
    fetchTransactionOperations();
  }, []);

  const fetchTransactionOperations = async () => {
    try {
      const { data } = (await axios.get(url)) as AxiosResponse<TransactionOperations>;
      setResponse(data);
    } catch (err) {
      dispatch(addNotification({ severity: 'error', message: defaultErrorMessage }));
    } finally {
      setIsLoading(false);
    }
  };

  const transactionHeaders = useMemo(() => {
    return [
      { id: 'createdAt', name: 'Created At' },
      ...transactionTableHeaders.filter(
        (header) => header.id !== 'viewOperation' && header.id !== 'stickyHeader',
      ),
    ];
  }, []);

  const loanScheduleHeaders = useMemo(() => {
    return [
      { id: 'entityId', name: 'Instalment Id' },
      ...loanScheduleTableHeaders.filter((header) => header.id !== 'totalForecasted'),
    ];
  }, []);

  const wrapRenderLoanScheduleCells = (
    key: keyof Transaction,
    transaction: TransactionWithLinks,
  ) => {
    switch (key) {
      case 'createdAt':
        return (
          <TableCell key={key + transaction.operationId}>
            <Typography variant='body2'>{transaction.createdAt}</Typography>
          </TableCell>
        );
      default:
        return renderTransactionCells(loan.currency)(key, transaction);
    }
  };

  return (
    <LmsModal
      open
      headerProps={{ title: `Transaction ${transactionId}'s operations`, onClose }}
      wrapperProps={{ width: '80%', height: '80%' }}
      footerProps={{ label: 'Done', onClick: onClose }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Table
            hideheaderDropdown={true}
            data={[response as TransactionOperations]}
            metaData={defaultMetaData}
            tableHeaders={transactionHeaders}
            sortBy={''}
            orderBy={'asc'}
            removableColumns={[]}
            sortFields={emptyArr}
            onSort={() => null}
            onSelect={() => null}
            renderCells={wrapRenderLoanScheduleCells}
            disablePagination
          />
          {response?.documentNumber ? (
            <Box display='flex' alignItems='center' gap={1} mt={2}>
              <Typography variant='h3'>Document number:</Typography>
              <Typography>{response.documentNumber}</Typography>
            </Box>
          ) : null}
          {response?.note ? (
            <Box display='flex' alignItems='center' gap={1} mt={2}>
              <Typography variant='h2'>Note:</Typography>
              <Typography>{response.note}</Typography>
            </Box>
          ) : null}

          <Typography variant='h2' sx={{ mt: 3 }} textAlign={'center'}>
            Instalments
          </Typography>

          <Table
            data={response?.instalments ?? []}
            metaData={defaultMetaData}
            tableHeaders={loanScheduleHeaders}
            sortBy={''}
            orderBy={'asc'}
            removableColumns={[]}
            sortFields={emptyArr}
            onSort={() => null}
            onSelect={() => null}
            renderCells={renderLoanScheduleCells}
            disablePagination
          />
        </>
      )}
    </LmsModal>
  );
};
