/* eslint-disable no-case-declarations */
import { TableCell } from '@mui/material';
import { ViewRevioDisbursementsResponse } from 'integrations/lms-api/loan-payment-information-api';
import { formatDate } from 'utils/formatDate';
import { formatNumber } from 'utils/formatNumber';

export const renderCells = (key: string, disbursement: ViewRevioDisbursementsResponse) => {
  switch (key) {
    case 'disbursementRequestId':
      return <TableCell key={key}>{disbursement.disbursementRequestId}</TableCell>;
      case 'status':
        return (
          <TableCell
          key={key}
          sx={{
            fontWeight: '500 !important',
          }}
          >
          {disbursement.status}
        </TableCell>
      );
      case 'amount':
        return (
          <TableCell key={key}>
          {formatNumber(disbursement.amount / 100 ?? 0, disbursement.currency ?? 'ZAR')}
        </TableCell>
      );
      case 'paymentId':
        return <TableCell key={key}>{disbursement.paymentId}</TableCell>;
      case 'paymentDate':
        return <TableCell key={key}>{formatDate(disbursement.paymentDate)}</TableCell>;
    default:
      return <TableCell key={key}>Column not supported</TableCell>;
  }
};
