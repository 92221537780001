import {
  Loader,
  Revenues,
  PageTabs,
  ErrorPage,
  Typography,
  AppPageWrapper,
  RegisterExpensesModal,
  DetailedPageSidebar,
  AddDisbursementModal,
  ReconcilePaymentModal,
  RequestDisbursementModal,
} from 'components';
import {
  Notes,
  History,
  LoanDetails,
  Breadcrumbs,
  Transactions,
  LoanSchedule,
  AccruedInterest,
  LoanPaymentInformation,
} from './components';
import { useDispatch, useModalState, useSelector } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  getDetailedLoan,
  getDetailedLoanNotes,
  getCustomerRelatedLoans,
  getDetailedLoanActionLogs,
  getDetailedLoanTransactions,
  getDetailedLoanAccruedInterest,
} from './store/detailedLoanThunk';
import {
  detailedLoanSelector,
  isDetailedLoanLoadingSelector,
  detailedLoanErrorStatusSelector,
} from './store/detailedLoanSlice';
import {
  allLoanTabs,
  loanDetailsTab,
  loanTransactionsTab,
  loanAccruedInterestTab,
  loanNotesTab,
  loanHistoryTab,
  loanRevenuesTab,
  loanScheduleTab,
  loanPaymentInformationTab,
} from './DetailedLoan.config';

export const DetailedLoan = () => {
  const dispatch = useDispatch();
  const params = useParams<{ loanId: string }>();
  const loan = useSelector(detailedLoanSelector);
  const isLoading = useSelector(isDetailedLoanLoadingSelector);
  const detailedLoanErrorStatus = useSelector(detailedLoanErrorStatusSelector);
  const [tabsValue, setTabsValue] = useState(loanDetailsTab.value);
  const [openDisbursementModal, closeDisbursementModal, isDisbursementModalOpen] = useModalState();
  const [openReconcilePaymentModal, closeReconcilePaymenteModal, isReconcilePaymentModalOpen] =
    useModalState();

  const [openRegisterExpensesModal, closeRegisterExpensesModal, isRegisterExpensesModalOpen] =
    useModalState();

  const [
    openRequestDisbursementModal,
    closeRequestDisbursementModal,
    isRequestDisbursementModalOpen,
  ] = useModalState();

  const loanId = useMemo(() => {
    return params?.loanId ?? '';
  }, [params?.loanId]);

  const tabOptions = useMemo(() => {
    return allLoanTabs.filter((tab) => tab.isVisibleFor(loan));
  }, [loan]);

  useEffect(() => {
    if (tabOptions.findIndex((tab) => tab.value === tabsValue) == -1) {
      setTabsValue(loanDetailsTab.value);
    }
  }, [tabOptions, tabsValue]);
  useEffect(() => {
    void fetchAppData();
  }, [loanId]);

  const fetchAppData = async () => {
    const { payload } = await dispatch(getDetailedLoan(loanId));
    if (payload && typeof payload !== 'number') {
      dispatch(getCustomerRelatedLoans({ companyIdentifier: payload.companyIdentifier }));
    }
    dispatch(getDetailedLoanNotes({ loanId }));
    dispatch(getDetailedLoanActionLogs({ loanId }));
    dispatch(getDetailedLoanTransactions({ loanId }));
    dispatch(getDetailedLoanAccruedInterest({ loanId }));
  };

  const handleTabsChange = (tab: string) => setTabsValue(tab);

  if (detailedLoanErrorStatus) {
    return (
      <ErrorPage
        status={detailedLoanErrorStatus}
        description={'It looks like the loan you are looking for does not exist!'}
      >
        <Typography variant='h2'>
          Please try navigating from the <Link to='/loans'>loans menu</Link>.
        </Typography>
      </ErrorPage>
    );
  }

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <DetailedPageSidebar isLoading={isLoading} customer={loan.company} />

      <AppPageWrapper hasSidebar>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Breadcrumbs loan={loan} />
            <PageTabs options={tabOptions} value={tabsValue} onChange={handleTabsChange} />
            {tabsValue === loanDetailsTab.value ? (
              <LoanDetails
                loan={loan}
                openReconcilePaymentModal={openReconcilePaymentModal}
                openDisbursementModal={openDisbursementModal}
                openRegisterExpensesModal={openRegisterExpensesModal}
                openRequestDisbursementModal={openRequestDisbursementModal}
              />
            ) : null}
            {tabsValue === loanTransactionsTab.value ? (
              <Transactions
                loan={loan}
                openDisbursementModal={openDisbursementModal}
                openReconcilePaymentModal={openReconcilePaymentModal}
                openRegisterExpensesModal={openRegisterExpensesModal}
              />
            ) : null}
            {tabsValue === loanAccruedInterestTab.value ? <AccruedInterest /> : null}
            {tabsValue === loanRevenuesTab.value ? <Revenues /> : null}
            {tabsValue === loanScheduleTab.value ? <LoanSchedule /> : null}
            {tabsValue === loanHistoryTab.value ? <History /> : null}
            {tabsValue === loanPaymentInformationTab.value ? (
              <LoanPaymentInformation loan={loan} navigate={setTabsValue} />
            ) : null}
            {tabsValue === loanNotesTab.value ? <Notes loanId={loan.loanId} /> : null}
          </>
        )}
      </AppPageWrapper>
      {isDisbursementModalOpen ? <AddDisbursementModal onClose={closeDisbursementModal} /> : null}
      {isReconcilePaymentModalOpen ? (
        <ReconcilePaymentModal
          isOpen={isReconcilePaymentModalOpen}
          onClose={closeReconcilePaymenteModal}
        />
      ) : null}
      {isRegisterExpensesModalOpen ? (
        <RegisterExpensesModal
          isOpen={isRegisterExpensesModalOpen}
          onClose={closeRegisterExpensesModal}
        />
      ) : null}
      {isRequestDisbursementModalOpen ? (
        <RequestDisbursementModal
          isOpen={isRequestDisbursementModalOpen}
          onClose={closeRequestDisbursementModal}
        />
      ) : null}
    </div>
  );
};
