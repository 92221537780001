import { useState } from 'react';
import { CollapseBox, IconButton, Loader, Typography } from 'components';
import { Customer } from 'lms-types';
import {
  Container,
  Wrapper,
  FlexContainer,
  VerticalFlex,
  Link,
} from './DetailedPageSidebar.styles';
import { Box } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SmsIcon from '@mui/icons-material/SmsOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'hooks';
import { isSidebarOpenSelector, setSidebarOpenState } from 'store/slices/globalSlice';

type Props = {
  customer: Customer;
  isLoading: boolean;
};

export const DetailedPageSidebar = ({ customer, isLoading }: Props) => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(isSidebarOpenSelector);
  const [openedBoxes, setOpenedBoxes] = useState<Array<string>>([]);

  const handleOpenBox = (id?: string) => {
    if (!id || openedBoxes.includes(id)) return;
    setOpenedBoxes((openedBoxes) => [...openedBoxes, id]);
  };

  const handleCloseBox = (id?: string) => {
    if (!id) return;
    setOpenedBoxes((openedBoxes) => openedBoxes.filter((box) => box !== id));
  };

  const handleOpenSidebar = () => {
    dispatch(setSidebarOpenState(true));
  };

  const handleCloseSidebar = () => {
    dispatch(setSidebarOpenState(false));
  };

  return (
    <Wrapper isOpen={isSidebarOpen}>
      {isLoading ? (
        <Loader />
      ) : isSidebarOpen ? (
        <>
          <Container>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h1'>{customer.companyLegalName}</Typography>
              <IconButton onClick={handleCloseSidebar}>
                <MenuOpenIcon sx={{ width: '1.5rem !important', height: '1.5rem !important' }} />
              </IconButton>
            </Box>
            <Box
              display='flex'
              gap='1rem'
              mt='1rem'
              alignItems='center'
              sx={{ '& > svg': { width: '1rem', height: '1rem' } }}
            >
              <LocalPhoneIcon color='primary' />
              <Typography color='primary' variant='body2'>
                {customer.phone}
              </Typography>
            </Box>
            <Box
              display='flex'
              gap='1rem'
              mt='.3rem'
              alignItems='center'
              sx={{ '& > svg': { width: '1rem', height: '1rem' } }}
            >
              <SmsIcon color='primary' />
              <Typography color='primary' variant='body2'>
                {customer.email}
              </Typography>
            </Box>
          </Container>
          <CollapseBox
            title='Company Details'
            id={'companyDetails'}
            isCollapsed={!openedBoxes.includes('companyDetails')}
            icon={<BusinessCenterOutlinedIcon />}
            onOpen={handleOpenBox}
            onClose={handleCloseBox}
          >
            <VerticalFlex>
              {customer.salesforceAccountId ? (
                <FlexContainer>
                  <Typography variant='body2'>Salesforce Id</Typography>
                  <Typography variant='body2' color='secondary'>
                    {customer.salesforceAccountId}
                  </Typography>
                </FlexContainer>
              ) : null}
              <FlexContainer>
                <Typography variant='body2'>Contact person</Typography>
                <Typography variant='body2' color='secondary'>
                  {customer.contactName}
                </Typography>
              </FlexContainer>
              <FlexContainer>
                <Typography variant='body2'>Date of incorporation</Typography>
                <Typography variant='body2' color='secondary'>
                  {customer.incorporationDate}
                </Typography>
              </FlexContainer>
              <FlexContainer>
                <Typography variant='body2'>Country of incorporation</Typography>
                <Typography variant='body2' color='secondary'>
                  {customer.incorporationCountry}
                </Typography>
              </FlexContainer>
              <FlexContainer>
                <Typography variant='body2'>Company type</Typography>
                <Typography variant='body2' color='secondary'>
                  {customer.companyType}
                </Typography>
              </FlexContainer>

              {customer.partnerCompany ? (
                <FlexContainer>
                  <Typography variant='body2'>Partner</Typography>
                  <Link to={`/customers/${customer.partnerCompanyIdentifier}`}>
                    {customer.partnerCompany.companyLegalName}
                  </Link>
                </FlexContainer>
              ) : null}
            </VerticalFlex>
          </CollapseBox>
          <CollapseBox
            title='Account'
            id={'account'}
            isCollapsed={!openedBoxes.includes('account')}
            icon={<FolderSharedOutlinedIcon />}
            onOpen={handleOpenBox}
            onClose={handleCloseBox}
          >
            <VerticalFlex>
              <FlexContainer>
                <Typography variant='body2'>Created date</Typography>
                <Typography variant='body2' color='secondary'>
                  {customer.createdAt}
                </Typography>
              </FlexContainer>
              <FlexContainer>
                <Typography variant='body2'>Company source</Typography>
                <Typography variant='body2' color='secondary'>
                  {customer.companySourceType}
                </Typography>
              </FlexContainer>
              <FlexContainer>
                <Typography variant='body2'>Loan Officer</Typography>
                <Typography variant='body2' color='secondary'>
                  {customer.loanOfficerUser.email}
                </Typography>
              </FlexContainer>
              <FlexContainer>
                <Typography variant='body2'>Credit score</Typography>
                <Typography variant='body2' color='secondary'>
                  {customer.creditScore}
                </Typography>
              </FlexContainer>
              <FlexContainer>
                <Typography variant='body2'>Compliance status</Typography>
                <Typography variant='body2' color='secondary'>
                  {customer.isComplianceApproved ? 'Approved' : 'Not approved'}
                </Typography>
              </FlexContainer>
              <FlexContainer>
                <Typography variant='body2'>Compliance review date</Typography>
                <Typography variant='body2' color='secondary'>
                  {customer.nextComplianceReviewData}
                </Typography>
              </FlexContainer>
            </VerticalFlex>
          </CollapseBox>
          <CollapseBox
            title='Bank details'
            id={'bank'}
            isCollapsed={!openedBoxes.includes('bank')}
            icon={<AccountBalanceOutlinedIcon />}
            onOpen={handleOpenBox}
            onClose={handleCloseBox}
          >
            <VerticalFlex>
              <FlexContainer>
                <Typography variant='body2'>Bank name</Typography>
                <Typography variant='body2' color='secondary'>
                  {customer.bankName}
                </Typography>
              </FlexContainer>
              {customer.incorporationCountry === 'South Africa' ? (
                <>
                  <FlexContainer>
                    <Typography variant='body2'>Branch Code</Typography>
                    <Typography variant='body2' color='secondary'>
                      {customer.branchCode ?? ''}
                    </Typography>
                  </FlexContainer>
                  <FlexContainer>
                    <Typography variant='body2'>Account Number</Typography>
                    <Typography variant='body2' color='secondary'>
                      {customer.accountNumber ?? ''}
                    </Typography>
                  </FlexContainer>
                </>
              ) : (
                <>
                  <FlexContainer>
                    <Typography variant='body2'>SWIFT</Typography>
                    <Typography variant='body2' color='secondary'>
                      {customer.swift}
                    </Typography>
                  </FlexContainer>
                  <FlexContainer>
                    <Typography variant='body2'>IBAN</Typography>
                    <Typography variant='body2' color='secondary'>
                      {customer.iban}
                    </Typography>
                  </FlexContainer>
                </>
              )}
              <FlexContainer>
                <Typography variant='body2'>Bank address</Typography>
                <Typography variant='body2' color='secondary' textAlign='right'>
                  {customer.bankAddress}
                </Typography>
              </FlexContainer>
            </VerticalFlex>
          </CollapseBox>
        </>
      ) : (
        <IconButton onClick={handleOpenSidebar} sx={{ width: '36px' }}>
          <MenuIcon sx={{ width: '1.5rem !important', height: '1.5rem !important' }} />
        </IconButton>
      )}
    </Wrapper>
  );
};
