export const tableHeaders = [
  { name: 'Date', id: 'createdAt' },
  { name: 'Action', id: 'action' },
  { name: 'Data', id: 'data' },
  { name: 'Author', id: 'user' },
];

export const mappedColumnIdsToNames = tableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);
