import { Button, Dropdown, PopperMenu, SearchField, TextField } from 'components';
import { FiltersContainer, Wrapper, CloseIcon } from './Filters.styles';
import {
  FilterTypes,
  Props,
  QueryFilterNames,
  RangeFilterTypes,
  SelectFilterTypes,
} from './Filters.types';
import { useMemo, useRef, useState } from 'react';
import {
  defaultFilterOptions,
  defaultRangeFilterData,
  filterTitles,
  nameToQueryParam,
  rangeDateFilters,
  rangeFilters,
} from './Filters.config';
import { useSearchParams } from 'react-router-dom';
import deleteParamsEntry from 'utils/deleteParamsEntry';
import { PopperOption } from 'components/organisms/PopperMenu/PopperMenu.types';
import { Box } from '@mui/material';
import { useSelector } from 'hooks';
import { selectedTransactionsSelector } from 'pages/DetailedCompany/store/detailedCompanySlice';
import { useFilterEffects } from './hooks/useFilterEffects';

export const Filters = ({ onOpenClassifyModal }: Props) => {
  const amountRef = useRef<HTMLDivElement | null>(null);
  const balanceAmountRef = useRef<HTMLDivElement | null>(null);
  const bankRef = useRef<HTMLDivElement | null>(null);
  const currencyRef = useRef<HTMLDivElement | null>(null);
  const transactionDateRef = useRef<HTMLDivElement | null>(null);
  const valueDateRef = useRef<HTMLDivElement | null>(null);
  const classificationRef = useRef<HTMLDivElement | null>(null);

  const selectedTransactions = useSelector(selectedTransactionsSelector);

  const [activeDropdown, setActiveDropdown] = useState<FilterTypes | ''>('');
  const [rangeFiltersData, setRangeFiltersData] = useState(defaultRangeFilterData);
  const [searchValue, setSearchValue] = useState('');
  const [filterOptions, setFilterOptions] = useState(defaultFilterOptions);

  const [searchParams, setSearchParams] = useSearchParams();

  useFilterEffects({ searchValue, setSearchValue, setFilterOptions, setRangeFiltersData });

  const filterRefs = useMemo(
    () => ({
      amount: amountRef,
      balanceAmount: balanceAmountRef,
      transactionDate: transactionDateRef,
      valueDate: valueDateRef,
      currency: currencyRef,
      bank: bankRef,
      classification: classificationRef,
    }),
    [
      amountRef.current,
      balanceAmountRef.current,
      bankRef.current,
      currencyRef.current,
      transactionDateRef.current,
      valueDateRef.current,
      classificationRef.current,
    ],
  );

  const handleCloseMenu = () => {
    setActiveDropdown('');
  };

  const hasAppliedFilters = useMemo(() => {
    const { bank, currency } = filterOptions;

    const hasAnyRangeFilter = rangeFilters.some((filter) =>
      searchParams.get(nameToQueryParam[filter]),
    );

    const hasAnyBankFilter = bank.some((filter) => filter.selected);
    const hasAnyCurrencyFilter = currency.some((filter) => filter.selected);

    return hasAnyBankFilter || hasAnyCurrencyFilter || hasAnyRangeFilter;
  }, [filterOptions, searchParams]);

  const handleValueChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(evt.target.value);
  };

  const handleDropdownClick = (id?: string) => {
    if (!id) return;
    setActiveDropdown(id as FilterTypes);
  };

  const handleSelect = (filterType: QueryFilterNames) => (id: string, selected: boolean) => {
    const activeFiltersOptionList = filterOptions[activeDropdown as SelectFilterTypes];

    setFilterOptions((opts) => ({
      ...opts,
      [activeDropdown]: activeFiltersOptionList.map((popperOption) =>
        popperOption.id === id
          ? { ...popperOption, selected }
          : { ...popperOption, selected: false },
      ),
    }));

    let updatedParams = searchParams;

    if (selected) {
      updatedParams = deleteParamsEntry(updatedParams, filterType);
      updatedParams.append(filterType, id);
    } else {
      updatedParams = deleteParamsEntry(updatedParams, filterType, id);
    }

    setSearchParams(updatedParams);
  };

  const handleDropAllFilters = () => {
    let updatedParams = searchParams;

    const { bank, currency, classification } = filterOptions;

    const deselectAndRemoveFromParams =
      (filterType: QueryFilterNames) => (option: PopperOption) => {
        if (option.selected) {
          updatedParams = deleteParamsEntry(updatedParams, filterType, option.id);
        }

        return { ...option, selected: false };
      };

    const mappedBank = bank.map(deselectAndRemoveFromParams('bank'));
    const mappedCurrency = currency.map(deselectAndRemoveFromParams('currency'));
    const mappedClassification = classification.map(deselectAndRemoveFromParams('classification'));

    rangeFilters.forEach((filter) => {
      updatedParams = deleteParamsEntry(updatedParams, nameToQueryParam[filter]);
    });

    setRangeFiltersData(defaultRangeFilterData);

    setFilterOptions({
      bank: mappedBank,
      currency: mappedCurrency,
      classification: mappedClassification,
    });
    setSearchParams(updatedParams);
  };

  const handleFilterDataChange =
    (type: RangeFilterTypes, range: 'from' | 'to') => (e: React.ChangeEvent<HTMLInputElement>) => {
      setRangeFiltersData((rangeFilters) => ({
        ...rangeFilters,
        [type as string]: { ...rangeFilters[type], [range]: e.target.value },
      }));
    };

  const handleApplyRangeFilter = (filter: RangeFilterTypes) => () => {
    setSearchParams((searchParams) => {
      const updatedSearchParams = deleteParamsEntry(searchParams, nameToQueryParam[filter]);

      updatedSearchParams.append(
        nameToQueryParam[filter],
        `${rangeFiltersData[filter].from || '-inf'},${rangeFiltersData[filter].to || 'inf'}`,
      );
      return updatedSearchParams;
    });
  };

  return (
    <>
      <Wrapper id='filters'>
        <SearchField placeholder='Search' value={searchValue} onChange={handleValueChange} />
        <FiltersContainer>
          <Dropdown
            ref={classificationRef}
            label='Classification'
            onClick={handleDropdownClick}
            id='classification'
            isActive={activeDropdown === 'amount'}
          />
          <Dropdown
            ref={amountRef}
            label='Amount'
            onClick={handleDropdownClick}
            id='amount'
            isActive={activeDropdown === 'amount'}
          />
          <Dropdown
            label='Balance Amount'
            onClick={handleDropdownClick}
            id='balanceAmount'
            ref={balanceAmountRef}
            isActive={activeDropdown === 'balanceAmount'}
          />
          <Dropdown
            label='Bank'
            onClick={handleDropdownClick}
            id='bank'
            ref={bankRef}
            isActive={activeDropdown === 'bank'}
          />
          <Dropdown
            label='Currency'
            onClick={handleDropdownClick}
            id='currency'
            ref={currencyRef}
            isActive={activeDropdown === 'currency'}
          />
          <Dropdown
            label='Transaction Date'
            onClick={handleDropdownClick}
            id='transactionDate'
            ref={transactionDateRef}
            isActive={activeDropdown === 'transactionDate'}
          />
          {hasAppliedFilters ? <CloseIcon onClick={handleDropAllFilters} /> : null}
        </FiltersContainer>
        {selectedTransactions.length > 0 ? (
          <Button onClick={onOpenClassifyModal}>Classify selected</Button>
        ) : null}
      </Wrapper>
      <PopperMenu
        open={Boolean(activeDropdown)}
        onClose={handleCloseMenu}
        title={filterTitles[activeDropdown as FilterTypes] ?? ''}
        options={filterOptions[activeDropdown as SelectFilterTypes]}
        anchorEl={filterRefs[activeDropdown as FilterTypes]?.current}
        multiple
        onSelect={handleSelect(nameToQueryParam[activeDropdown as FilterTypes])}
        placement='bottom-start'
      >
        {rangeFilters.includes(activeDropdown) ? (
          <Box display='flex' padding='12px' flexDirection='column' gap='.5rem'>
            <TextField
              label='From'
              value={rangeFiltersData[activeDropdown as RangeFilterTypes].from}
              onChange={handleFilterDataChange(activeDropdown as RangeFilterTypes, 'from')}
              type={rangeDateFilters.includes(activeDropdown) ? 'date' : 'number'}
            />
            <TextField
              label='To'
              value={rangeFiltersData[activeDropdown as RangeFilterTypes].to}
              onChange={handleFilterDataChange(activeDropdown as RangeFilterTypes, 'to')}
              type={rangeDateFilters.includes(activeDropdown) ? 'date' : 'number'}
            />
            <Button
              disabled={
                activeDropdown === 'transactionDate' &&
                (!rangeFiltersData.transactionDate.to || !rangeFiltersData.transactionDate.from)
              }
              label='Apply'
              onClick={handleApplyRangeFilter(activeDropdown as RangeFilterTypes)}
            />
          </Box>
        ) : null}
      </PopperMenu>
    </>
  );
};
