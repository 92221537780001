/* eslint-disable no-case-declarations */
import { TableCell } from '@mui/material';
import { RecurringToken } from 'pages/DetailedLoan/store/detailedLoanSlice.types';


export const renderCells = (key: keyof RecurringToken, token: RecurringToken) => {
  switch (key) {
    default:
      return <TableCell key={key}>{token[key]}</TableCell>;
  }
};
