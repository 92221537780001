import { styled } from 'theme';
import { Tabs as MuiTabs, tabsClasses } from '@mui/material';

type WrapperProps = {
  variant: 'primary' | 'secondary';
};

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<WrapperProps>`
  border-radius: 4px;
  margin-top: 1.5rem;
  padding: ${(props) => (props.variant === 'primary' ? '0.9rem 1.5rem' : '0rem 1.5rem 0.5rem')};

  ${(props) =>
    props.variant === 'primary'
      ? `
        border: 1px solid ${props.theme.palette.secondary.light};
        padding-bottom: 0;
        margin-bottom: 1.5rem;
      `
      : null}
`;

export const StyledTabs = styled(MuiTabs)`
  & .Mui-selected {
    color: ${(props) => props.theme.palette.primary.dark} !important;
  }

  & .${tabsClasses.fixed} {
    padding-bottom: 10px;
  }
`;
