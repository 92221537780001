export default function deleteParamsEntry(params: URLSearchParams, key: string, value?: string) {
  let newEntries;
  if (value) {
    newEntries = Array.from(params.entries()).filter(([k, v]) => !(k === key && v === value));
  } else {
    newEntries = Array.from(params.entries()).filter(([k]) => !(k === key));
  }

  return new URLSearchParams(newEntries);
}
