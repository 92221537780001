import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';
import { appEnv } from 'lms.config';

export const makeStore = () =>
  configureStore({
    reducer: rootReducer,
    devTools: appEnv !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  });

export const store = makeStore();

export type AppDispatch = typeof store.dispatch;
