import { TabsWrapper as Wrapper } from '../../CustomerAndLoanModal.styles';
import { CURRENCY_PLACEHOLDER, FormDataType, groupedFields } from './CreateLoan.config';
import { EventType } from 'hooks/useFormData';
import { useDispatch } from 'hooks';
import { useEffect, useMemo } from 'react';
import { LoanFormSection } from './LoanFormSection';
import { getLoanModalSelectOptions } from 'store/thunks/customerAndLoanModalThunk';
import { useCompanyCurrency } from '../../CustomerAndLoanModal.hooks';
import { CurrenciesMap } from 'Constants';
import { GroupedFieldsType } from '../../CustomerAndLoanModal.types';

type Props = {
  formData: FormDataType;
  fieldHelpers: Partial<FormDataType>;
  handleChange(evt: EventType): void;
  onSubmit(): void;
  setFieldError(evt: keyof FormDataType, value?: string): void;
  onValidate?(field: keyof FormDataType): void;
};

export const CreateLoan = ({
  formData,
  fieldHelpers,
  onSubmit,
  handleChange,
  setFieldError,
  onValidate,
}: Props) => {
  const dispatch = useDispatch();

  const loanCompanyCurrency = useCompanyCurrency(formData);

  const mappedGroupedFields = useMemo(() => {
    const mappedLoadDetailsFields = groupedFields['Loan data'].map((field) =>
      field.name === 'principalAmount'
        ? {
            ...field,
            label: field.label.replace(CURRENCY_PLACEHOLDER, CurrenciesMap[loanCompanyCurrency]),
          }
        : field,
    );

    return {
      ...groupedFields,
      'Loan data': mappedLoadDetailsFields,
    } as GroupedFieldsType<FormDataType>;
  }, [loanCompanyCurrency]);

  useEffect(() => {
    dispatch(getLoanModalSelectOptions());
  }, []);

  const handleValidate = (field: keyof FormDataType) => async () => {
    onValidate?.(field);
  };

  const removeValidationError = (field: keyof FormDataType) => () => {
    setFieldError(field);
  };

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    onSubmit();
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      {Object.keys(mappedGroupedFields).map((groupKey) => {
        const fields = mappedGroupedFields[groupKey];

        return (
          <LoanFormSection
            fields={fields}
            title={groupKey}
            fieldHelpers={fieldHelpers}
            formData={formData}
            handleChange={handleChange}
            handleValidate={handleValidate}
            removeValidationError={removeValidationError}
            key={groupKey}
          />
        );
      })}
    </Wrapper>
  );
};
