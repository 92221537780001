import { useCallback, useMemo } from 'react';
import {
  confirmSignIn,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  signIn as awsSignIn,
  signOut as awsSignOut,
} from 'aws-amplify/auth';
import { useDispatch } from './reduxHooks';
import { dropUser, setUser } from 'store/slices/authSlice';
import { useNavigate } from 'react-router';
import { AmplifyError } from 'lms-types';
import { addNotification, setGlobalLoading } from 'store/slices/globalSlice';

export const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signIn = useCallback(async (email: string, password: string) => {
    try {
      const { nextStep } = await awsSignIn({
        username: email,
        password,
      });

      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        navigate('/new-password');
      }
      const currentUser = await getCurrentUser();
      const userAttributes = await fetchUserAttributes();
      const userSession = await fetchAuthSession();

      dispatch(
        setUser({
          username: currentUser.username,
          attributes: userAttributes,
          signInUserSession: {
            accessToken: {
              jwtToken: userSession.tokens?.accessToken.toString() ?? '',
            },
          },
          challengeName: nextStep.signInStep,
        }),
      );
    } catch (err) {
      dispatch(addNotification({ severity: 'error', message: 'Username or password is wrong' }));
    }
  }, []);

  const completeNewPassword = useCallback(async (password: string) => {
    try {
      const { nextStep } = await confirmSignIn({ challengeResponse: password });

      const currentUser = await getCurrentUser();
      const userAttributes = await fetchUserAttributes();
      const userSession = await fetchAuthSession();

      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        dispatch(
          setUser({
            username: currentUser.username,
            attributes: userAttributes,
            signInUserSession: {
              accessToken: {
                jwtToken: userSession.tokens?.accessToken.toString() ?? '',
              },
            },
            challengeName: nextStep.signInStep,
          }),
        );
        navigate('/');
      }
    } catch (err) {
      const error = err as AmplifyError;
      if (error.code !== 'InvalidPasswordException') {
        dispatch(addNotification({ severity: 'error', message: 'Username or password is wrong' }));
      }
    }
  }, []);

  const checkUser = useCallback(async () => {
    try {
      const currentUser = await getCurrentUser();
      const userAttributes = await fetchUserAttributes();
      const userSession = await fetchAuthSession();

      dispatch(
        setUser({
          username: currentUser.username,
          attributes: userAttributes,
          signInUserSession: {
            accessToken: {
              jwtToken: userSession.tokens?.accessToken.toString() ?? '',
            },
          },
        }),
      );
    } catch (err) {
      dispatch(
        addNotification({ severity: 'error', message: 'Credentials could not be verified' }),
      );
    } finally {
      dispatch(setGlobalLoading(false));
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      await awsSignOut();
      dispatch(dropUser());
      dispatch({ type: 'logout/clear-state' });
      navigate('/');
    } catch (err) {
      navigate('/');
    }
  }, []);

  return useMemo(
    () => ({ signIn, completeNewPassword, checkUser, logout }),
    [signIn, checkUser, completeNewPassword, logout],
  );
};
