import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';
import palette from 'theme/palette/palette';

export const MuiTextField: Components<Theme>['MuiTextField'] = {
  defaultProps: {
    size: 'small',
    fullWidth: true,
    variant: 'outlined',
  },
  styleOverrides: {
    root: {
      '&:hover': {
        backgroundColor: palette.grey[950],
      },

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: `${palette.grey[900]} !important`,
      },

      '& .MuiFormHelperText-root': {
        color: '#aaa',
        fontSize: '.8rem',
        margin: '0.4rem 0 0 0',
        lineHeight: 1,
      },

      '& .MuiOutlinedInput-root': {
        padding: '0 !important',
        '&.Mui-focused fieldset': {
          borderColor: `${palette.primary.main} !important`,
          borderWidth: '2px',
        },

        '#end-adornment, #start-adornment': {
          zIndex: 1499,
        },

        // '&.Mui-disabled:hover fieldset': {
        //   borderColor: `${palette.grey[900]}`,
        // },

        '&.Mui-disabled fieldset': {
          borderColor: `${palette.grey[900]}`,
          backgroundColor: `${palette.grey[950]}`,
        },

        '&.Mui-disabled .MuiOutlinedInput-input': {
          backgroundColor: 'transparent',
          zIndex: 1499,
        },
      },

      '& .MuiOutlinedInput-input': {
        color: palette.secondary.main,
        backgroundColor: 'transparent',
        borderRadius: '.25rem',
        fontSize: '.9rem',
        fontWeight: 500,
        fontFamily: 'IBM Plex Sans',
        padding: '.625rem .75rem',

        '&.Mui-disabled': {
          color: '#4a4a4a',
          textFillColor: '#4a4a4a',
          backgroundColor: '#151922',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.grey[900],
        borderRadius: '.25rem',
        borderWidth: '2px',
      },
    },
  },
};
