import { styled } from 'theme';

import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

export const Breadcrumbs = styled(MuiBreadcrumbs)`
  & a {
    color: ${(props) => props.theme.palette.secondary.main};
    text-decoration: none;
    font-size: 12px;
  }
`;

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavigateLoansBtnsWrapper = styled('div')`
  display: flex;
  gap: 1rem;
`;
