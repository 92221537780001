import { DisbursementStatus, LoanStatus } from 'lms-types';
import { Wrapper } from './Status.styles';

type Props = {
  status: LoanStatus | DisbursementStatus;
};

export const Status = ({ status }: Props) => {
  return <Wrapper status={status}>{status}</Wrapper>;
};
