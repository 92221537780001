import { useEffect, useMemo } from 'react';
import {
  AppPageWrapper,
  Loader,
  DetailedPageSidebar,
  PageTabs,
  Revenues,
  ErrorPage,
  Typography,
} from 'components';
import { useDispatch, useSelector } from 'hooks';
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Breadcrumbs, CreditProfile, History, RelatedLoans } from './components';
import {
  getDetailedCustomer,
  getDetailedCustomersActionLogs,
} from './store/detailedCustomerSliceThunk';
import {
  detailedCustomerErrorStatusSelector,
  detailedCustomerSelector,
  isDetailedCustomerLoadingSelector,
} from './store/detailedCustomerSlice';
import { Wrapper } from './DetailedCustomer.styles';
import { tabOpts } from './DetailedCustomer.config';
import { getRevenues } from 'store/thunks/revenueSliceThunk';
import { getLastPartOfUrl } from 'utils/getLastPartOfUrl';

export const DetailedCustomer = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const detailedCustomerErrorStatus = useSelector(detailedCustomerErrorStatusSelector);
  const isLoading = useSelector(isDetailedCustomerLoadingSelector);
  const detailedCustomer = useSelector(detailedCustomerSelector);

  const customerId = useMemo(() => {
    return params?.customerId ?? '';
  }, [params?.customerId]);

  useEffect(() => {
    dispatch(getDetailedCustomer(customerId));
    dispatch(getDetailedCustomersActionLogs({ customerId }));
    dispatch(getRevenues({ customerId }));
  }, [customerId]);

  const handleTabsChange = (tab: string) => {
    navigate(
      { pathname: `/customers/${detailedCustomer.companyIdentifier}/${tab}` },
      { state: true },
    );
  };

  if (detailedCustomerErrorStatus) {
    return (
      <ErrorPage
        status={detailedCustomerErrorStatus}
        description={'It looks like the customer you are looking for does not exist!'}
      >
        <Typography variant='h2'>
          Please try navigating from the <Link to='/'>customers menu</Link>.
        </Typography>
      </ErrorPage>
    );
  }

  return (
    <Wrapper>
      <DetailedPageSidebar isLoading={isLoading} customer={detailedCustomer} />
      <AppPageWrapper hasSidebar>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Breadcrumbs customer={detailedCustomer} />
            <PageTabs
              options={tabOpts}
              value={getLastPartOfUrl(location.pathname)}
              onChange={handleTabsChange}
            />
            <Routes>
              <Route
                path='credit-profile'
                element={<CreditProfile customer={detailedCustomer} />}
              />
              <Route path='loans' element={<RelatedLoans />} />
              <Route path='revenue' element={<Revenues />} />
              <Route path='history' element={<History />} />
              <Route path='*' element={<Navigate to={'credit-profile'} />} />
            </Routes>
          </>
        )}
      </AppPageWrapper>
    </Wrapper>
  );
};
