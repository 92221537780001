import { useEffect, useMemo } from 'react';
import { FormDataType as LoanFormDataType } from './components/CreateLoan/CreateLoan.config';
import { FormDataType as CustomerFormDataType } from './components/CreateCustomer/CreateCustomer.config';
import { addYears, format } from 'date-fns';
import { EventType } from 'hooks/useFormData';
import { useDispatch, useSelector } from 'hooks';
import { modalPicklistOptionsSelector } from 'store/slices/customerAndLoanModalSlice';
import {
  getComputedIBFPrincipal,
  getComputedInterest,
} from 'store/thunks/customerAndLoanModalThunk';
import { FetchInterestAmountBody, FetchRBFPrincipalAmountBody } from './CustomerAndLoanModal.types';
import { LoanType } from 'lms-types';
import { DefaultCurrency } from 'Constants';

export const useCompanyCurrency = (loanFormData: LoanFormDataType) => {
  const picklistOptions = useSelector(modalPicklistOptionsSelector);

  const loanCompanyCurrency = useMemo(() => {
    if (!picklistOptions) return DefaultCurrency;

    const selectedCompany = picklistOptions?.companyIdentifier?.find(
      (company) => company.value === loanFormData.companyIdentifier,
    );

    return selectedCompany?.currency ?? DefaultCurrency;
  }, [loanFormData.companyIdentifier, picklistOptions]);

  return loanCompanyCurrency;
};

export const useLoanOfficerDefault = (
  loanFormData: LoanFormDataType,
  handleChange: (e: EventType) => void,
) => {
  const picklistOptions = useSelector(modalPicklistOptionsSelector);

  useEffect(() => {
    if (!picklistOptions) return;

    const selectedCompany = picklistOptions?.companyIdentifier?.find(
      (company) => company.value === loanFormData.companyIdentifier,
    );
    if (!selectedCompany) return;

    handleChange({
      target: {
        name: 'loanOfficerUserId',
        value: selectedCompany?.loan_officer_id ?? '',
      },
    } as EventType);
  }, [loanFormData.companyIdentifier, picklistOptions]);
};

export const useFetchInterestAmount = (
  formData: LoanFormDataType,
  handleChange: (e: EventType) => void,
) => {
  const dispatch = useDispatch();

  const fetchInterestAmount = async (body: FetchInterestAmountBody) => {
    const { payload } = await dispatch(getComputedInterest(body));

    if (payload) {
      handleChange({
        target: {
          name: 'interestAmount',
          value: payload.interestAmount,
        },
      } as EventType);
    }
  };

  useEffect(() => {
    const { loanType, tenor, monthlyRate, principalAmount } = formData;

    if (!principalAmount || !tenor || !monthlyRate || !loanType || Number(loanType) < 0 || Number(monthlyRate) > 100)
      return;
    if (['IBF', 'IBF2'].includes(loanType) && !tenor) return;

    const body: FetchInterestAmountBody = {
      loanType: loanType as LoanType,
      principalAmount,
      monthlyRate,
      tenor,
    };

    fetchInterestAmount(body);
  }, [formData.loanType, formData.tenor, formData.monthlyRate, formData.principalAmount]);
};

export const useFetchIBFPrincipalAmount = (
  formData: LoanFormDataType,
  handleChange: (e: EventType) => void,
) => {
  const dispatch = useDispatch();

  const fetchRBFPrincipalAmount = async (body: FetchRBFPrincipalAmountBody) => {
    const { payload } = await dispatch(getComputedIBFPrincipal(body));

    if (payload) {
      handleChange({
        target: {
          name: 'principalAmount',
          value: payload.principalAmount,
        },
      } as EventType);
    }
  };

  useEffect(() => {
    const { loanType, invoicesDiscountRate, invoicesAmount } = formData;

    if (
      (loanType !== 'IBF' && loanType !== 'IBF2') ||
      !invoicesDiscountRate ||
      !invoicesAmount ||
      Number(invoicesDiscountRate) < 0 ||
      Number(invoicesDiscountRate) > 100
    )
      return;

    const body: FetchRBFPrincipalAmountBody = {
      invoicesDiscountRate,
      invoicesAmount,
    };

    fetchRBFPrincipalAmount(body);
  }, [formData.loanType, formData.invoicesDiscountRate, formData.invoicesAmount]);
};

export const useNextComplianceReviewDateCompute = (
  formData: CustomerFormDataType,
  handleChange: (e: EventType) => void,
) => {
  useEffect(() => {
    handleChange({
      target: {
        name: 'nextComplianceReviewDate',
        value: formData.isComplianceApproved ? format(addYears(new Date(), 1), 'yyyy-MM-dd') : '',
      },
    } as EventType);
  }, [formData.isComplianceApproved, formData.nextComplianceReviewDate]);
};

export const useClearPrincipalAmount = (
  formData: LoanFormDataType,
  handleChange: (e: EventType) => void,
) => {
  useEffect(() => {
    handleChange({
      target: {
        name: 'principalAmount',
        value: '',
      },
    } as EventType);
  }, [formData.loanType]);
};
