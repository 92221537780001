import { styled } from 'theme';

export const FlexContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const VerticalFlex = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;
export const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;
