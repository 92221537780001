import { Table } from 'components';
import { useSelector, useDispatch, useSkipFirstRenderEffect } from 'hooks';
import { detailedLoanRevioInformationSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';
import useSortingParams from 'hooks/useSortingParams';
import { tableHeaders as rawTableHeaders, mappedColumnIdsToNames } from './RevioInformation.config';
import { renderCells } from './RevioInformation.helpers';
import { getDetailedLoanRevioInformation } from 'pages/DetailedLoan/store/detailedLoanThunk';
import { Wrapper, DataGroup } from './RevioInformation.styles';
import { LoanDetail } from '../../..';
import { Divider, Typography } from '@mui/material';
import { defaultMetaData } from 'Constants';
import { useMemo } from 'react';
import { DetailedLoanWithLinks } from 'lms-types';

const emptyArr: Array<string> = [];

type Props = {
  loan: DetailedLoanWithLinks;
};

export const RevioInformation = ({ loan: detailedLoan }: Props) => {
  const dispatch = useDispatch();
  const { company, loan, recurringTokens } = useSelector(detailedLoanRevioInformationSelector);
  const recurringToken = useMemo(
    () =>
      recurringTokens.find((token) => token.recurringTokenId === loan?.recurringTokenId) || null,
    [recurringTokens, loan?.recurringTokenId],
  );

  const fetchRevioInformation = () => {
    dispatch(getDetailedLoanRevioInformation({ loanId: detailedLoan.loanId }));
  };

  const { tableHeaders, searchParams, handleChangePage, handleChangeRowsPerPage, handleSort } =
    useSortingParams(emptyArr, fetchRevioInformation, mappedColumnIdsToNames, rawTableHeaders);

  useSkipFirstRenderEffect(() => {
    if (!detailedLoan._links.getRevioCollectionGatewayLoan) return;

    fetchRevioInformation();
  }, [detailedLoan.loanId]);

  return (
    <>
      <Wrapper>
        {company ? (
          <DataGroup>
            <Typography variant='h3'>Client Information</Typography>
            <LoanDetail title='Email' message={company?.clientData?.email ?? '-'} />
            <LoanDetail title='Full Name' message={company?.clientData?.fullName ?? '-'} />
            <LoanDetail title='Personal code' message={company?.clientData?.personalCode ?? '-'} />
            <Divider />
            <LoanDetail title='Brand name' message={company?.clientData?.brandName ?? '-'} />
            <LoanDetail title='Business name' message={company?.clientData?.legalName ?? '-'} />
            <LoanDetail
              title='Registration number'
              message={company?.clientData?.registrationNumber ?? '-'}
            />
            <LoanDetail title='VAT number' message={company?.clientData?.taxNumber ?? '-'} />
            <LoanDetail title='Country' message={company?.clientData?.country ?? '-'} />
            <LoanDetail title='City, province' message={company?.clientData?.city ?? '-'} />
            <LoanDetail title='Street' message={company?.clientData?.streetAddress ?? '-'} />
            <LoanDetail title='ZIP code' message={company?.clientData?.zipCode ?? '-'} />
            <Divider />
            <LoanDetail title='Bank account' message={company?.clientData?.bankAccount ?? '-'} />
            <LoanDetail title='Bank code' message={company?.clientData?.bankCode ?? '-'} />
          </DataGroup>
        ) : (
          <Typography variant='h3'>No client information is available</Typography>
        )}
        {recurringToken ? (
          <DataGroup>
            <Typography variant='h3'>Recurring Token</Typography>
            <LoanDetail title='Token ID' message={recurringToken?.recurringTokenId ?? '-'} />
            <LoanDetail title='Token Name' message={recurringToken?.name ?? '-'} />
            <LoanDetail title='Service type' message={recurringToken?.serviceType ?? '-'} />
            <LoanDetail title='Status' message={recurringToken?.status ?? '-'} />
            <Divider />
            <LoanDetail title='First name' message={recurringToken?.firstName ?? '-'} />
            <LoanDetail title='Last name' message={recurringToken?.lastName ?? '-'} />
            <LoanDetail title='Phone' message={recurringToken?.phoneNumber ?? '-'} />
            <LoanDetail title='ID number' message={recurringToken?.idNumber ?? '-'} />
            <LoanDetail title='ID type' message={recurringToken?.idType ?? '-'} />
            <Divider />
            <LoanDetail title='Account number' message={recurringToken?.accountNumber ?? '-'} />
            <LoanDetail title='Account type' message={recurringToken?.accountType ?? '-'} />
            <LoanDetail title='Branch' message={recurringToken?.branchCode ?? '-'} />
          </DataGroup>
        ) : (
          <Typography variant='h3'>There is no recurring token set as active.</Typography>
        )}
      </Wrapper>
      {recurringTokens.length > 0 ? (
        <>
          <Typography variant='h2'>All recurring tokens</Typography>
          <Table
            hideheaderDropdown={true}
            data={recurringTokens}
            removableColumns={[]}
            sortFields={emptyArr}
            onSelect={() => null}
            renderCells={renderCells}
            sortBy={searchParams.get('sortBy') ?? ''}
            orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
            tableHeaders={tableHeaders}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSort}
            metaData={defaultMetaData}
          />
        </>
      ) : (
        <Typography variant='h3'>No recurring tokens found.</Typography>
      )}
    </>
  );
};
