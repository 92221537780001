export const formatPercent = (num?: number | null) => {
  if (num === null || num === undefined) return '';
  const formatter = Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  });

   return formatter.format(num) + '%';
};
