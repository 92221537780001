import { AppPageWrapper, Typography, Table } from 'components';
import { Filters } from './components';
import { useDispatch, useSelector } from 'hooks';
import { getAllCustomers } from './store/customerSliceThunk';
import { customersSelector } from './store/customersSlice';
import {
  defaultTableHeaders,
  defaultMappedColumnIdsToNames,
  includableTableHeaders,
  includableMappedColumnIdsToNames,
  defaultQueryParams,
} from './Customers.config';
import useSortingParams from 'hooks/useSortingParams';
import { renderCells } from './Customers.helpers';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export const Customers = () => {
  const dispatch = useDispatch();
  const data = useSelector(customersSelector);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const fetchCustomers = (searchParams: URLSearchParams) => {
    if (!searchParams.size) return;
    dispatch(getAllCustomers(searchParams));
  };

  const {
    searchParams,
    includedColumns,
    tableHeaders,
    handleSort,
    handleSearch,
    handleChangePage,
    handleChangeRowsPerPage,
    handleIncludeRows,
  } = useSortingParams(
    data?._embedded?.includeValues ?? [],
    fetchCustomers,
    defaultMappedColumnIdsToNames,
    defaultTableHeaders,
    includableMappedColumnIdsToNames,
    includableTableHeaders,
  );

  useEffect(() => {
    const queryColumns = searchParams.getAll('include');

    if (queryColumns.length) {
      setSearchParams(searchParams);
    } else {
      defaultQueryParams.forEach((param) => searchParams.append('include', param));
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <AppPageWrapper>
      <Typography variant='h1'>Customers</Typography>
      <Filters handleSearch={handleSearch} />
      <Table
        data={data.companies}
        removableColumns={includedColumns}
        sortBy={searchParams.get('sortBy') ?? ''}
        orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
        onSort={handleSort}
        onSelect={handleIncludeRows}
        metaData={data.metaData}
        sortFields={data._embedded.sortFields}
        tableHeaders={tableHeaders}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        renderCells={renderCells}
      />
    </AppPageWrapper>
  );
};
