import { styled } from 'theme';
import { Tabs as MuiTabs, Tab as MuiTab, tabsClasses } from '@mui/material';

export const Tabs = styled(MuiTabs)`
  & .${tabsClasses.fixed} {
    padding-bottom: 0.1rem;
  }
`;

export const Tab = styled(MuiTab)`
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 500;
`;
