import React from 'react';
import { TextField } from './SearchField.styles';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
// import { TextFieldProps } from '@mui/material';
import { TextFieldProps } from 'components/common/TextField/TextField';

type Props = Omit<TextFieldProps, 'sx'>;

export const SearchField = ({ ...props }: Props) => {
  return (
    <TextField {...props} size='small' InputProps={{ startAdornment: <SearchOutlinedIcon /> }} />
  );
};
