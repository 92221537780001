import {
  Wrapper,
  CreditScoreWrapper,
  CreditLimitWrapper,
  IbfLimitWrapper,
  RbfLimitWrapper,
} from './CreditProfile.styles';
import { Typography, CreditScore } from 'components';
import { CreditGauge } from './components';
import { CustomerWithLinks } from 'lms-types';
import { ActionsPanel, RelatedLoans } from '..';

type Props = {
  customer: CustomerWithLinks;
};

export const CreditProfile = ({ customer }: Props) => {
  return (
    <>
      <ActionsPanel />
      <Wrapper>
        <CreditScoreWrapper>
          <Typography variant='h3'>Credit score</Typography>
          <CreditScore score={customer.creditScore} size='large' />
          <Typography variant='h1' fontSize={'13px'}>
            Next review date: <b>{customer.nextReviewDate}</b>
          </Typography>
        </CreditScoreWrapper>

        <CreditLimitWrapper>
          <CreditGauge
            currency={customer.currency}
            title='General credit limit'
            limit={customer.creditLimit}
            utilised={customer.utilizedCredit}
            gap={customer.generalLimitGap}
            isGeneral
            breakdown={{
              utilisedIbf: customer.utilizedIbfCredit,
              utilisedRbf: customer.utilizedRbfCredit,
            }}
          />
        </CreditLimitWrapper>
        {customer.ibfCreditLimit ? (
          <IbfLimitWrapper>
            <CreditGauge
              currency={customer.currency}
              title='IBF credit limit'
              limit={customer.ibfCreditLimit}
              utilised={customer.utilizedIbfCredit}
              gap={customer.ibfLimitGap}
            />
          </IbfLimitWrapper>
        ) : null}
        {customer.rbfCreditLimit ? (
          <RbfLimitWrapper>
            <CreditGauge
              currency={customer.currency}
              title='RBF credit limit'
              limit={customer.rbfCreditLimit}
              utilised={customer.utilizedRbfCredit}
              gap={customer.rbfLimitGap}
            />
          </RbfLimitWrapper>
        ) : null}
      </Wrapper>
      <RelatedLoans showActions={false} />
    </>
  );
};
