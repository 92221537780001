import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { Disbursement } from 'lms-types';
import { RootState } from 'store';

type ApproveDisbursementModal = {
  isOpen: boolean;
  disbursement?: Disbursement;
  disbursements?: Disbursement[];
  actionType: ApproveDisbursementModalActionType;
};

export type ApproveDisbursementModalActionType = 'APPROVE' | 'CANCEL' | 'APPROVE_MANY';

const initialState: ApproveDisbursementModal = {
  isOpen: false,
  disbursement: undefined,
  disbursements: undefined,
  actionType: 'APPROVE',
};

const approveDisbursementModalSlice = createSlice({
  name: 'approveDisbursementModalSlice',
  initialState,
  reducers: {
    resetModalState() {
      return initialState;
    },
    openApproveDisbursementModal(
      state,
      action: PayloadAction<{
        action: ApproveDisbursementModalActionType;
        disbursement?: Disbursement;
        disbursements?: Disbursement[];
      }>,
    ) {
      state.actionType = action.payload.action;
      state.disbursement = action.payload.disbursement;
      state.disbursements = action.payload.disbursements;
      state.isOpen = true;
    },
    closeApproveDisbursementModal() {
      return initialState;
    },
  },
  extraReducers() {
    // no-op till implementation
  },
});

export const { resetModalState, closeApproveDisbursementModal, openApproveDisbursementModal } =
  approveDisbursementModalSlice.actions;

export default approveDisbursementModalSlice.reducer;

// Selectors:

const approveDisbursementSliceSelector = (store: RootState) => store.approveDisbursementModalSlice;

export const approveDisbursementModalDataSelector = createSelector(
  approveDisbursementSliceSelector,
  (state) => state,
);

export const isApproveDisbursementModalOpenSelector = createSelector(
  approveDisbursementSliceSelector,
  (state) => state.isOpen,
);
