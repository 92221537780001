import { Select, outlinedInputClasses, MenuItem as MuiMenuItem } from '@mui/material';
import { styled } from 'theme';

export const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'helperText',
})`
  border-radius: 0.5rem;
  & .${outlinedInputClasses.notchedOutline} {
    border-color: ${(props) => props.theme.palette.grey[900]};
    border-width: 2px;
    border-radius: 0.25rem;
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: ${(props) => props.theme.palette.grey[900]};
    border-width: 2px;
  }

  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-width: 2px;
  }

  &.${outlinedInputClasses.disabled}:hover .${outlinedInputClasses.notchedOutline} {
    border-color: rgba(0, 0, 0, 0.26) !important;
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  display: flex;
  justify-content: space-between !important;
  font-size: 0.9rem;
  gap: 1rem;
  padding-left: 1.5rem !important;

  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
