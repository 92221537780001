/* eslint-disable no-case-declarations */
import { TableCell } from '@mui/material';
import { Currency, Transaction, TransactionWithLinks } from 'lms-types';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentIcon from '@mui/icons-material/Payment';
import { formatNumber } from 'utils/formatNumber';
import { CancelTransaction, ViewTransactionOperations } from './components';

type OptionalTransactionKey = 'viewOperation' | 'stickyHeader';

const arrowProps = {
  width: '1rem',
};

const computeIcon = (transactionType: Transaction['type']) => {
  switch (transactionType) {
    case 'Disbursement':
      return <ArrowUpwardIcon sx={arrowProps} />;
    case 'Money Received':
      return <ArrowDownwardIcon sx={arrowProps} />;
    case 'Requested payment':
      return <AccessTimeIcon sx={arrowProps} />;
    case 'Expense':
      return <PaymentIcon sx={arrowProps} />;

    default:
      return null;
  }
};

const computeColor = (transactionType: Transaction['type']) => {
  switch (transactionType) {
    case 'Disbursement':
      return 'error.main';
    case 'Money Received':
      return 'success.main';
    case 'Expense':
      return 'warning.main';
    default:
      return 'black';
  }
};

export const computeOpacity = (isCanceled: boolean) => {
  return { opacity: isCanceled ? 0.6 : 1 };
};

export const renderCells =
  (currency: Currency) =>
  // eslint-disable-next-line react/display-name
  (key: keyof Transaction | OptionalTransactionKey, transaction: TransactionWithLinks) => {
    const transactionType = transaction.type;

    const renderKey = key + transaction.operationId;

    switch (key) {
      case 'viewOperation':
        return <ViewTransactionOperations key={renderKey} transaction={transaction} />;
      case 'principal':
      case 'interest':
      case 'penalty':
        return (
          <TableCell key={renderKey} sx={{ ...computeOpacity(transaction.isCanceled) }}>
            {transaction[key] ? formatNumber(transaction[key] ?? 0, currency) : '-'}
          </TableCell>
        );
      case 'type':
        return (
          <TableCell
            key={renderKey}
            sx={{
              color: computeColor(transactionType),
              display: 'flex',
              alignItems: 'center',
              gap: '.5rem',
              fontWeight: '500 !important',
              ...computeOpacity(transaction.isCanceled),
            }}
          >
            {computeIcon(transactionType)}
            {transaction[key] as string}
          </TableCell>
        );

      case 'amount':
        return (
          <TableCell
            key={renderKey}
            sx={{
              color: computeColor(transactionType),
              fontWeight: '500 !important',
              ...computeOpacity(transaction.isCanceled),
            }}
          >
            {formatNumber(transaction[key], currency)}
          </TableCell>
        );

      case 'user':
        return (
          <TableCell key={renderKey} sx={{ ...computeOpacity(transaction.isCanceled) }}>
            {transaction.user.email ?? '-'}
          </TableCell>
        );

      case 'stickyHeader':
        return <CancelTransaction key={renderKey} transaction={transaction} />;

      default:
        return (
          <TableCell key={renderKey} sx={{ ...computeOpacity(transaction.isCanceled) }}>
            {transaction[key as keyof typeof transaction] as string}
          </TableCell>
        );
    }
  };
