import { DetailedLoanWithLinks } from 'lms-types';

interface PaymentInformationTab {
  label: string;
  value: string;
  isVisibleFor(loan: DetailedLoanWithLinks): boolean;
}

export const revioCollectionsTab: PaymentInformationTab = {
  label: 'Revio Collections',
  value: 'revioCollections',
  isVisibleFor: (loan) => !!loan?._links?.getPurchases,
};
export const revioDisbursementsTab: PaymentInformationTab = {
  label: 'Revio Disbursements',
  value: 'revioDisbursements',
  isVisibleFor: (loan) => !!loan?._links?.getRevioDisbursements,
};
export const revioInformationTab: PaymentInformationTab = {
  label: 'Revio Information',
  value: 'revioInformation',
  isVisibleFor: (loan) => !!loan?._links?.getRevioCollectionGatewayLoan,
};

export const paymentInformationTabs: PaymentInformationTab[] = [
  revioCollectionsTab,
  revioInformationTab,
  revioDisbursementsTab,
];
