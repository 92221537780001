import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import globalReducer from './slices/globalSlice';
import customerAndLoanModalReducer from './slices/customerAndLoanModalSlice';
import revenuesReducer from './slices/revenuesSlice';
import timeMachineReducer from './slices/timeMachineSlice';

import registerRevenueModalReducer from 'components/modals/RegisterRevenueModal/store/registerRevenueModalSlice';
import approveDisbursementReducer from 'components/modals/ApproveDisbursementModal/store/approveDisbursementModalSlice';

import loansReducer from 'pages/Loans/store/loansSlice';
import detailedCustomerSliceReducer from 'pages/DetailedCustomer/store/detailedCustomerSlice';
import customersReducer from 'pages/Customers/store/customersSlice';
import detailedLoanReducer from 'pages/DetailedLoan/store/detailedLoanSlice';
import disbursementsReducer from 'pages/Disbursements/store/disbursementsSlice';
import dashboardReducer from 'pages/Dashboard/store/dashboardSlice';
import bankStatementsReducer from 'pages/BankStatements/store/bankStatementsSlice';
import detailedCompanyReducer from 'pages/DetailedCompany/store/detailedCompanySlice';
import refinementRulesReducer from 'pages/RefinementRules/store/refinementRulesSlice';

const appReducer = combineReducers({
  authSlice: authReducer,
  loansSlice: loansReducer,
  globalSlice: globalReducer,
  revenuesSlice: revenuesReducer,
  dashboardSlice: dashboardReducer,
  customersSlice: customersReducer,
  timeMachineSlice: timeMachineReducer,
  detailedLoanSlice: detailedLoanReducer,
  disbursementsSlice: disbursementsReducer,
  bankStatementsSlice: bankStatementsReducer,
  detailedCompanySlice: detailedCompanyReducer,
  refinementRulesSlice: refinementRulesReducer,
  detailedCustomerSlice: detailedCustomerSliceReducer,
  registerRevenueModalSlice: registerRevenueModalReducer,
  customerAndLoanModalSlice: customerAndLoanModalReducer,
  approveDisbursementModalSlice: approveDisbursementReducer,
});

export type RootState = ReturnType<typeof appReducer>;

export const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === 'logout/clear-state') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
