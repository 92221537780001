import { AppPageWrapper, SearchField, Typography } from 'components';
import { useState } from 'react';
import { useSelector } from 'hooks';
import { bankingStatementCompaniesSelector } from './store/bankStatementsSlice';
import { Card } from './components';
import { Wrapper } from './BankStatements.styles';
import { useNavigate } from 'react-router';
import { useBankingStatementsEffects } from './hooks/useBankingStatementsEffects.hooks';

export const BankStatments = () => {
  const companies = useSelector(bankingStatementCompaniesSelector);
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');

  useBankingStatementsEffects({ searchValue });

  const handleCardClick = (name: string) => () => {
    navigate(`/companies/${name}`);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <AppPageWrapper>
      <Typography sx={{ mb: '1rem' }} variant='h2'>
        Bank Statements
      </Typography>
      <SearchField value={searchValue} onChange={handleSearchChange} placeholder='Search' />
      <Wrapper>
        {companies.map(({ name }) => (
          <Card key={name} onClick={handleCardClick(name)} title={name} />
        ))}
      </Wrapper>
    </AppPageWrapper>
  );
};
