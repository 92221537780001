import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { DashboardResponse } from './dashboardSlice.types';
import { getDashboardData } from './dashboardSliceThunk';

type DashboardState = DashboardResponse & {
  isLoading: boolean;
};

const initialState: DashboardState = {
  isLoading: true,
  loanStats: {
    defaultProvision: 0,
    defaultReview: 0,
    late: 0,
    overdue: 0,
    waitingDisbursement: 0,
  },
};

const dashboardSlice = createSlice({
  initialState,
  name: 'dashboardSlice',
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getDashboardData.fulfilled, (state, action) => {
      state.loanStats = action.payload.loanStats;
      state.isLoading = false;
    });
    builder.addCase(getDashboardData.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default dashboardSlice.reducer;

const dashboardSliceSelector = (state: RootState) => state.dashboardSlice;

export const isDashboardLoadingSelector = createSelector(
  dashboardSliceSelector,
  (dashboard) => dashboard.isLoading,
);

export const loanStatsSelector = createSelector(
  dashboardSliceSelector,
  (dashboard) => dashboard.loanStats,
);
