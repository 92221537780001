import { styled } from 'theme';
import { TextField as DefaultTextField } from 'components/common/TextField/TextField';

export const TextField = styled(DefaultTextField)`
  .MuiOutlinedInput-input {
    padding: 7px !important;
    padding-left: 3px !important;
    font-weight: 400 !important;
  }

  .MuiOutlinedInput-root:hover {
    background-color: ${(props) => props.theme.palette.grey[950]};
  }

  svg {
    margin-left: 0.5rem;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;
