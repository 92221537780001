import {
  Navbar,
  CustomerAndLoanModal,
  RegisterRevenueModal,
  ApproveDisbursementModal,
} from 'components';
import { isApproveDisbursementModalOpenSelector } from 'components/modals/ApproveDisbursementModal/store/approveDisbursementModalSlice';
import { isRegisterRevenueModalOpenSelector } from 'components/modals/RegisterRevenueModal/store/registerRevenueModalSlice';
import { useDispatch, useSelector } from 'hooks';
import { Outlet } from 'react-router-dom';
import {
  closeCustomerAndLoanModal,
  isCustomerAndLoanModalOpenSelector,
  openCustomerAndLoanModa,
} from 'store/slices/customerAndLoanModalSlice';
import { LmsErrorModal } from '../../modals/LmsErrorModal';

export const AppLayout = () => {
  const dispatch = useDispatch();
  const isRegisterRevenueModalOpen = useSelector(isRegisterRevenueModalOpenSelector);
  const isCustomerAndLoanModalOpen = useSelector(isCustomerAndLoanModalOpenSelector);
  const isApproveDisbursementModalOpen = useSelector(isApproveDisbursementModalOpenSelector);

  const handleOpenCustomerAndLoanModal = () => {
    dispatch(openCustomerAndLoanModa());
  };

  const handleCloseCustomerAndLoanModal = () => {
    dispatch(closeCustomerAndLoanModal());
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Navbar onOpenModal={handleOpenCustomerAndLoanModal} />
      <div style={{ flexGrow: 1 }} id='content-root'>
        <Outlet />
      </div>
      {isCustomerAndLoanModalOpen ? (
        <CustomerAndLoanModal
          open={isCustomerAndLoanModalOpen}
          onClose={handleCloseCustomerAndLoanModal}
        />
      ) : null}
      {isRegisterRevenueModalOpen ? (
        <RegisterRevenueModal isOpen={isRegisterRevenueModalOpen} />
      ) : null}
      {isApproveDisbursementModalOpen ? (
        <ApproveDisbursementModal isOpen={isApproveDisbursementModalOpen} />
      ) : null}
      <LmsErrorModal />
    </div>
  );
};
