import { AppPageWrapper, Button, Table, Typography } from 'components';
import { useDispatch, useSelector } from 'hooks';
import {
  disbursementsSelector, selectedDisbursementsSelector,
  selectedDisbursementsTotalSumSelector,
} from './store/disbursementsSlice';
import useSortingParams from 'hooks/useSortingParams';
import { getAllDisbursements } from './store/disbursementsSliceThunk';
import { defaultTableHeaders, defaultMappedColumnIdsToNames } from './Disbursements.config';
import { renderCells } from './Disbursements.helpers';
import { Box } from '@mui/material';
import { formatNumber } from 'utils/formatNumber';
import {
  openApproveDisbursementModal
} from '../../components/modals/ApproveDisbursementModal/store/approveDisbursementModalSlice';

const emptyArr: Array<string> = [];

export const Disbursements = () => {
  const dispatch = useDispatch();
  const { disbursementRequests, metaData } = useSelector(disbursementsSelector);
  const totalSelected = useSelector(selectedDisbursementsTotalSumSelector);
  const selectedRequests = useSelector(selectedDisbursementsSelector)

  const fetchDisbursements = async (params: URLSearchParams) => {
    await dispatch(getAllDisbursements(params));
  };

  const handleApprove = () => {
    dispatch(openApproveDisbursementModal({ action: 'APPROVE_MANY', disbursements: selectedRequests }));
  };

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    handleIncludeRows,
    handleSort,
    includedColumns,
    searchParams,
    tableHeaders,
  } = useSortingParams(
    emptyArr,
    fetchDisbursements,
    defaultMappedColumnIdsToNames,
    defaultTableHeaders,
  );

  return (
    <AppPageWrapper>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h1'>Disbursements</Typography>
        {totalSelected > 0 ? (
          <Box display='flex' gap='1rem' alignItems='center'>
            <Typography variant='body1'>
              Selected amount to approve: <strong>{formatNumber(totalSelected, 'ZAR')}</strong>
            </Typography>
            <Button onClick={handleApprove}>Approve selected</Button>
          </Box>
        ) : null}
      </Box>
      <Table
        data={disbursementRequests}
        removableColumns={includedColumns}
        sortBy={searchParams.get('sortBy') ?? ''}
        orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
        onSort={handleSort}
        onSelect={handleIncludeRows}
        metaData={metaData}
        sortFields={emptyArr}
        tableHeaders={tableHeaders}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        renderCells={renderCells}
      />
    </AppPageWrapper>
  );
};
