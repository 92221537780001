import React from 'react';
import { AppPageWrapper, Typography } from 'components';

export const Repayments = () => {
  return (
    <AppPageWrapper>
      <Typography variant='h1'>Repayments</Typography>
    </AppPageWrapper>
  );
};
