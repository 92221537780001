import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { DetailedLoanWithLinks, ServerError } from 'lms-types';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import {
  AccruedInterestResponse,
  DetailedLoanActionLogResponse,
  DetailedLoanNotesResponse,
  DetailedLoanRevioPurchasesResponse,
  DetailedLoanTransactionsResponse,
  LoanScheduleResponse,
  RevioCollectionGatewayLoanDetailsResponse,
} from './detailedLoanSlice.types';

export const getDetailedLoan = createAsyncThunk<
  DetailedLoanWithLinks,
  string,
  {
    rejectValue: number | null;
  }
>('detailedLoanSlice/getDetailedLoan', async (identifier, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.get(
      `/loans/${identifier}`,
    )) as AxiosResponse<DetailedLoanWithLinks>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;

    dispatch(
      addNotification({
        severity: 'error',
        message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(serverError.response?.status ?? null);
  }
});

export const getDetailedLoanActionLogs = createAsyncThunk<
  DetailedLoanActionLogResponse,
  { loanId: string; params?: URLSearchParams },
  {
    rejectValue: null;
  }
>(
  'detailedLoanSlice/getDetailedLoanActionLogs',
  async ({ loanId, params = '' }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/loans/${loanId}/actionLogs?${params}`,
      )) as AxiosResponse<DetailedLoanActionLogResponse>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const getDetailedLoanTransactions = createAsyncThunk<
  DetailedLoanTransactionsResponse,
  { loanId: string; params?: URLSearchParams },
  {
    rejectValue: null;
  }
>(
  'detailedLoanSlice/getDetailedLoanTransactions',
  async ({ loanId, params = '' }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/loans/${loanId}/transactions?${params}`,
      )) as AxiosResponse<DetailedLoanTransactionsResponse>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const getDetailedLoanRevioPurchases = createAsyncThunk<
  DetailedLoanRevioPurchasesResponse,
  { loanId: string; params?: URLSearchParams },
  {
    rejectValue: null;
  }
>(
  'detailedLoanSlice/getDetailedLoanRevioPurchases',
  async ({ loanId }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/loans/${loanId}/purchases`,
      )) as AxiosResponse<DetailedLoanRevioPurchasesResponse>;
      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);
export const getDetailedLoanRevioInformation = createAsyncThunk<
  RevioCollectionGatewayLoanDetailsResponse,
  { loanId: string },
  {
    rejectValue: null;
  }
>(
  'detailedLoanSlice/getDetailedLoanRevioInformation',
  async ({ loanId }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/loans/${loanId}/reviopay`,
      )) as AxiosResponse<RevioCollectionGatewayLoanDetailsResponse>;
      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);
export const getDetailedLoanSchedule = createAsyncThunk<
  LoanScheduleResponse,
  { loanId: string; params?: URLSearchParams },
  {
    rejectValue: null;
  }
>(
  'detailedLoanSlice/getDetailedLoanSchedule',
  async ({ loanId }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/loans/${loanId}/schedule`,
      )) as AxiosResponse<LoanScheduleResponse>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const setLoanDefaultReviewStatus = createAsyncThunk<
  DetailedLoanWithLinks,
  { loanId: string; note: string },
  {
    rejectValue: null;
  }
>(
  'detailedLoanSlice/setLoanDefaultReviewStatus',
  async ({ loanId, note }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post(`/loans/${loanId}/setLoanDefaultReviewStatus`, {
        note,
      })) as AxiosResponse<DetailedLoanWithLinks>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const unsetLoanDefaultReviewStatus = createAsyncThunk<
  DetailedLoanWithLinks,
  { loanId: string; note: string },
  {
    rejectValue: null;
  }
>(
  'detailedLoanSlice/unsetLoanDefaultReviewStatus',
  async ({ loanId, note }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post(`/loans/${loanId}/unsetLoanDefaultReviewStatus`, {
        note,
      })) as AxiosResponse<DetailedLoanWithLinks>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const setLoanDefaultProvisionStatus = createAsyncThunk<DetailedLoanWithLinks, string>(
  'detailedLoanSlice/setDefaultProvisionStatus',
  async (loanId, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post(
        `/loans/${loanId}/transitToDefaultProvision`,
      )) as AxiosResponse<DetailedLoanWithLinks>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const writeOffLoan = createAsyncThunk<
  DetailedLoanWithLinks,
  { loanId: string; note: string },
  {
    rejectValue: null;
  }
>('detailedLoanSlice/writeOffLoan', async ({ loanId, note }, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.post(`/loans/${loanId}/writeOff`, {
      note,
    })) as AxiosResponse<DetailedLoanWithLinks>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});

export const handleCancelTransaction = createAsyncThunk<
  DetailedLoanTransactionsResponse,
  { loanId: string; operationId: string; reason: string; note: string | null },
  {
    rejectValue: null;
  }
>(
  'detailedLoanSlice/handleCancelTransaction',
  async ({ loanId, operationId, note, reason }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.delete(`/loans/${loanId}/transactions/${operationId}`, {
        data: {
          note,
          reason,
        },
      })) as AxiosResponse<DetailedLoanTransactionsResponse>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const getDetailedLoanAccruedInterest = createAsyncThunk<
  AccruedInterestResponse,
  { loanId: string; params?: URLSearchParams },
  {
    rejectValue: null;
  }
>(
  'detailedLoanSlice/getDetailedLoanAccruedInterest',
  async ({ loanId }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/loans/${loanId}/accruedInterest`,
      )) as AxiosResponse<AccruedInterestResponse>;
      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const getDetailedLoanNotes = createAsyncThunk<
  DetailedLoanNotesResponse,
  { loanId: string; params?: URLSearchParams },
  {
    rejectValue: null;
  }
>(
  'detailedLoanSlice/getDetailedLoanNotes',
  async ({ loanId, params }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/loans/${loanId}/notes${params ? '?' + params : ''}`,
      )) as AxiosResponse<DetailedLoanNotesResponse>;
      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const resetDetailedLoanRiskFlag = createAsyncThunk<
  DetailedLoanWithLinks,
  { loanId: string; note: string },
  {
    rejectValue: null;
  }
>(
  'detailedLoanSlice/resetDetailedLoanRiskFlag',
  async ({ loanId, note }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post(`/loans/${loanId}/resetRiskFlag`, {
        note,
      })) as AxiosResponse<DetailedLoanWithLinks>;
      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const cancelLoan = createAsyncThunk<
  DetailedLoanWithLinks,
  { loanId: string; note: string },
  {
    rejectValue: null;
  }
>('detailedLoanSlice/cancelLoan', async ({ loanId, note }, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.post(`/loans/${loanId}/cancel`, {
      note,
    })) as AxiosResponse<DetailedLoanWithLinks>;
    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});

export const getCustomerRelatedLoans = createAsyncThunk<
  Array<DetailedLoanWithLinks>,
  { companyIdentifier: string },
  {
    rejectValue: null;
  }
>(
  'detailedLoanSlice/getCustomerRelatedLoans',
  async ({ companyIdentifier }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/companies/${companyIdentifier}/loans?include=companyIdentifier&include=name&include=loanStatus&include=createdAt&limit=200`,
      )) as AxiosResponse<{ loans: Array<DetailedLoanWithLinks> }>;

      return response.data.loans;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);
