import { PopperOption } from 'components/organisms/PopperMenu/PopperMenu.types';

export const filterTitles = {
  type: 'Choose loan type',
  status: 'Choose loan status',
};

const typeOptions: Array<PopperOption> = [
  { id: 'RBF', name: 'RBF Loan', selected: false },
  { id: 'IBF', name: 'IBF Loan', selected: false },
  { id: 'IBF2', name: 'IBF2 Loan', selected: false },
];
const statusOptions: Array<PopperOption> = [
  { id: 'PENDING', name: 'Pending', selected: false },
  { id: 'APPROVED', name: 'Approved', selected: false },
  { id: 'ACTIVE', name: 'Active', selected: false },
  { id: 'LATE', name: 'Late' },
  { id: 'DEFAULT_REVIEW', name: 'Default review', selected: false },
  { id: 'RESTRUCTURED', name: 'Restructured', selected: false },
  { id: 'DEFAULT_PROVISION', name: 'Default provisioning', selected: false },
  { id: 'OVERDUE', name: 'Overdue', selected: false },
  { id: 'WRITTEN_OFF', name: 'Written off', selected: false },
  { id: 'PAID_OFF', name: 'Paid off', selected: false },
  { id: 'CANCELED', name: 'Canceled', selected: false },
];

export const defaultFilterOptions = {
  type: typeOptions,
  status: statusOptions,
};
