import {
  Table as MuiTable,
  TableContainer,
  TableRow,
  TableBody,
  TablePagination,
} from '@mui/material';
import TableHead from './TableHead';
import { TableProps } from './Table.types';
import { tableRowsOptions } from 'Constants';

export const Table = <T extends object>({
  data,
  orderBy,
  sortBy,
  removableColumns,
  tableHeaders,
  metaData,
  sortFields,
  maxHeight,
  headerCheck,
  disablePagination = false,
  hideheaderDropdown = false,
  onSort,
  onSelect,
  onChangePage,
  onHeaderCheckClick,
  onChangeRowsPerPage,
  renderCells,
}: TableProps<T>) => {
  const handleChangePage = (_: unknown, newPage: number) => {
    onChangePage?.(newPage);
  };

  const handleChangeRowsPerPage = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRowsPerPage?.(evt.target.value);
  };

  return (
    <>
      <TableContainer sx={{ maxHeight }}>
        <MuiTable stickyHeader={Boolean(maxHeight)}>
          <TableHead
            orderBy={orderBy}
            sortBy={sortBy}
            onSort={onSort}
            onSelect={onSelect}
            tableHeaders={tableHeaders}
            removableColumns={removableColumns}
            sortFields={sortFields}
            hideheaderDropdown={hideheaderDropdown}
            onHeaderCheckClick={onHeaderCheckClick}
            headerCheck={headerCheck}
          />
          <TableBody>
            {data.map((loan, idx) => (
              <TableRow key={idx}>
                {tableHeaders.map((header) => renderCells(header.id, loan, idx, data.length))}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {disablePagination ? null : (
        <TablePagination
          component='div'
          count={metaData.itemsCount}
          page={metaData.page - 1}
          rowsPerPage={metaData.limit}
          rowsPerPageOptions={tableRowsOptions}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      )}
    </>
  );
};
