import { styled } from 'theme';

export const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  padding-bottom: 0;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.light};

  & button {
    transform: translateY(-00.4rem);
  }
`;
