import { LoanPaymentInformationProps } from './PaymentInformation.types';
import { useEffect, useMemo, useState } from 'react';
import {
  paymentInformationTabs,
  revioCollectionsTab,
  revioDisbursementsTab,
  revioInformationTab,
} from './PaymentInformation.config';
import { RevioInformation, RevioPurchases, RevioDisbursements } from './components';
import { loanDetailsTab } from 'pages/DetailedLoan/DetailedLoan.config';
import { PageTabs } from 'components';

export const LoanPaymentInformation = ({ loan, navigate }: LoanPaymentInformationProps) => {
  const tabOptions = useMemo(() => {
    return paymentInformationTabs.filter((tab) => tab.isVisibleFor(loan));
  }, [loan]);

  const [tabsValue, setTabsValue] = useState(tabOptions.length ? tabOptions[0].value : '');

  useEffect(() => {
    if (tabOptions.findIndex((tab) => tab.value === tabsValue) === -1) {
      navigate(loanDetailsTab.value);
    }
  }, [tabOptions, tabsValue]);

  const handleTabsChange = (tab: string) => setTabsValue(tab);
  return tabOptions.length ? (
    <>
      <PageTabs
        variant='secondary'
        options={tabOptions}
        value={tabsValue}
        onChange={handleTabsChange}
      />
      {tabsValue === revioCollectionsTab.value ? <RevioPurchases loan={loan} /> : null}
      {tabsValue === revioInformationTab.value ? <RevioInformation loan={loan} /> : null}
      {tabsValue === revioDisbursementsTab.value ? <RevioDisbursements loan={loan} /> : null}
    </>
  ) : null;
};
