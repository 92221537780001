import { styled } from 'theme';
import { WrapperProps } from './LmsModal.types';

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height',
})<WrapperProps>`
  max-width: ${(props) => props.width};
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.theme.palette.common.white};
  height: 100%;
  max-height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Content = styled('form')`
  padding: 1.5rem;
  overflow-y: scroll;
  height: calc(100% - 10rem);
`;
