import { Typography } from 'components';
import { CheckboxProps } from './Checkbox.types';
import MuiCheckbox from '@mui/material/Checkbox';
import { Wrapper } from './Checkbox.styles';
import React, { forwardRef } from 'react';

export const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>(
  (
    { checked, defaultChecked, id, label, name = '', onChange, isDisabled = false, ...rest },
    ref,
  ) => {
    const handleClick = (evt: React.MouseEvent<HTMLDivElement>) => {
      if (isDisabled) return;
      const event = { ...evt, target: { ...evt.target, name, value: !checked } };
      onChange?.(event);
    };

    return (
      <Wrapper
        hasLabel={Boolean(label?.length)}
        id={id}
        onClick={handleClick}
        ref={ref}
        isDisabled={isDisabled}
        {...rest}
      >
        <MuiCheckbox
          checked={checked}
          defaultChecked={defaultChecked}
          id={id}
          disabled={isDisabled}
        />
        {label ? (
          <Typography variant='body2' color={isDisabled ? 'secondary' : undefined}>
            {label}
          </Typography>
        ) : null}
      </Wrapper>
    );
  },
);

Checkbox.displayName = 'Checkbox';
