import { AxiosInstance } from 'axios';

export class APIClient {
    axiosClient: AxiosInstance;
    constructor(axiosClient: AxiosInstance) {
        this.axiosClient = axiosClient;
    }

    async get(url: string, params?: Record<string, unknown>) {
        const response = await this.axiosClient.get(url, { params });
        return response.data;
    }
}
