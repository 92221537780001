import {
  SearchField,
  //  Dropdown
} from 'components';
import {
  // FiltersContainer
  Wrapper,
} from './Filters.styles';
import { useEffect, useRef, useState } from 'react';
import { Props } from './Filters.types';

export const Filters = ({ handleSearch }: Props) => {
  const [value, setValue] = useState('');
  const timerRef = useRef<NodeJS.Timeout>();

  const handleValueChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
  };

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      handleSearch(value);
    }, 700);

    return () => clearTimeout(timerRef.current);
  }, [value]);
  return (
    <Wrapper>
      <SearchField placeholder='Search' value={value} onChange={handleValueChange} />
      {/* <FiltersContainer>
        <Dropdown label='Company name' options={[]} />
        <Dropdown label='Country' options={[]} />
        <Dropdown label='Phone number' options={[]} />
        <Dropdown label='Date of incorporation' options={[]} />
        <Dropdown label='Loan officer' options={[]} />
      </FiltersContainer> */}
    </Wrapper>
  );
};
