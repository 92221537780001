import { styled } from 'theme';

export const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 1rem 0;
`;

export const DataGroup = styled('div')`
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.palette.grey[900]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
