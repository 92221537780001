import { AppPageWrapper, Typography, Table } from 'components';
import { Filters } from './components';
import { useDispatch, useSelector } from 'hooks';
import { getAllLoans } from './store/loansSlice.thunk';
import { loansSelector } from './store/loansSlice';
import {
  defaultMappedColumnIdsToNames,
  defaultTableHeaders,
  includableTableHeaders,
  includableMappedColumnIdsToNames,
  defaultQueryParams,
} from './Loans.config';
import useSortingParams from 'hooks/useSortingParams';
import { renderCells } from './Loans.helpers';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export const Loans = () => {
  const dispatch = useDispatch();
  const data = useSelector(loansSelector);

  const fetchLoans = (params: URLSearchParams) => {
    if (!searchParams.size) return;
    dispatch(getAllLoans(params));
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const {
    searchParams,
    tableHeaders,
    includedColumns,
    handleSort,
    handleSearch,
    handleChangePage,
    handleIncludeRows,
    handleChangeRowsPerPage,
  } = useSortingParams(
    data._embedded.includeValues ?? [],
    fetchLoans,
    defaultMappedColumnIdsToNames,
    defaultTableHeaders,
    includableMappedColumnIdsToNames,
    includableTableHeaders,
  );

  useEffect(() => {
    const queryColumns = searchParams.getAll('include');

    if (queryColumns.length) {
      setSearchParams(searchParams);
    } else {
      defaultQueryParams.forEach((param) => searchParams.append('include', param));
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <AppPageWrapper>
      <Typography variant='h1'>Loans</Typography>
      <Filters handleSearch={handleSearch} />
      <Table
        data={data.loans}
        removableColumns={includedColumns}
        sortBy={searchParams.get('sortBy') ?? ''}
        orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
        onSort={handleSort}
        onSelect={handleIncludeRows}
        metaData={data.metaData}
        sortFields={data._embedded.sortFields}
        tableHeaders={tableHeaders}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        renderCells={renderCells}
      />
    </AppPageWrapper>
  );
};
