import { MuiTextField } from './MuiTextField';
import { MuiSelect } from './MuiSelect';
import { MuiList, MuiMenuItem, MuiListItemIcon, MuiListSubheader } from './MuiMenu';
import { MuiButton } from './MuiButton';
import { MuiIconButton } from './MuiIconButton';
import { MuiCssBaseline } from './MuiCssBaseline';
import { MuiRadio } from './MuiRadio';
import { MuiTooltip } from './MuiTooltip';
import { MuiCheckbox } from './MuiCheckbox';
import { MuiTab, MuiTabs } from './MuiTabs';
import { MuiTableHead, MuiTableCell, MuiTable, MuiTableContainer } from './MuiTable';

const components = {
  MuiTableContainer,
  MuiTable,
  MuiTableCell,
  MuiTableHead,
  MuiIconButton,
  MuiTextField,
  MuiSelect,
  MuiList,
  MuiMenuItem,
  MuiButton,
  MuiRadio,
  MuiCssBaseline,
  MuiListItemIcon,
  MuiTooltip,
  MuiListSubheader,
  MuiCheckbox,
  MuiTabs,
  MuiTab,
};

export default components;
