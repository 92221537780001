import { Notification } from './Notification';
import { useSelector } from 'hooks';
import { globalNotificationsSelector } from 'store/slices/globalSlice';
import { Wrapper } from './NotificationContainer.styles';

const GlobalNotification = () => {
  const notifications = useSelector(globalNotificationsSelector);

  return (
    <Wrapper>
      {notifications.length > 0
        ? notifications.map((notification) => (
            <Notification key={notification.id} {...notification} />
          ))
        : null}
    </Wrapper>
  );
};

export default GlobalNotification;
