import { CThemeColors } from 'theme/theme';

const palette: CThemeColors = {
  primary: {
    light: '#1a5eff',
    main: '#0036B3',
    dark: '#00081A',
    contrastText: '',
  },
  secondary: {
    main: '#656E86',
    light: '#D3D6DE',
    dark: '#4f5669',
  },
  common: {
    white: '#fff',
    black: '#000',
  },
  background: {
    default: '#fff',
  },
  grey: {
    700: '#B3B3B3',
    900: '#E6E6E6',
    950: '#F2F2F2',
  },
  lightGrey: {
    main: '#AAAAAA',
    dark: '#4D4D4D',
  },
  darkBlue: {
    main: '#171D2C',
    light: '#232D43',
    dark: '#111722',
  },
  lightBlue: {
    main: '#646D85',
    light: '#424857',
    dark: '#4D4D4D',
  },
  error: {
    main: '#E70033',
  },
  success: {
    main: '#6FAE1E',
  },
  warning: {
    main: '#EDB700',
  },
};
export default palette;
