import { Props } from './LoanWithNotesModal.types';
import { LmsModal, TextField } from 'components';

export const LoanWithNotesModal = ({
  label,
  note,
  isLoading = false,
  isDisabled,
  onChangeNote,
  onClose,
  onSubmit,
}: Props) => {
  return (
    <LmsModal
      open
      headerProps={{
        onClose,
        title: 'Please add a note!',
      }}
      footerProps={{
        label: label,
        onClick: onSubmit,
        isDisabled,
        isLoading,
      }}
      wrapperProps={{ width: '25rem', height: '20rem' }}
    >
      <TextField
        fullWidth
        multiline
        minRows={4}
        name='note'
        placeholder='Please enter your note here ...'
        value={note}
        onChange={(e) => onChangeNote(e.target.value)}
      />
    </LmsModal>
  );
};
