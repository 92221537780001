export const includableTableHeaders = [
  { name: 'Principal', id: 'principalAmount' },
  { name: 'Monthly rate', id: 'monthlyRate' },
  { name: 'Daily rate', id: 'dailyRate' },
  { name: 'Interest', id: 'interestAmount' },
  { name: 'Loan officer', id: 'loanOfficerUserId' },
  { name: 'Currency', id: 'currency' },
  { name: 'RBF revenue share', id: 'rbfRevenueShare' },
  { name: 'Grace period', id: 'gracePeriodDays' },
  { name: 'Tenor', id: 'tenor' },
  { name: 'Penalty type', id: 'penaltyType' },
  { name: 'Penalty(%)', id: 'penaltyPercent' },
  { name: 'Payoff date', id: 'payoffDate' },
  { name: 'Approval date', id: 'loanApprovalDate' },
  { name: 'Times deliquent', id: 'timesDelinquent' },
  { name: 'Paid interest amount', id: 'paidInterestAmount' },
  { name: 'Paid penalties amount', id: 'paidPenaltiesAmount' },
  { name: 'Product version', id: 'productVersion' },
  { name: 'Total penalty amount', id: 'totalPenaltyAmount' },
  { name: 'Total outstanding amount', id: 'totalOutstandingAmount' },
  { name: 'Total offset amount', id: 'totalOffsetAmount' },
  { name: 'Delinquent amount', id: 'delinquentAmount' },
  { name: 'Waved amount', id: 'wavedAmount' },
  { name: 'Written off amount', id: 'writtenOffAmount' },
  { name: 'Created at', id: 'createdAt' },
  { name: 'Salesforce ID', id: 'salesforceOpportunityId' },
  { name: 'Paid principal amount', id: 'paidPrincipalAmount' },
  { name: 'Net payable principal', id: 'netPayablePrincipal' },
  { name: 'Net payable interest', id: 'netPayableInterest' },
  { name: 'Net payable penalties', id: 'netPayablePenalties' },
  { name: 'Current expected interest', id: 'currentExpectedInterest' },
  { name: 'Daily rate', id: 'dailyRate' },
  { name: 'Monthly rate', id: 'monthlyRate' },
  { name: 'Company identifier', id: 'companyIdentifier' },
  { name: 'Loan name', id: 'name' },
  { name: 'Loan type', id: 'loanType' },
  { name: 'Loan status', id: 'loanStatus' },
  { name: 'Total disbursed', id: 'totalDisbursedAmount' },
  { name: 'Origination Fee', id: 'originationFee' },
  { name: 'Net payable total', id: 'netPayableTotal' },
  { name: 'Disbursement date', id: 'disbursementDate' },
  { name: 'Maturity date', id: 'maturityDate' },
  { name: 'Repaid', id: 'paidTotalAmount' },
  { name: 'APR', id: 'annualRate' },
  { name: 'Repayment Type', id: 'repaymentType' },
  { name: 'Loan Rate', id: 'loanRate' },
  { name: 'Repayment Amount', id: 'repaymentAmount' },
  { name: 'Total Expenses Amount', id: 'totalExpensesAmount' },
  { name: 'Collection Method', id: 'collectionMethod' },
  { name: 'Disbursement Method', id: 'disbursementMethod' },
];

export const includableMappedColumnIdsToNames = includableTableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);

export const defaultTableHeaders = [
  { name: 'Loan id', id: 'loanId' },
  { name: 'Sticky', id: 'stickyHeader' },
];

export const defaultMappedColumnIdsToNames = defaultTableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);

export const defaultQueryParams = [
  'companyIdentifier',
  'name',
  'loanType',
  'loanStatus',
  'totalDisbursedAmount',
  'originationFee',
  'netPayableTotal',
  'disbursementDate',
  'maturityDate',
  'paidTotalAmount',
  'annualRate',
];
