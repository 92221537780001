export const includableTableHeaders = [
  { name: 'Incorporation date', id: 'incorporationDate' },
  { name: 'Company type', id: 'companyType' },
  { name: 'Industry', id: 'industry' },
  { name: 'Is partner', id: 'isPartner' },
  { name: 'Partner', id: 'partnerCompanyIdentifier' },
  { name: 'Email', id: 'email' },
  { name: 'Phone', id: 'phone' },
  { name: 'Website', id: 'website' },
  { name: 'Contact name', id: 'contactName' },
  { name: 'IBF credit limit', id: 'ibfCreditLimit' },
  { name: 'RBF credit limit', id: 'rbfCreditLimit' },
  { name: 'Credit score', id: 'creditScore' },
  { name: 'Next review date', id: 'nextReviewDate' },
  { name: 'Is compliance approved', id: 'isComplianceApproved' },
  { name: 'Bank name', id: 'bankName' },
  { name: 'IBAN', id: 'iban' },
  { name: 'SWIFT', id: 'swift' },
  { name: 'Updated by', id: 'updatedByUserId' },
  { name: 'Bank address', id: 'bankAddress' },
  { name: 'Created At', id: 'createdAt' },
  { name: 'Company source type', id: 'companySourceType' },
  { name: 'Next compliance review date', id: 'nextComplianceReviewDate' },
  { name: 'Loan officer', id: 'loanOfficerUserId' },
  { name: 'Incorporation country', id: 'incorporationCountry' },
  { name: 'General credit limit', id: 'creditLimit' },
  { name: 'Utilized credit', id: 'utilizedCredit' },
  { name: 'Updated at', id: 'updatedAt' },
  { name: 'Currency', id: 'currency' },
  { name: 'partnerCompany', id: 'partnerCompany' },
];

export const includableMappedColumnIdsToNames = includableTableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);

export const defaultTableHeaders = [
  { name: 'Company Legal Name', id: 'companyLegalName' },
  { name: 'Sticky', id: 'stickyHeader' },
];

export const defaultMappedColumnIdsToNames = defaultTableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);

export const defaultQueryParams = [
  'loanOfficerUserId',
  'incorporationCountry',
  'creditLimit',
  'utilizedCredit',
  'updatedAt',
];
