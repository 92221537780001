import { createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

type RegisterRevenueModal = {
  open: boolean;
};

const initialState: RegisterRevenueModal = {
  open: false,
};

const registerRevenueModalSlice = createSlice({
  name: 'registerRevenueModalSlice',
  initialState,
  reducers: {
    resetModalState() {
      return initialState;
    },
    openRevenueModal(state) {
      state.open = true;
    },
    closeRevenueModal(state) {
      state.open = false;
    },
  },
  extraReducers() {
    // no-op till implementation
  },
});

export const { resetModalState, closeRevenueModal, openRevenueModal } =
  registerRevenueModalSlice.actions;

export default registerRevenueModalSlice.reducer;

// Selectors:

const registerRevenueSliceSelector = (store: RootState) => store.registerRevenueModalSlice;

export const isRegisterRevenueModalOpenSelector = createSelector(
  registerRevenueSliceSelector,
  (state) => state.open,
);
