import { styled } from 'theme';

export const Wrapper = styled('div')`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const FiltersContainer = styled('div')`
  display: flex;
  gap: 0.5rem;
`;
