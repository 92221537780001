import { format } from 'date-fns';

export const initialFormData = {
  paymentDate: format(new Date(), 'yyyy-MM-dd'),
  priority: 'NORMAL',
};

export const priorityOpts = [
  { name: 'Urgent', value: 'URGENT' },
  { name: 'Normal', value: 'NORMAL' },
];
