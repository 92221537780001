import { useAuth } from 'hooks';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Wrapper, InnerWrapper } from './AuthLayout.styles';

export const AuthLayout = () => {
  const { checkUser } = useAuth();

  useEffect(() => {
    void checkUser();
  }, []);

  return (
    <Wrapper>
      <InnerWrapper>
        <Outlet />
      </InnerWrapper>
    </Wrapper>
  );
};
