import { DetailedLoanWithLinks } from 'lms-types';

export interface LoanTab {
  label: string;
  value: string;
  isVisibleFor(loan: DetailedLoanWithLinks): boolean;
}

export const loanNotesTab: LoanTab = { label: 'Notes', value: 'notes', isVisibleFor: () => true };
export const loanAccruedInterestTab: LoanTab = {
  label: 'Accrued interest',
  value: 'accruedInterest',
  isVisibleFor: () => true,
};
export const loanTransactionsTab: LoanTab = {
  label: 'Transactions',
  value: 'transactions',
  isVisibleFor: () => true,
};
export const loanDetailsTab: LoanTab = {
  label: 'Loan details',
  value: 'loanDetails',
  isVisibleFor: () => true,
};
export const loanHistoryTab: LoanTab = {
  label: 'History',
  value: 'actionLogs',
  isVisibleFor: () => true,
};
export const loanRevenuesTab: LoanTab = {
  label: 'Revenues',
  value: 'revenues',
  isVisibleFor: (loan) => loan.loanType === 'RBF' && loan.repaymentType === 'Monthly variable',
};
export const loanScheduleTab: LoanTab = {
  label: 'Loan schedule',
  value: 'loanSchedule',
  isVisibleFor: (loan) => !!loan?._links?.getLoanSchedule,
};
export const loanPaymentInformationTab: LoanTab = {
  label: 'Payment Information',
  value: 'paymentInformation',
  isVisibleFor: (loan) =>
    !!(
      loan?._links?.getPurchases ||
      loan?._links?.getRevioCollectionGatewayLoan ||
      loan?._links?.getRevioDisbursements
    ),
};

export const allLoanTabs = [
  loanDetailsTab,
  loanTransactionsTab,
  loanAccruedInterestTab,
  loanNotesTab,
  loanRevenuesTab,
  loanScheduleTab,
  loanPaymentInformationTab,
  loanHistoryTab,
];
