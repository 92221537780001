import { styled } from 'theme';

export const Form = styled('form')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  max-width: 380px;
  width: 100%;

  & button {
    margin-top: 0.5rem;
  }
`;
