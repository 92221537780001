import { styled } from 'theme';

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & button {
    margin-top: 0.5rem;
  }
`;
