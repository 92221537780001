import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  getRefinementRules,
  createRefinementRule,
  updateRefinementRule,
  deleteRefinementRule,
} from './refinementRulesThunk';
import { RefinementRule } from 'bst-types';

interface RefinementRulesState {
  isLoading: boolean;
  rules: Array<RefinementRule>;
  banks: Array<string>;
  rulesFilterCriteria: {
    bank: string;
  };
}

const initialState: RefinementRulesState = {
  isLoading: false,
  rules: [],
  banks: [],
  rulesFilterCriteria: {
    bank: '',
  },
};

const refinementRulesSlice = createSlice({
  name: 'refinementRulesSlice',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setBankFilter(state, action: PayloadAction<string>) {
      state.rulesFilterCriteria.bank = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(getRefinementRules.fulfilled, (state, action) => {
      state.rules = action.payload.rules;
      state.banks = action.payload.banks;
    });

    builder.addCase(createRefinementRule.fulfilled, (state, action) => {
      state.rules = action.payload.rules;
      state.banks = action.payload.banks;
    });

    builder.addCase(updateRefinementRule.fulfilled, (state, action) => {
      state.rules = action.payload.rules;
    });

    builder.addCase(deleteRefinementRule.fulfilled, (state, action) => {
      state.rules = action.payload.rules;
    });
  },
});

export const { setIsLoading, setBankFilter } = refinementRulesSlice.actions;

export default refinementRulesSlice.reducer;

// Selectors

const refinementRulesSliceSelector = (state: RootState) => state.refinementRulesSlice;

export const areRefinementRulesLoadingSelector = createSelector(
  refinementRulesSliceSelector,
  (state) => state.isLoading,
);

export const refinementRulesSelector = createSelector(
  refinementRulesSliceSelector,
  (state) => state.rules,
);

export const filteredRefinementRulesSelector = createSelector(
  refinementRulesSliceSelector,
  (state) => {
    const filteredBank = state.rulesFilterCriteria.bank;

    return state.rules.filter((rule) => rule.bank === filteredBank);
  },
);

export const refinementBanksSelector = createSelector(
  refinementRulesSliceSelector,
  (state) => state.banks,
);

export const refinementBanksAsOptionsSelector = createSelector(refinementBanksSelector, (state) =>
  state.map((bankName) => ({ name: bankName, id: bankName, value: bankName })),
);
