import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { ServerError } from 'lms-types';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import { RootState } from 'store';
import { AddNewDisbursementResponse } from './disbursementsModalSlice.types';
import { DisbursementFormData } from '../components/AddDisbursement/AddDisbursement.types';

export const addNewDisbursement = createAsyncThunk<
  AddNewDisbursementResponse,
  Omit<DisbursementFormData, 'note'> & { note: string | null },
  { rejectValue: null; state: RootState }
>(
  'disbursementsModalSlice/addDisbursement',
  async (data, { dispatch, rejectWithValue, getState }) => {
    try {
      const store = getState();

      const response = (await axios.post(
        `/loans/${store.detailedLoanSlice.loan?.loanId}/addDisbursement`,
        data,
      )) as AxiosResponse<AddNewDisbursementResponse>;

      dispatch(
        addNotification({
          severity: 'success',
          message: 'Transaction saved successfully',
        }),
      );

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const validateNewDisbursementField = createAsyncThunk<
  { [key: string]: string } | null,
  { [key: string]: string | number | boolean | null },
  {
    rejectValue: null;
  }
>(
  'disbursementsModalSlice/validateAddNewDisbursementField',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post('/loans/disbursementFormFields', body)) as AxiosResponse<{
        [key: string]: string;
      } | null>;

      return response.data;
    } catch (error) {
      dispatch(addNotification({ severity: 'error', message: 'something went wrong' }));
      return rejectWithValue(null);
    }
  },
);
