import { Form } from './NewPassword.styles';
import { TextField, Button, Typography } from 'components';
import { useAuth, useFormData } from 'hooks';
import React, { useMemo } from 'react';

const initialFormData = {
  password: '',
  confirmPassword: '',
};

export const NewPassword = () => {
  const { completeNewPassword } = useAuth();
  const { formData, handleChange } = useFormData(initialFormData);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    completeNewPassword(formData.password);
  };

  const isFormValid = useMemo(() => {
    return formData.password.length > 8 && formData.password === formData.confirmPassword;
  }, [formData.password, formData.confirmPassword]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Typography variant='h1'>Set a new password</Typography>
        <TextField
          label='Password'
          type='password'
          value={formData.password}
          onChange={handleChange}
          fullWidth
          name='password'
        />
        <TextField
          label='Confirm password'
          type='password'
          value={formData.confirmPassword}
          onChange={handleChange}
          fullWidth
          name='confirmPassword'
        />
        <Button type='submit' disabled={!isFormValid}>
          Confirm
        </Button>
      </Form>
    </>
  );
};
