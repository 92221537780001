import { Typography, Button, LmsModal, TextField } from 'components';
import { format } from 'date-fns';
import { useDispatch, useModalState, useSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { currentServerTimeSelector } from 'store/slices/timeMachineSlice';
import { getServerTime, setServerTime } from 'store/thunks/timeMachineThunk';

export const TimeMachine = () => {
  const dispatch = useDispatch();
  const currentServerTime = useSelector(currentServerTimeSelector);
  const [handleOpenModal, handleCloseModal, isModalOpen] = useModalState();
  const [newDate, setNewDate] = useState('');

  useEffect(() => {
    dispatch(getServerTime());
  }, []);

  const handleSubmit = async () => {
    const response = await dispatch(setServerTime({ newDate }));
    if (response.payload) {
      handleCloseModal();
    }
  };

  return (
    <>
      <Typography>
        <b>{format(new Date(currentServerTime), 'dd/MM/yyy | HH:mm')}</b>
      </Typography>
      <Button label='Set Time' onClick={handleOpenModal} />
      {isModalOpen ? (
        <LmsModal
          open={isModalOpen}
          headerProps={{ onClose: handleCloseModal, title: 'Set current server time' }}
          footerProps={{ label: 'Save time', onClick: handleSubmit }}
          wrapperProps={{ width: '25rem', height: '45vh' }}
        >
          <TextField
            fullWidth
            type='date'
            label='New Date'
            name='newDate'
            value={newDate}
            onChange={(e) => setNewDate(e.target.value)}
          />
        </LmsModal>
      ) : null}
    </>
  );
};
