export const tableHeaders = [
  { name: 'Transaction status', id: 'transactionType' },
  { name: 'Due date', id: 'dueDate' },
  { name: 'Paid date', id: 'paidDate' },
  { name: 'Principal repayment', id: 'principalPaid' },
  { name: 'Interest paid', id: 'interestPaid' },
  { name: 'Penalty paid', id: 'penaltyPaid' },
  { name: 'Outstanding Amount', id: 'instalmentTotalDueAmount' },
  { name: 'Total paid', id: 'totalPaid' },
  { name: 'Forecasted amount', id: 'totalForecasted' },
  { name: 'Expected amount', id: 'totalExpected' },
  { name: 'Principal due', id: 'principalDue' },
  { name: 'Interest due', id: 'interestDue' },
  { name: 'Penalties due', id: 'penaltiesDue' },
  { name: 'Total repayment due', id: 'totalRepaymentDue' },
];

export const mappedColumnIdsToNames = tableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);
