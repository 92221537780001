import { useMemo, useRef } from 'react';
import { useModalState, useSelector } from 'hooks';
import { Button, PopperMenu } from 'components';
import { DetailedLoanWithLinks } from 'lms-types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, NavigateLoansBtnsWrapper, Wrapper } from './Breadcrumbs.styles';
import { relatedLoansByCustomerSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

type Props = {
  loan: DetailedLoanWithLinks;
};

export const BreadcrumbsComponent = ({ loan }: Props) => {
  const navigate = useNavigate();
  const params = useParams<{ loanId: string }>();
  const { loansList: relatedLoans } = useSelector(relatedLoansByCustomerSelector);
  const [handleOpenModal, handleCloseModal, isModalOpen] = useModalState();
  const dropdownRef = useRef<HTMLAnchorElement>(null);

  const showNavigateBtns = useMemo(() => {
    return relatedLoans.length > 1;
  }, [relatedLoans]);

  const mappedRelatedLoans = useMemo(() => {
    return relatedLoans.map((relatedLoan) => ({
      id: relatedLoan.loanId,
      name: `${relatedLoan.loanId} - ${relatedLoan.name}`,
      selected: relatedLoan.loanId === params.loanId,
    }));
  }, [relatedLoans]);

  const handleOpenPopperMenu = () => {
    if (!showNavigateBtns) return;
    handleOpenModal();
  };

  const handleChangLoan = (operation: 'next' | 'prev') => () => {
    if (!showNavigateBtns) return;
    const selectedLoanIndex = relatedLoans.findIndex((loan) => loan.loanId === params.loanId);

    if (selectedLoanIndex < 0) return;

    const indexDifference = operation === 'next' ? +1 : -1;
    const newLoanIndex =
      (selectedLoanIndex + indexDifference + relatedLoans.length) % relatedLoans.length;

    navigate(`/loans/${relatedLoans[newLoanIndex].loanId}`);
  };

  const handleSelectLoan = (loanId: string) => {
    navigate(`/loans/${loanId}`);
    handleCloseModal();
  };

  return (
    <Wrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to='/'>Customers</Link>
          <Link to={`/customers/${loan.companyIdentifier}`}>{loan.companyIdentifier}</Link>
          <Link ref={dropdownRef} to={`/loans/${loan.loanId}`} onClick={handleOpenPopperMenu}>
            {loan.name}
          </Link>
        </Breadcrumbs>
        {showNavigateBtns ? (
          <ArrowDropDownOutlinedIcon
            onClick={handleOpenPopperMenu}
            sx={{ width: '1.5rem', height: '1.5rem', color: 'secondary.main', cursor: 'pointer' }}
          />
        ) : null}
      </div>
      {showNavigateBtns ? (
        <NavigateLoansBtnsWrapper>
          <Button onClick={handleChangLoan('prev')}>{'<'} Previous</Button>
          <Button onClick={handleChangLoan('next')}>Next {'>'}</Button>
        </NavigateLoansBtnsWrapper>
      ) : null}
      <PopperMenu
        open={isModalOpen}
        onClose={handleCloseModal}
        title={'Select Loan'}
        options={mappedRelatedLoans}
        anchorEl={dropdownRef.current}
        onSelect={handleSelectLoan}
      />
    </Wrapper>
  );
};
