import { styled } from 'theme';

export const Wrapper = styled('div')`
  padding: 1.2rem 1.8rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.3rem;
  z-index: 12;

  & > svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: #fff;
  }

  & > p {
    width: 100%;
  }

  &.error {
    background-color: ${(props) => props.theme.palette.error.main};
  }

  &.warning {
    border: 1.5px solid yellow;
    background-color: hsl(60, 100%, 80%);
  }

  &.success {
    background-color: #34a853;
  }
`;
