import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { ServerError } from 'lms-types';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import { RootState } from 'store';
import { RegisterExpensesFormData } from '../RegisterExpenses.config';
import { RegisterExpensesResponse } from './registerExpenses.types';

export const registerNewExpense = createAsyncThunk<
  RegisterExpensesResponse,
  RegisterExpensesFormData,
  { rejectValue: null; state: RootState }
>('registerExpensesSlice/registerNewExpense', async (data, { dispatch, rejectWithValue, getState }) => {
  try {
    const store = getState();

    const response = (await axios.post(
      `/loans/${store.detailedLoanSlice.loan?.loanId}/repayments`,
      { ...data, type: 'EXPENSE' },
    )) as AxiosResponse<RegisterExpensesResponse>;

    dispatch(
      addNotification({
        severity: 'success',
        message: 'Repayment saved successfully',
      }),
    );

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});
