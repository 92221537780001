import { styled } from '@mui/material';
import { WrapperProps } from './CreditScore.types';
import { CreditColorsMap } from './CreditScore.config';

export const Wrapper = styled('div')<WrapperProps>`
  display: flex;
  width: ${(props) => (props.size === 'small' ? '1.5rem' : '3rem')};
  height: ${(props) => (props.size === 'small' ? '1.5rem' : '3rem')};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.common.white};
  background-color: ${(props) => CreditColorsMap[props.score]};
`;
