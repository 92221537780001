import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { CustomerWithLinks } from 'lms-types';
import { RootState } from 'store';
import {
  getDetailedCustomer,
  getDetailedCustomersActionLogs,
  getDetailedCustomersLoans,
} from './detailedCustomerSliceThunk';
import { CustomerLoansResponse, CustomerLogsResponse } from './detailedCustomerSlice.types';
import { defaultMetaData } from 'Constants';

interface DetailedCustomerState {
  customer: CustomerWithLinks | null;
  customerLoans: CustomerLoansResponse | null;
  actionLogs: CustomerLogsResponse | null;
  isLoading: boolean;
  errorStatus: number | null;
}

const initialState: DetailedCustomerState = {
  customer: null,
  customerLoans: null,
  actionLogs: null,
  isLoading: true,
  errorStatus: null,
};

const detailedCustomerSlice = createSlice({
  name: 'DetailedCustomerSlice',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(getDetailedCustomer.fulfilled, (state, action) => {
      state.customer = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getDetailedCustomer.rejected, (state, action) => {
      state.isLoading = false;
      state.errorStatus = action.payload ?? null;
    });
    builder.addCase(getDetailedCustomersLoans.fulfilled, (state, action) => {
      state.customerLoans = action.payload;
    });
    builder.addCase(getDetailedCustomersActionLogs.fulfilled, (state, action) => {
      state.actionLogs = action.payload;
    });
  },
});

export const { setIsLoading } = detailedCustomerSlice.actions;

export default detailedCustomerSlice.reducer;

// Selectors

const detailedCustomerSliceSelector = (state: RootState) => state.detailedCustomerSlice;

export const isDetailedCustomerLoadingSelector = createSelector(
  detailedCustomerSliceSelector,
  (state) => state.isLoading,
);

export const detailedCustomerErrorStatusSelector = createSelector(
  detailedCustomerSliceSelector,
  (state) => state.errorStatus,
);

export const detailedCustomerSelector = createSelector(
  detailedCustomerSliceSelector,
  (state) => state.customer ?? ({} as CustomerWithLinks),
);

export const customerLoansSelector = createSelector(
  detailedCustomerSliceSelector,
  (state) =>
    state.customerLoans ??
    ({
      loans: [],
      metaData: defaultMetaData,
      _embedded: { includeValues: [], sortFields: [] },
      _links: {},
    } as CustomerLoansResponse),
);

export const customerActionLogsSelector = createSelector(
  detailedCustomerSliceSelector,
  (state) =>
    state.actionLogs ??
    ({
      actionLogs: [],
      metaData: defaultMetaData,
      _embedded: {
        excludeValues: [],
        sortFields: [],
      },
    } as CustomerLogsResponse),
);
