import { styled } from 'theme';

export const Wrapper = styled('div')`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.light};
`;

export const FlexContainer = styled('div')`
  cursor: pointer;
  & > div {
    display: flex;
    gap: 0.5rem;
  }

  display: flex;
  justify-content: space-between;
`;
