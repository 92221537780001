import { TableCell } from '@mui/material';
import { RevenueType } from 'lms-types';
import { formatNumber } from 'utils/formatNumber';

// eslint-disable-next-line react/display-name
export const renderCells = (key: keyof RevenueType, revenue: RevenueType) => {
  switch (key) {
    case 'amount':
      return <TableCell>{formatNumber(Number(revenue.amount), revenue.currency)}</TableCell>;
    default:
      return <TableCell key={key}>{revenue[key as keyof typeof revenue] as string}</TableCell>;
  }
};
