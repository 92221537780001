import React from 'react';
import { FlexContainer, ImageWrapper, Tabs } from '../../Navbar.styles';
import { Tab } from '@mui/material';
import { Props } from './BankStatementsTabs.types';
import logo from 'assets/images/flow48-logo.png';
import { Routes } from 'Constants';

export const BankStatementsTabs = ({ tabsValue, handleNavigate, handleTabChange }: Props) => {
  return (
    <FlexContainer>
      <ImageWrapper>
        <img src={logo} alt='Flow48 logo' />
      </ImageWrapper>
      <Tabs value={tabsValue} onChange={handleTabChange}>
        <Tab label='Bank statements' value='/' onClick={handleNavigate('/')} />
        <Tab
          label='Refinement rules'
          value={Routes.REFINEMENT_RULES}
          onClick={handleNavigate(Routes.REFINEMENT_RULES)}
        />
      </Tabs>
    </FlexContainer>
  );
};
