import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import { ServerError } from 'bst-types';
import { CompaniesResponse } from './bankStatementsSlice.types';

export const getAllCompanies = createAsyncThunk<
  CompaniesResponse,
  { searchParams?: URLSearchParams },
  {
    rejectValue: null;
  }
>('bankStatementsSlice/getCompanies', async ({ searchParams }, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.get(
      `/statements/companies?${searchParams}`,
    )) as AxiosResponse<CompaniesResponse>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});
