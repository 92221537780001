import { Loader, Table, Typography } from 'components';
import useSortingParams from 'hooks/useSortingParams';
import {
  tableHeaders as rawTableHeaders,
  mappedColumnIdsToNames,
} from './RevioDisbursements.config';
import { renderCells } from './RevioDisbursements.helpers';
import { DetailedLoanWithLinks } from 'lms-types';
import { lmsApiClient } from 'axios.config';
import { useEffect, useState } from 'react';
import { ViewRevioDisbursementsResponse } from 'integrations/lms-api/loan-payment-information-api';
import { defaultMetaData } from 'Constants';
type Props = {
  loan: DetailedLoanWithLinks;
};

const emptyArr: Array<string> = [];

export const RevioDisbursements = ({ loan }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [disbursements, setDisbursements] = useState<ViewRevioDisbursementsResponse[]>([]);

  const fetchRevioDisbursements = async () => {
    setIsLoading(true);
    try {
      const disbursements = await lmsApiClient.loanPaymentInformationApi.getRevioDisbursements(
        loan.loanId,
      );
      setDisbursements(disbursements.revioDisbursements);
    } finally {
      setIsLoading(false);
    }
  };

  const { tableHeaders, searchParams, handleChangePage, handleChangeRowsPerPage, handleSort } =
    useSortingParams(emptyArr, fetchRevioDisbursements, mappedColumnIdsToNames, rawTableHeaders);

  useEffect(() => {
    if (!loan._links.getPurchases) return;

    fetchRevioDisbursements();
  }, [loan.loanId]);

  return isLoading ? (
    <Loader />
  ) : disbursements.length ? (
    <>
      <Table
        hideheaderDropdown
        data={disbursements}
        disablePagination={true}
        metaData={defaultMetaData}
        tableHeaders={tableHeaders}
        sortBy={searchParams.get('sortBy') ?? ''}
        orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
        removableColumns={[]}
        sortFields={emptyArr}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onSort={handleSort}
        onSelect={() => null}
        renderCells={renderCells}
      />
    </>
  ) : (
    <Typography variant='h3'>No disbursements information is available.</Typography>
  );
};
