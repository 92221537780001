import { styled } from '@mui/material';
import { AvatarWrapperStyleProps } from './Avatar.types';

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'active',
})<AvatarWrapperStyleProps>`
  cursor: pointer !important;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? props.theme.palette.secondary.dark : props.theme.palette.secondary.main};
  width: ${(props) => (props.size === 'medium' ? '2rem' : '1.5rem')};
  height: ${(props) => (props.size === 'medium' ? '2rem' : '1.5rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  object-fit: contain;
  transition: all 0.2s;

  & > span {
    font-size: ${(props) => (props.size === 'medium' ? undefined : '10px')} !important;
  }
`;
