import { styled } from 'theme';

export const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;
export const FormContainer = styled('div')`
  display:grid;
  gap: 1rem;
`;
