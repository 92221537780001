import { useMemo, useEffect } from 'react';
import { Notification as NotificationType } from 'lms-types';
import { useTheme } from '@mui/material';
import { Close, ErrorOutline, WarningAmber, CheckCircleOutline } from '@mui/icons-material';
import { clsx } from 'clsx';
import { Wrapper } from './Notification.style';
import { Typography, IconButton } from 'components';
import { useDispatch } from 'hooks';
import { dropNotification } from 'store/slices/globalSlice';

const Notification = ({ message, severity, id }: NotificationType) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(dropNotification(id ?? ''));
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  const computedIcon = useMemo(() => {
    switch (severity) {
      case 'error':
        return <ErrorOutline color='error' />;
      case 'warning':
        return <WarningAmber color='warning' />;
      default:
        return <CheckCircleOutline color='success' />;
    }
  }, [severity]);

  const handleClose = () => {
    if (!id) return;
    dispatch(dropNotification(id));
  };

  return (
    <Wrapper
      className={clsx({
        ['error']: severity === 'error',
        ['warning']: severity === 'warning',
        ['success']: severity === 'success',
      })}
    >
      {computedIcon}
      <Typography
        color={theme.palette.common.white}
        variant='body2'
        fontSize={'1rem'}
        textAlign={'left'}
      >
        {message}
      </Typography>
      <IconButton onClick={handleClose}>
        <Close />
      </IconButton>
    </Wrapper>
  );
};

export default Notification;
