import { LoanDetailProps } from './LoanDetail.types';
import { LoanData } from './LoanDetail.styles';
import { Typography } from 'components';

export const LoanDetail = ({ title, message, color, children }: LoanDetailProps) => {
  return (
    <LoanData color={color}>
      <Typography color='secondary' variant='body1'>
        {title}
      </Typography>
      {children ? children : <Typography variant='h3'>{message}</Typography>}
    </LoanData>
  );
};
