import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { CustomerWithLinks, LoanWithLinks, SelectDataResponse, ServerError } from 'lms-types';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import {
  RatesPayload,
  RatesResponse,
} from 'components/modals/CustomerAndLoanModal/CustomerAndLoanModal.types';

type SelectOptionsCustomerResponse = {
  selectFields: SelectDataResponse;
  requiredFields: Array<string>;
};
type SelectOptionsLoanResponse = {
  selectFields: SelectDataResponse;
  requiredFields: { [key: string]: Array<string> };
};

export const getCustomerModalSelectOptions = createAsyncThunk<
  SelectOptionsCustomerResponse,
  undefined,
  {
    rejectValue: null;
  }
>(
  'customerAndLoanModalSlice/getCustomerModalSelectOptions',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      // const headers = getRequestHeaders(store);

      const response = (await axios.get(
        '/companies/additionFormFields',
      )) as AxiosResponse<SelectOptionsCustomerResponse>;

      return response.data;
    } catch (error) {
      // const serverError = error as AxiosError<ServerError>;
      dispatch(addNotification({ severity: 'error', message: 'something went wrong' }));
      return rejectWithValue(null);
    }
  },
);

export const validateCreateCustomerField = createAsyncThunk<
  { [key: string]: string } | null,
  { [key: string]: string | number | boolean | null },
  {
    rejectValue: null;
  }
>(
  'customerAndLoanModalSlice/validateCreateCustomerField',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post('/companies/additionFormFields', body)) as AxiosResponse<{
        [key: string]: string;
      } | null>;

      return response.data;
    } catch (error) {
      dispatch(addNotification({ severity: 'error', message: 'something went wrong' }));
      return rejectWithValue(null);
    }
  },
);

export const createCustomer = createAsyncThunk<
  CustomerWithLinks,
  { [key: string]: string | number | boolean | null },
  {
    rejectValue: null;
  }
>('customerAndLoanModalSlice/createCustomer', async (data, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.post('/companies', data)) as AxiosResponse<CustomerWithLinks>;
    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});

export const getLoanModalSelectOptions = createAsyncThunk<
  SelectOptionsLoanResponse,
  undefined,
  {
    rejectValue: null;
  }
>(
  'customerAndLoanModalSlice/getLoanModalSelectOptions',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        '/loans/additionFormFields',
      )) as AxiosResponse<SelectOptionsLoanResponse>;

      return response.data;
    } catch (error) {
      dispatch(addNotification({ severity: 'error', message: 'something went wrong' }));
      return rejectWithValue(null);
    }
  },
);

export const validateCreateLoanField = createAsyncThunk<
  { [key: string]: string } | null,
  { [key: string]: string | number | boolean | null },
  {
    rejectValue: null;
  }
>(
  'customerAndLoanModalSlice/validateCreateLoanField',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post('/loans/additionFormFields', body)) as AxiosResponse<{
        [key: string]: string;
      } | null>;

      return response.data;
    } catch (error) {
      dispatch(addNotification({ severity: 'error', message: 'something went wrong' }));
      return rejectWithValue(null);
    }
  },
);

export const createLoan = createAsyncThunk<
  LoanWithLinks,
  { [key: string]: string | number | boolean | null },
  {
    rejectValue: null;
  }
>('customerAndLoanModalSlice/createLoan', async (data, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.post('/loans', data)) as AxiosResponse<LoanWithLinks>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});

type InterestResponse = {
  interestAmount: string;
};

type IBFPrincipalResponse = {
  principalAmount: string;
};

export const getComputedInterest = createAsyncThunk<
  InterestResponse,
  { [key: string]: string | number | boolean | null },
  {
    rejectValue: null;
  }
>('customerAndLoanModalSlice/getComputedInterest', async (data, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.post(
      '/loans/additionFormFields/calculateInterest',
      data,
    )) as AxiosResponse<InterestResponse>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});

export const getComputedRates = createAsyncThunk<RatesResponse, RatesPayload>(
  'customerAndLoanModalSlice/getComputedRates',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post(
        '/loans/additionFormFields/calculateIBFRates',
        data,
      )) as AxiosResponse<RatesResponse>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const getComputedIBFPrincipal = createAsyncThunk<
  IBFPrincipalResponse,
  { [key: string]: string | number | boolean | null },
  {
    rejectValue: null;
  }
>(
  'customerAndLoanModalSlice/getComputedIBFPrincipal',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post(
        '/loans/additionFormFields/calculateIBFPrincipal',
        data,
      )) as AxiosResponse<IBFPrincipalResponse>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);
