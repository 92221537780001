import { Components } from '@mui/material';
import palette from 'theme/palette/palette';

export const MuiTableHead: Components['MuiTableHead'] = {
  styleOverrides: {
    root: {
      borderBottom: `2px solid ${palette.secondary.light}`,

      '& .MuiTableCell-root': {
        color: palette.secondary.main,
        fontWeight: '500 !important',
      },
    },
  },
};

export const MuiTableCell: Components['MuiTableCell'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      minWidth: 'max-content',
      fontSize: '0.85rem',
      fontWeight: '400 !important',
      color: palette.primary.dark,

      '& a': {
        '&:link, &:visited': {
          textDecoration: 'none',
          color: palette.primary.main,
        },
      },
    },
  },
};

export const MuiTable: Components['MuiTable'] = {
  styleOverrides: {
    root: {
      width: 'max-content',
      minWidth: '100%',
    },
  },
};

export const MuiTableContainer: Components['MuiTableContainer'] = {
  styleOverrides: {
    root: {},
  },
};
