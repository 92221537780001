import { LmsModal, Typography, TextField } from 'components';
import { Props } from './ApproveDisbursementModal.types';
import { useDispatch, useSelector } from 'hooks';
import {
  approveDisbursementModalDataSelector,
  closeApproveDisbursementModal,
} from './store/approveDisbursementModalSlice';
import { useMemo, useState } from 'react';
import {
  approveDisbursementRequest,
  approveDisbursementRequests,
  cancelDisbursementRequest,
} from './store/approveDisbursementModalThunk';

export const ApproveDisbursementModal = ({ isOpen }: Props) => {
  const dispatch = useDispatch();
  const { actionType, disbursement, disbursements } = useSelector(
    approveDisbursementModalDataSelector,
  );
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isCancel = useMemo(() => {
    return actionType === 'CANCEL';
  }, [actionType]);

  const isApproveMany = useMemo(() => {
    return actionType === 'APPROVE_MANY';
  }, [actionType]);

  const actionLabel = useMemo(() => {
    if (isCancel) return 'Cancel';
    if (isApproveMany) return 'Approve All';
    return 'Approve';
  }, [isCancel, isApproveMany]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (!isApproveMany && disbursement !== undefined) {
        const requestId = disbursement?.disbursementRequestId ?? '';
        if (isCancel) {
          await dispatch(cancelDisbursementRequest({ requestId }));
        } else {
          await dispatch(approveDisbursementRequest({ code, requestId }));
        }
      } else if (isApproveMany && disbursements !== undefined) {
        const requestIds = disbursements.map(
          (disbursement) => disbursement?.disbursementRequestId ?? '',
        );
        await dispatch(approveDisbursementRequests({ code, requestIds }));
      }

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    dispatch(closeApproveDisbursementModal());
  };

  const isFormValid = () => {
    if (isCancel) return true;
    else return code.length > 3;
  };

  const handleChangeTotp = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setCode(evt.target.value);
  };

  return (
    <LmsModal
      open={isOpen}
      headerProps={{
        onClose,
        title: `${actionLabel} disbursement request`,
      }}
      footerProps={{
        label: actionLabel,
        onClick: handleSubmit,
        isLoading,
        isDisabled: !isFormValid(),
      }}
      wrapperProps={{ width: '25rem', height: '45vh' }}
    >
      {isCancel ? (
        <Typography>Are you sure you want to cancel this disbursement request ?</Typography>
      ) : (
        <TextField label='Approval code' onChange={handleChangeTotp} fullWidth value={code} />
      )}
    </LmsModal>
  );
};
