import { Components } from '@mui/material';
import palette from 'theme/palette/palette';
import ReadexProPath from 'assets/fonts/ReadexPro-Regular.ttf';
import ReadexProBoldPath from 'assets/fonts/ReadexPro-Medium.ttf';
import IBMPlexSansRegularPath from 'assets/fonts/IBMPlexSans-Regular.ttf';
import IBMPlexSansMediumPath from 'assets/fonts/IBMPlexSans-Medium.ttf';
import IBMPlexSansSemiBoldPath from 'assets/fonts/IBMPlexSans-SemiBold.ttf';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: `

  

  ::-webkit-scrollbar {
    scrollbar-width: thin;
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: ${palette.secondary.light};
    border-radius: 8px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background:  ${palette.secondary.main};
  }

  @font-face {
    font-family: 'Readex Pro';
    font-weight: 400;
    font-style: normal;
    src: url(${ReadexProPath});
  }

  @font-face {
    font-family: 'Readex Pro';
    font-weight: 500;
    font-style: normal;
    src: url(${ReadexProBoldPath});
  }
  
  @font-face {
    font-family: 'IBM Plex Sans';
    font-weight: 400;
    font-style: normal;
    src: url(${IBMPlexSansRegularPath});
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-style: normal;
    src: url(${IBMPlexSansMediumPath});
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-weight: 600;
    font-style: normal;
    src: url(${IBMPlexSansSemiBoldPath});
  }
`,
};
