import { signOut } from 'aws-amplify/auth';
import axios, { AxiosRequestHeaders } from 'axios';
import axiosRetry from 'axios-retry';
import { HeaderKeys } from 'Constants';
import { LMSApiClient } from 'integrations/lms-api/lms-api-client';
import { baseAPIUrl } from 'lms.config';
import { authTokenSelector, dropUser } from 'store/slices/authSlice';

const axiosClient = axios.create({
  baseURL: baseAPIUrl,
});

axiosRetry(axiosClient, { retries: 3 });

axiosClient.interceptors.request.use(async (request) => {
  const { store } = await import('store/store');
  const state = store.getState();
  const token = authTokenSelector(state);

  const headers = {
    ...request.headers,
    [HeaderKeys.Authorization]: `Bearer ${token}`,
    [HeaderKeys.Accept]: '*/*',
  } as AxiosRequestHeaders;

  request.headers = headers;

  return request;
});

axiosClient.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      await signOut();
      const { store } = await import('store/store');
      store.dispatch(dropUser());
      store.dispatch({ type: 'logout/clear-state' });
      window.location.assign('/');
    } else {
      return Promise.reject(error);
    }
  },
);

export default axiosClient;

export const lmsApiClient = new LMSApiClient(axiosClient);
