import React from 'react';
import { PageWrapperProps } from './AppPageWrapper.types';
import { Wrapper } from './AppPageWrapper.styles';
import { useSelector } from 'hooks';
import { isSidebarOpenSelector } from 'store/slices/globalSlice';

export const AppPageWrapper = ({ children, hasSidebar = false }: PageWrapperProps) => {
  const isSidebarOpen = useSelector(isSidebarOpenSelector);

  return (
    <Wrapper hasSidebar={hasSidebar} isSidebarOpen={isSidebarOpen}>
      {children}
    </Wrapper>
  );
};
