import { styled } from 'theme';
import { DropdownWrapperProps } from './Dropdown.types';

export const Wrapper = styled('div')<DropdownWrapperProps>`
  padding: 0.35rem 0.75rem;
  background-color: ${(props) =>
    props.isActive ? props.theme.palette.secondary.main : props.theme.palette.grey[950]};
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  cursor: pointer;

  & p,
  & svg {
    color: ${(props) =>
      props.isActive ? props.theme.palette.common.white : props.theme.palette.secondary.main};
  }

  &:hover {
    background-color: ${(props) =>
      props.isActive ? props.theme.palette.secondary.main : props.theme.palette.grey[900]};
  }
`;
