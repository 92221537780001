import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { DisbursementsResponse } from './disbursementsSliceTypes';
import { getAllDisbursements } from './disbursementsSliceThunk';
import {
  approveDisbursementRequest,
  approveDisbursementRequests,
  cancelDisbursementRequest,
} from 'components/modals/ApproveDisbursementModal/store/approveDisbursementModalThunk';
import { DisbursementWithLinks } from 'lms-types';
import { ApproveDisbursementResults } from 'components/modals/ApproveDisbursementModal/ApproveDisbursementModal.types';
import { DisbursementRequestLmsError } from '../../../components/modals/LmsErrorModal/LmsErrorModal.types';

type DisbursementsState = DisbursementsResponse & {
  isLoading: boolean;
  selectedDisbursementRequests: Array<string>;
};

const initialState: DisbursementsState = {
  isLoading: true,
  disbursementRequests: [],
  selectedDisbursementRequests: [],
  metaData: {
    itemsCount: 0,
    limit: 0,
    page: 1,
  },
};

const disbursementsSlice = createSlice({
  initialState,
  name: 'disbursementsSlice',
  reducers: {
    setDisbursementError(
      state,
      { payload }: PayloadAction<DisbursementRequestLmsError | undefined>,
    ) {
      if (!payload) return;
      state.disbursementRequests = state.disbursementRequests.map((disbursement) =>
        disbursement.disbursementRequestId === payload.disbursementRequestId
          ? {
              ...disbursement,
              isSuccess: false,
              error: payload.error,
              displayError: payload.displayError,
            }
          : disbursement,
      );
    },
    updateDisbursementList(state, { payload }: PayloadAction<DisbursementWithLinks>) {
      const { disbursementRequestId } = payload;
      state.disbursementRequests = state.disbursementRequests.map((disbursement) =>
        disbursement.disbursementRequestId === disbursementRequestId ? payload : disbursement,
      );
    },
    updateDisbursementListFromDisbursements(
      state,
      { payload }: PayloadAction<ApproveDisbursementResults>,
    ) {
      payload.disbursementRequestResults.forEach((disbursementRequestResult) => {
        state.disbursementRequests = state.disbursementRequests.map((disbursement) =>
          disbursement.disbursementRequestId ===
          disbursementRequestResult.disbursementRequest.disbursementRequestId
            ? {
                ...disbursementRequestResult.disbursementRequest,
                isSuccess: disbursementRequestResult.isSuccess,
                error: disbursementRequestResult.error,
                displayError: disbursementRequestResult.displayError,
              }
            : disbursement,
        );
      });
    },
    selectDisbursement(state, action: PayloadAction<string>) {
      state.selectedDisbursementRequests = [...state.selectedDisbursementRequests, action.payload];
    },
    removeDisbursement(state, action: PayloadAction<string>) {
      state.selectedDisbursementRequests = state.selectedDisbursementRequests.filter(
        (selectedDisbId) => selectedDisbId !== action.payload,
      );
    },
  },

  extraReducers(builder) {
    const {
      updateDisbursementList,
      updateDisbursementListFromDisbursements,
      setDisbursementError,
    } = disbursementsSlice.caseReducers;

    builder.addCase(getAllDisbursements.fulfilled, (state, action) => {
      const {
        payload: { disbursementRequests, metaData },
      } = action;
      state.disbursementRequests = disbursementRequests;
      state.metaData = metaData;
      state.isLoading = false;
    });
    builder.addCase(approveDisbursementRequest.fulfilled, (state, action) => {
      updateDisbursementList(state, action);
    });
    builder.addCase(
      approveDisbursementRequest.rejected,
      (state, action: PayloadAction<DisbursementRequestLmsError | undefined>) => {
        setDisbursementError(state, action);
      },
    );
    builder.addCase(approveDisbursementRequests.fulfilled, (state, action) => {
      updateDisbursementListFromDisbursements(state, action);
    });
    builder.addCase(cancelDisbursementRequest.fulfilled, (state, action) => {
      updateDisbursementList(state, action);
    });
  },
});

export const { removeDisbursement, selectDisbursement } = disbursementsSlice.actions;

export default disbursementsSlice.reducer;

const disbursementsSliceSelector = (state: RootState) => state.disbursementsSlice;

export const disbursementsSelector = createSelector(
  disbursementsSliceSelector,
  (disbursements) => disbursements,
);

export const selectedDisbursementRequestIdsSelector = createSelector(
  disbursementsSliceSelector,
  (state) => state.selectedDisbursementRequests,
);

export const selectedDisbursementsSelector = createSelector(
  disbursementsSliceSelector,
  ({ selectedDisbursementRequests: selectedDIsbursementRequestIds, disbursementRequests }) => {
    return disbursementRequests.filter((disbReq) =>
      selectedDIsbursementRequestIds.includes(disbReq.disbursementRequestId),
    );
  },
);

export const selectedDisbursementsTotalSumSelector = createSelector(
  selectedDisbursementsSelector,
  (selectedDisbursementRequests) => {
    return selectedDisbursementRequests.reduce(
      // TODO: clarify if amount will be left as string or BE will respond with number.
      (amount, currDisb) => amount + parseFloat(currDisb.amount as unknown as string),
      0,
    );
  },
);
