import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  getCustomerModalSelectOptions,
  getLoanModalSelectOptions,
} from '../thunks/customerAndLoanModalThunk';
import { OptionType } from 'components/common/Select/Select.types';
import { FormDataType as LoanFormData } from 'components/modals/CustomerAndLoanModal/components/CreateLoan/CreateLoan.config';
import { Currency } from 'lms-types';

export type OptionTypeWithOfficer = OptionType & {
  loan_officer_id?: string;
  currency?: Currency;
};

type CustomerAndLoanModalInitialState = {
  selectOptions: {
    [key: string]: Array<OptionTypeWithOfficer>;
  } | null;
  loanTenor: { [key: string]: Array<OptionType> };
  repaymentType: { [key: string]: Array<OptionType> };
  rbfInterestAllocation: Array<OptionType>;
  requiredFields: { [key: string]: Array<string> };
  initialLoanFormData: Partial<LoanFormData> | null;
  isModalOpen: boolean;
};

const initialState: CustomerAndLoanModalInitialState = {
  selectOptions: null,
  requiredFields: {},
  repaymentType: {},
  loanTenor: {},
  rbfInterestAllocation: [],
  initialLoanFormData: null,
  isModalOpen: false,
};

const customerAndLoanModalSlice = createSlice({
  name: 'customerAndLoanModalSlice',
  initialState,
  reducers: {
    setInitialCreateLoanState(state, action: PayloadAction<Partial<LoanFormData>>) {
      state.initialLoanFormData = action.payload;
    },
    openCustomerAndLoanModa(state) {
      state.isModalOpen = true;
    },

    closeCustomerAndLoanModal(state) {
      state.isModalOpen = false;
    },
    resetModalState() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getCustomerModalSelectOptions.fulfilled, (state, action) => {
      state.selectOptions = action.payload.selectFields;
      state.requiredFields.customer = action.payload.requiredFields;
    });

    builder.addCase(getLoanModalSelectOptions.fulfilled, (state, action) => {
      const { interestAllocationType, ...restSelectFields } = action.payload.selectFields;

      state.loanTenor = action.payload.selectFields.tenor;
      state.repaymentType = action.payload.selectFields.repaymentType;
      state.rbfInterestAllocation = interestAllocationType.RBF;
      state.selectOptions = restSelectFields;

      state.requiredFields = action.payload.requiredFields;
    });
  },
});

export const {
  resetModalState,
  closeCustomerAndLoanModal,
  openCustomerAndLoanModa,
  setInitialCreateLoanState,
} = customerAndLoanModalSlice.actions;

export default customerAndLoanModalSlice.reducer;

// Selectors:

const customerAndLoanModalSelector = (store: RootState) => store.customerAndLoanModalSlice;

export const modalPicklistOptionsSelector = createSelector(
  customerAndLoanModalSelector,
  ({ selectOptions }) => selectOptions,
);

export const loanTenorSelector = createSelector(
  customerAndLoanModalSelector,
  (modalState) => modalState.loanTenor,
);

export const rbfInterestAllocationSelector = createSelector(
  customerAndLoanModalSelector,
  ({ rbfInterestAllocation }) => rbfInterestAllocation,
);

export const repaymentTypeSelector = createSelector(
  customerAndLoanModalSelector,
  ({ repaymentType }) => repaymentType,
);

export const requiredFieldsSelector = createSelector(
  customerAndLoanModalSelector,
  (modalState) => modalState.requiredFields,
);

export const loanModalInitialFormDataSelector = createSelector(
  customerAndLoanModalSelector,
  (modalState) => modalState.initialLoanFormData,
);

export const isCustomerAndLoanModalOpenSelector = createSelector(
  customerAndLoanModalSelector,
  (modalState) => modalState.isModalOpen,
);
