import { Wrapper } from './CreditScore.styles';
import { Props } from './CreditScore.types';
import { Typography } from 'components';
import { forwardRef } from 'react';

export const CreditScore = forwardRef<HTMLDivElement, Props>(({ score, size = 'small' }, ref) => {
  return (
    <Wrapper score={score} ref={ref} size={size}>
      <Typography color='white' fontSize={size === 'small' ? '.85rem' : '1.4rem'} variant='body2'>
        {score}
      </Typography>
    </Wrapper>
  );
});

CreditScore.displayName = 'CreditScore';
