
export const tableHeaders = [
  { name: 'Token ID', id: 'recurringTokenId' },
  { name: 'Token Name', id: 'name' },
  { name: 'Service type', id: 'serviceType' },
  { name: 'Status', id: 'status' },
  { name: 'First name', id: 'firstName' },
  { name: 'Last name', id: 'lastName' },
  { name: 'Phone', id: 'phoneNumber' },
  { name: 'ID number', id: 'idNumber' },
  { name: 'ID type', id: 'idType' },
  { name: 'Account number', id: 'accountNumber' },
  { name: 'Account type', id: 'accountType' },
  { name: 'Branch', id: 'branchCode' },
];

export const mappedColumnIdsToNames = tableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);
