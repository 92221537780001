import { Table } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { tableHeaders as rawTableHeaders, mappedColumnIdsToNames } from './History.config';
import useSortingParams from 'hooks/useSortingParams';
import { useParams } from 'react-router-dom';
import { renderCells } from './History.helpers';
import { detailedLoanActionLogsSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';
import { getDetailedLoanActionLogs } from 'pages/DetailedLoan/store/detailedLoanThunk';

export const History = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const actionLogs = useSelector(detailedLoanActionLogsSelector);

  const fetchActionLogs = (searchParams: URLSearchParams) => {
    dispatch(
      getDetailedLoanActionLogs({
        loanId: params?.loanId ?? '',
        params: searchParams,
      }),
    );
  };

  const { tableHeaders, searchParams, handleChangePage, handleChangeRowsPerPage, handleSort } =
    useSortingParams(
      actionLogs._embedded.excludeValues,
      fetchActionLogs,
      mappedColumnIdsToNames,
      rawTableHeaders,
    );

  return (
    <div>
      <Table
        data={actionLogs.actionLogs}
        metaData={actionLogs.metaData}
        tableHeaders={tableHeaders}
        sortBy={searchParams.get('sortBy') ?? ''}
        orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
        removableColumns={[]}
        sortFields={actionLogs._embedded.sortFields}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onSort={handleSort}
        onSelect={() => null}
        renderCells={renderCells}
      />
    </div>
  );
};
