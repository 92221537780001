import { PopperOption } from 'components/organisms/PopperMenu/PopperMenu.types';
import { QueryFilterNames } from './Filters.types';
import { TransactionClassification } from 'Constants';

export const filterTitles = {
  amount: 'Choose amount range',
  balanceAmount: 'Choose balance amount range',
  bank: 'Choose bank',
  currency: 'Choose currency',
  transactionDate: 'Choose transaction date range',
  classification: 'Choose classification',
};

export const defaultRangeFilterData = {
  amount: { from: '', to: '' },
  balanceAmount: { from: '', to: '' },
  transactionDate: { from: '', to: '' },
};

const popperOptionArray = [] as PopperOption[];

export const classificationOpts = [
  ...TransactionClassification.map((classification) => ({
    name: classification,
    id: classification,
  })),
  { name: 'Unclassified', id: 'none' },
];

export const defaultFilterOptions = {
  bank: popperOptionArray,
  currency: popperOptionArray,
  classification: classificationOpts,
};

export const nameToQueryParam: { [key: string]: QueryFilterNames } = {
  amount: 'amount',
  balanceAmount: 'balance_amount',
  bank: 'bank',
  currency: 'currency',
  transactionDate: 'transaction_date',
  classification: 'classification',
};

export const rangeDateFilters = ['transactionDate'];

export const rangeFilters = ['amount', 'balanceAmount', ...rangeDateFilters];
