import { appDateNames } from 'Constants';
import React, { useState, useCallback } from 'react';
import { getServerFormatDate } from 'utils/getServerFormatDate';

export type EventType =
  | (Partial<Event> & { target: { name: string | number; value: string | number | boolean } })
  | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export type FieldIssueSeverity = 'error' | 'warning';
export class FieldIssue {
  severity: FieldIssueSeverity;
  message: string;

  constructor(severity: FieldIssueSeverity, message: string) {
    this.severity = severity;
    this.message = message;
  }
  get isError() {
    return this.severity === 'error';
  }
  get isWarning() {
    return this.severity === 'warning';
  }
}

export const useFormData = <T>(initialData: T) => {
  const [formData, setFormData] = useState(initialData);
  const [fieldHelpers, setFieldHelpers] = useState<Partial<T>>({});
  const [fieldIssues, setFieldIssues] = useState<{ [K in keyof T]?: FieldIssue }>({});

  const handleChangeFormData = useCallback((evt: EventType) => {
    setFormData((formData) => {
      const { name, value } = evt.target;

      const isDate =
        appDateNames.some((dateName) => (name as string).includes(dateName)) && Boolean(value);

      return { ...formData, [name]: isDate ? getServerFormatDate(value as string) : value };
    });
  }, []);

  const setFieldError = useCallback((field: keyof T, value?: string) => {
    if (value) {
      setFieldHelpers((fieldHelpers) => ({ ...fieldHelpers, [field]: value }));
      setFieldIssues((fieldIssues) => ({ ...fieldIssues, [field]: new FieldIssue('error', value) }));
    } else {
      setFieldHelpers((fieldHelpers) => {
        const updatedFieldHelpers = { ...fieldHelpers };

        delete updatedFieldHelpers[field];

        return updatedFieldHelpers;
      });
      clearFieldIssues(field);
    }
  }, []);
  const setFieldWarning = useCallback((field: keyof T, value: string) => {
    setFieldIssues((fieldIssues) => ({ ...fieldIssues, [field]: new FieldIssue('warning', value) }));
  }, []);
  const clearFieldIssues = useCallback((field: keyof T) => {
    setFieldIssues((fieldIssues) => {
      const updatedFieldIssues = { ...fieldIssues };

      delete updatedFieldIssues[field];

      return updatedFieldIssues;
    })
  }, []);
  return { formData, handleChange: handleChangeFormData, fieldHelpers, setFieldError, setFieldWarning, fieldIssues, clearFieldIssues };
};
