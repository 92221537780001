export const defaultTableHeaders = [
  { name: 'Reference', id: 'reference' },
  { name: 'Status', id: 'status' },
  { name: 'Amount', id: 'amount' },
  { name: 'Paid On', id: 'paidOn' },
  { name: 'Purchase Id', id: 'id' },
  { name: 'Sticky', id: 'stickyHeader' },
];
export const includableTableHeaders = [
  { name: 'Created date', id: 'createdOn' },
  { name: 'Updated date', id: 'updatedOn' },
  { name: 'Reference Generated', id: 'referenceGenerated' },
  { name: 'Net Amount', id: 'netAmount' },
  { name: 'Fee Amount', id: 'feeAmount' },
]
export const includableMappedColumnIdsToNames = includableTableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
)
export const defaultMappedColumnIdsToNames = defaultTableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);

export const includableTableHeaderIds = includableTableHeaders.map(
  (header) => header.id,
);
