import React from 'react';
import { Typography, TextField, Select } from 'components';
import { FormDataType } from './CreateLoan.config';
import { Grid } from '../../CustomerAndLoanModal.styles';
import { useDispatch, useSelector } from 'hooks';
import {
  modalPicklistOptionsSelector,
  loanTenorSelector,
  rbfInterestAllocationSelector,
  repaymentTypeSelector,
} from 'store/slices/customerAndLoanModalSlice';
import { defaultSelectOption } from 'Constants';
import { EventType } from 'hooks/useFormData';
import { FieldDescription, RatesPayload, RatesResponse } from '../../CustomerAndLoanModal.types';
import { getComputedRates } from 'store/thunks/customerAndLoanModalThunk';

type Props = {
  title: string;
  fields: Array<FieldDescription<FormDataType>>;
  formData: FormDataType;
  fieldHelpers: Partial<FormDataType>;
  handleChange(evt: EventType): void;
  handleValidate(field: keyof FormDataType): () => void;
  removeValidationError(field: keyof FormDataType): () => void;
};

export const LoanFormSection = ({
  title,
  fields,
  formData,
  fieldHelpers,
  handleChange,
  handleValidate,
  removeValidationError,
}: Props) => {
  const dispatch = useDispatch();
  const picklistOptions = useSelector(modalPicklistOptionsSelector);
  const { IBF: ibfTenor, RBF: rbfTenor } = useSelector(loanTenorSelector);
  const { IBF: ibfRepaymentType, RBF: rbfRepaymentType } = useSelector(repaymentTypeSelector);
  const rbfInterestAllocation = useSelector(rbfInterestAllocationSelector);

  return (
    <React.Fragment>
      <Typography variant='h2' sx={{ mb: '1.5rem', mt: '1rem' }}>
        {title}
      </Typography>
      <Grid>
        {fields.map(({ label, name, type, inputType }) => {
          const selectOptions = picklistOptions?.[name] ?? defaultSelectOption;

          const isRbf = formData.loanType === 'RBF';
          const hideDefaultOption = name !== 'penaltyType' && name !== 'loanType';

          if (name === 'interestAllocationType') {
            return isRbf ? (
              <Select
                label={'Interest Allocation'}
                name={name}
                onChange={handleChange}
                value={formData[name]}
                error={fieldHelpers[name] ? Boolean(fieldHelpers[name]) : undefined}
                helperText={fieldHelpers[name] as unknown as string}
                onBlur={handleValidate(name)}
                onFocus={removeValidationError(name)}
                key={name}
                options={rbfInterestAllocation}
                showDefaultOption={false}
              />
            ) : null;
          }

          if (name === 'tenor') {
            return isRbf ? (
              <Select
                label={'Tenor (months)'}
                name={name}
                onChange={handleChange}
                value={formData[name]}
                error={fieldHelpers[name] ? Boolean(fieldHelpers[name]) : undefined}
                helperText={fieldHelpers[name] as unknown as string}
                onBlur={handleValidate(name)}
                onFocus={removeValidationError(name)}
                key={name}
                options={isRbf ? rbfTenor : ibfTenor}
                showDefaultOption={false}
              />
            ) : (
              <TextField
                label={label}
                name={name}
                disabled={Boolean(formData.firstInstalmentDate)}
                onChange={handleChange}
                value={formData[name]}
                error={fieldHelpers[name] ? Boolean(fieldHelpers[name]) : undefined}
                helperText={fieldHelpers[name] as unknown as string}
                onBlur={handleValidate(name)}
                onFocus={removeValidationError(name)}
                key={name}
              />
            );
          }
          if (name === 'repaymentType') {
            return isRbf ? (
              <Select
                label={'Repayment Type'}
                name={name}
                onChange={handleChange}
                value={formData[name]}
                error={fieldHelpers[name] ? Boolean(fieldHelpers[name]) : undefined}
                helperText={fieldHelpers[name] as unknown as string}
                onBlur={handleValidate(name)}
                onFocus={removeValidationError(name)}
                key={name}
                options={isRbf ? rbfRepaymentType : ibfRepaymentType}
                showDefaultOption={false}
              />
            ) : null;
          }
          if (name === 'rbfRevenueShare' && !isRbf) return null;

          const propsObj = {
            label,
            name,
            onChange: handleChange,
            value: formData[name] as string,
            error: fieldHelpers[name] ? Boolean(fieldHelpers[name]) : undefined,
            helperText: fieldHelpers[name] as string,
            onBlur: handleValidate(name),
            onFocus: removeValidationError(name),
            type: inputType,
            key: name,
          };
          if (name === 'invoicesDiscountRate') {
            return !isRbf ? (
              <TextField
                {...propsObj}
                value={propsObj.value}
                type={inputType ?? type}
                onChange={propsObj.onChange}
                onBlur={propsObj.onBlur}
              />
            ) : null;
          }
          if (name === 'invoicesAmount') {
            return !isRbf ? (
              <TextField
                {...propsObj}
                value={propsObj.value}
                type={inputType ?? type}
                onChange={propsObj.onChange}
                onBlur={propsObj.onBlur}
              />
            ) : null;
          }

          if (name === 'monthlyRate' || name === 'annualRate') {
            if (name === 'annualRate' && isRbf) return null;

            let computedLabel = label;

            if (name === 'monthlyRate' && !isRbf) {
              computedLabel = 'Monthly Rate(%)';
            }

            const handleBlur = (payloadKey: 'monthlyRate' | 'annualRate') => async () => {
              handleValidate(name);
              const { annualRate, monthlyRate, loanType } = formData;

              if (loanType === 'RBF') return;

              const data: RatesPayload =
                payloadKey === 'annualRate'
                  ? {
                      annualRate: annualRate,
                    }
                  : {
                      monthlyRate: monthlyRate,
                    };

              const { payload } = (await dispatch(getComputedRates(data))) as {
                payload: RatesResponse;
              };

              if (payload) {
                handleChange({
                  target: {
                    name: 'monthlyRate',
                    value: payload.monthlyRate,
                  },
                } as EventType);

                handleChange({
                  target: {
                    name: 'annualRate',
                    value: payload.annualRate,
                  },
                } as EventType);
              }
            };

            return (
              <TextField
                {...propsObj}
                label={computedLabel}
                value={propsObj.value}
                type={inputType ?? type}
                onChange={propsObj.onChange}
                onBlur={handleBlur(name)}
                key={name}
              />
            );
          }
          if (name === 'principalAmount') {
            return (
              <TextField
                {...propsObj}
                value={propsObj.value}
                type={inputType ?? type}
                onChange={propsObj.onChange}
                onBlur={propsObj.onBlur}
                key={name}
              />
            );
          }
          return type === 'select' ? (
            <Select {...propsObj} options={selectOptions} showDefaultOption={hideDefaultOption} />
          ) : (
            <TextField
              {...propsObj}
              value={propsObj.value}
              disabled={name === 'interestAmount'}
              type={inputType ?? type}
              onChange={propsObj.onChange}
              onBlur={propsObj.onBlur}
              parseValue={name !== 'gracePeriodDays'}
            />
          );
        })}
      </Grid>
    </React.Fragment>
  );
};
