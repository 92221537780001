import { Popper, ClickAwayListener, MenuList, MenuItem, ListItemIcon, Paper } from '@mui/material';
import { Props } from './PopperMenu.types';
import { Typography, Checkbox } from 'components';

const PopperMenu = ({
  options,
  open,
  title,
  multiple,
  onClose,
  onSelect,
  children,
  ...props
}: Props) => {
  const handleClose = () => {
    onClose?.();
  };

  const handleSelect = (id: string, selected?: boolean) => {
    onSelect?.(id, selected);
  };

  return (
    <Popper open={open} {...props} sx={{ zIndex: 2000 }}>
      <ClickAwayListener onClickAway={handleClose}>
        <Paper sx={{ boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)', zIndex: 2000 }}>
          <Typography sx={{ p: '12px', pb: '6px' }} variant='h3'>
            {title}
          </Typography>
          {children ? (
            children
          ) : (
            <MenuList sx={{ maxHeight: '450px', overflowY: 'auto' }}>
              {options
                ? options.map((option) => (
                    <MenuItem
                      key={option.id}
                      onClick={() => handleSelect(option.id, !option.selected)}
                      sx={{ gap: '.5rem' }}
                      selected={multiple ? undefined : option.selected}
                    >
                      {multiple ? <Checkbox checked={option.selected} /> : null}
                      {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                      {option.name}
                    </MenuItem>
                  ))
                : null}
            </MenuList>
          )}
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default PopperMenu;
