import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { ServerError } from 'lms-types';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import { ServerTimeResponse } from 'types/api';

export const getServerTime = createAsyncThunk<
  ServerTimeResponse,
  undefined,
  {
    rejectValue: null;
  }
>('timeMachineSlice/getServerTime', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.get('/timeMachine')) as AxiosResponse<ServerTimeResponse>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});

export const setServerTime = createAsyncThunk<
  ServerTimeResponse,
  { newDate: string },
  { rejectValue: null }
>('timeMachineSlice/getServerTime', async (data, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.post('/timeMachine', data)) as AxiosResponse<ServerTimeResponse>;

    dispatch(
      addNotification({
        severity: 'success',
        message: 'Server time changed successfully',
      }),
    );

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});
