import { Components } from '@mui/material';
import palette from 'theme/palette/palette';

export const MuiIconButton: Components['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      padding: '0.375rem',
      borderRadius: '0.25rem',

      '& svg': {
        width: '1rem',
        height: '1rem',
        fill: palette.primary.main,
        padding: 0,
      },

      '&:hover': {
        backgroundColor: palette.primary.main,
      },

      '&:hover svg, &:active svg': {
        fill: palette.common.white,
      },

      '&.Mui-disabled svg': {
        fill: '#AAAAAA',
      },
    },
  },
};
