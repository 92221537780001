/* eslint-disable react/display-name */
import { useEffect, useState, forwardRef } from 'react';
import { useSelector } from 'hooks';
import { companyTransactionsSelector } from 'pages/DetailedCompany/store/detailedCompanySlice';
import { columns } from './Transactions.config';
import { renderCells } from './Transactions.helpers';
import { Props } from './Transactions.types';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import { Transaction } from 'bst-types';
import { Checkbox } from 'components';

const VirtuosoTableComponents: TableComponents<Transaction> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
  TableHead: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function rowContent(_index: number, row: Transaction) {
  return <>{columns.map((column) => renderCells(column.dataKey, row))}</>;
}

const headingHeight = 24;
const padding = 24 * 2;

const computeAllowedHeight = () => {
  const navbar = document.getElementById('navbar');
  const filters = document.getElementById('filters');
  if (!navbar || !filters) return;

  return window.innerHeight - navbar.offsetHeight - filters.offsetHeight - headingHeight - padding;
};

export const Transactions = ({ isChecked, onHeaderCheckClick }: Props) => {
  const transactions = useSelector(companyTransactionsSelector);
  const [maxTableHeight, setMaxTableHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    const height = computeAllowedHeight();
    setMaxTableHeight(height);
    window.addEventListener('resize', () => {
      const height = computeAllowedHeight();
      setMaxTableHeight(height);
    });

    return () =>
      window.removeEventListener('resize', () => {
        computeAllowedHeight();
      });
  }, []);

  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant='head'
            align={column.numeric || false ? 'right' : 'left'}
            style={{ width: column.width }}
            sx={{ backgroundColor: 'background.paper' }}
          >
            {column.dataKey === 'select-all' ? (
              <Checkbox checked={isChecked} onChange={onHeaderCheckClick} />
            ) : (
              column.label
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  return (
    <TableVirtuoso
      style={{ height: maxTableHeight }}
      data={transactions}
      components={VirtuosoTableComponents}
      fixedHeaderContent={fixedHeaderContent}
      itemContent={rowContent}
    />
  );
};
