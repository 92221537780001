import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from 'lms-types';
import { RootState } from 'store';
import { v4 as uuid } from 'uuid';
import { LmsErrorMessage } from '../../components/modals/LmsErrorModal/LmsErrorModal.types';

type GlobalInitialState = {
  notifications: Notification[];
  isLoading: boolean;
  isSidebarOpen: boolean;
  lmsErrorMessages: LmsErrorMessage[];
};

const initialState: GlobalInitialState = {
  notifications: [],
  isLoading: true,
  isSidebarOpen: true,
  lmsErrorMessages: [],
};

const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setGlobalLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    addNotification(state, action: PayloadAction<Notification>) {
      const id = uuid();
      state.notifications.push({ ...action.payload, id });
    },
    dropNotification(state, action: PayloadAction<string>) {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload,
      );
    },
    setSidebarOpenState(state, action: PayloadAction<boolean>) {
      state.isSidebarOpen = action.payload;
    },
    clearLmsErrors(state) {
      state.lmsErrorMessages = [];
    },
    addLmsError(state, action: PayloadAction<LmsErrorMessage>) {
      if (!action.payload) return;
      state.lmsErrorMessages.push(action.payload);
    },
  },
});

export const {
  setGlobalLoading,
  addNotification,
  dropNotification,
  setSidebarOpenState,
  clearLmsErrors,
  addLmsError,
} = globalSlice.actions;

export default globalSlice.reducer;

// Selectors

const globalSliceSelector = (state: RootState) => state.globalSlice;

export const isGlobalLoadingSelector = createSelector(
  globalSliceSelector,
  (globalSlice) => globalSlice.isLoading,
);

export const globalNotificationsSelector = createSelector(
  globalSliceSelector,
  (globalSlice) => globalSlice.notifications,
);

export const isSidebarOpenSelector = createSelector(
  globalSliceSelector,
  (globalSlice) => globalSlice.isSidebarOpen,
);

export const globalLmsErrorMessagesSelector = createSelector(
  globalSliceSelector,
  (globalSlice) => globalSlice.lmsErrorMessages,
);
