import { styled } from 'theme';
import { Tabs as MuiTabs, tabsClasses } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  padding-bottom: 0.25rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
`;

export const ImageWrapper = styled('div')`
  border-radius: 0.4rem;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
`;

export const FlexContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 2rem;

  & > button {
    align-self: center;
  }
`;

export const Tabs = styled(MuiTabs)`
  transform: translateY(0.3rem);

  & .${tabsClasses.fixed} {
    padding-bottom: 8px;
  }
`;
