import { TabOption } from 'lms-types';

const rawTabOpts = [
  { label: 'Credit profile', value: 'credit-profile' },
  { label: 'Loans', value: 'loans' },
  // { label: 'Customer files', value: 'customerFiles' },
  { label: 'Revenue', value: 'revenue' },
  { label: 'History', value: 'history' },
] as const;

type TabOptsType = (typeof rawTabOpts)[number];

type TabKeys = TabOptsType['value'];

export const mappedTabOptions = rawTabOpts.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.value]: curVal.value }),
  {} as Record<TabKeys, TabKeys>,
);

export const tabOpts = rawTabOpts as unknown as TabOption[];
