import { styled } from 'theme';
import CloseSvg from '@mui/icons-material/Close';

export const Wrapper = styled('div')`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const FiltersContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CloseIcon = styled(CloseSvg)`
  fill: #656e86;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;
