import { useMemo, forwardRef } from 'react';
import { Typography } from 'components';
import { Wrapper } from './Avatar.styles';
import { AvatarProps } from './Avatar.types';

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ name, size = 'medium', active = true, img, onClick }, ref) => {
    const initials = useMemo(() => {
      const namesArr = name.split(' ');

      let computedInitial = namesArr[0].charAt(0).toUpperCase();

      if (namesArr.length > 1) {
        computedInitial += namesArr[1].charAt(0).toUpperCase();
      }

      return computedInitial;
    }, [name]);

    const handleClick = () => {
      onClick?.();
    };

    return (
      <Wrapper size={size} active={active} ref={ref} onClick={handleClick}>
        {img ? (
          <img src={img} alt={name} />
        ) : (
          <Typography variant='caption' color='white'>
            {initials}
          </Typography>
        )}
      </Wrapper>
    );
  },
);

Avatar.displayName = 'Avatar';
