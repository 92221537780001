import { Components, Theme, alpha } from '@mui/material';
import palette from 'theme/palette/palette';

export const MuiList: Components<Theme>['MuiList'] = {
  styleOverrides: {
    root: {
      '& .MuiMenuItem-root': {
        textTransform: 'capitalize',
        fontFamily: 'IBM Plex Sans',
        fontWeight: '500 !important',
        fontSize: '15px',
        color: palette.secondary.main,
        lineHeight: 1,
        minHeight: 0,
        minWidth: '12rem',
        padding: '10px 12px',
        backgroundColor: 'transparent',

        '&.Mui-selected': {
          backgroundColor: alpha(palette.secondary.light, 0.65),
        },

        '&:hover': {
          backgroundColor: palette.secondary.light,
        },
        '&.Mui-selected:hover': {
          backgroundColor: palette.secondary.light,
        },
      },

      '& .MuiListItemIcon-root': {
        color: palette.secondary.main + ' ' + '!important',

        '& svg': {
          fill: palette.secondary.main + ' ' + '!important',
        },
      },
    },
  },
};

export const MuiListSubheader: Components<Theme>['MuiListSubheader'] = {
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      color: palette.secondary.main,
      fontFamily: 'inherit',
      fontSize: '0.95rem',
    },
  },
};

export const MuiMenuItem: Components['MuiMenuItem'] = {};

export const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: `${theme.palette.common.white} !important`,
      minWidth: '0 !important',

      '& svg': {
        fill: `${theme.palette.common.white} !important`,
        width: '1rem !important',
        height: '1rem !important',
      },
    }),
  },
};
