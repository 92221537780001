import { Components, Theme } from '@mui/material';
import palette from 'theme/palette/palette';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: { size: 'small' },
  styleOverrides: {
    select: {
      color: palette.secondary.main,
      borderRadius: '.25rem !important',
      fontSize: '.9rem',
      padding: '.625rem .75rem',

      '&:hover': {
        backgroundColor: palette.grey[950],
      },

      '&.Mui-disabled': {
        backgroundColor: palette.grey[700],

        '&:hover': {
          backgroundColor: palette.grey[700],
        },
      },
    },

    icon: {
      fill: '#aaa',

      '&.Mui-disabled': {
        fill: '#4D4D4D',
      },
    },
  },
};
