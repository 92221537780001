import { ColumnData } from './Transactions.types';

export const columns: ColumnData[] = [
  {
    width: 30,
    label: '',
    dataKey: 'select-all',
  },
  {
    width: 100,
    label: 'Row',
    dataKey: 'rowNumber',
    numeric: true,
  },
  {
    width: 50,
    label: 'Classification',
    dataKey: 'classification',
  },
  {
    width: 110,
    label: 'Account',
    dataKey: 'account',
  },
  {
    width: 130,
    label: 'Amount',
    dataKey: 'amount',
  },

  {
    width: 150,
    label: 'Balance Amount',
    dataKey: 'balanceAmount',
  },
  {
    width: 130,
    label: 'Bank',
    dataKey: 'bank',
  },
  {
    width: 130,
    label: 'Country',
    dataKey: 'country',
  },
  {
    width: 130,
    label: 'Currency',
    dataKey: 'currency',
  },
  {
    width: 250,
    label: 'Description',
    dataKey: 'fullDescription',
  },
  {
    width: 250,
    label: 'Refined Description',
    dataKey: 'refinedDescription',
  },
  {
    width: 150,
    label: 'Transaction Date',
    dataKey: 'transactionDate',
  },
  {
    width: 50,
    label: '',
    dataKey: 'stickyHeader',
  },
];
