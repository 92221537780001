import { DisbursementStatus, LoanStatus } from 'lms-types';
import { styled } from 'theme';

interface WrapperProps {
  status: LoanStatus | DisbursementStatus;
}

const STATUS_COLORS = {
  SUCCESS: '#6FAE1E',
  WARNING: '#EDB700',
};

const statusesMappedToColors: { [key: string]: string } = {
  Active: STATUS_COLORS.SUCCESS,
  FAILED: STATUS_COLORS.WARNING,
  PAID: STATUS_COLORS.SUCCESS,
  APPROVED: STATUS_COLORS.SUCCESS,
  NEW: STATUS_COLORS.WARNING,
};

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'status',
})<WrapperProps>`
  border-radius: 100px;
  padding: 2px 8px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  background-color: ${(props) =>
    statusesMappedToColors[props.status]
      ? statusesMappedToColors[props.status]
      : props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
`;
