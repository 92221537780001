import { styled } from 'theme';
import { Typography as RawTypography } from 'components/common/Typography';

export const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ControlsWrapper = styled('div')`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const Typography = styled(RawTypography, {
  shouldForwardProp: (prop) => prop !== 'computedColor' && prop !== 'hasBackground',
})<{ computedColor?: string; hasBackground?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: ${(props) => (props.computedColor ? props.computedColor : '')};
  ${(props) =>
    props.hasBackground
      ? `padding: .1rem .3rem; border-radius: 4px; background-color: ${props.theme.palette.grey[900]}; width: max-content;`
      : ''}

  & span {
    color: #edb700 !important;
  }

  & svg {
    width: 1.1rem;
    height: 1.1rem;
    fill: ${(props) => (props.computedColor ? props.computedColor : '')};
  }
`;
