import { forwardRef, MouseEvent } from 'react';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

type Props = ButtonProps & {
  isLoading?: boolean;
  label?: string;
};

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ isLoading, children, label, onClick, ...props }, ref) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (isLoading || props.disabled) return;
      onClick?.(e);
    };

    return (
      <MuiButton {...props} ref={ref} onClick={handleClick}>
        {isLoading ? (
          <CircularProgress sx={{ width: '1rem !important', height: '1rem !important' }} />
        ) : children ? (
          children
        ) : (
          label ?? ''
        )}
      </MuiButton>
    );
  },
);

Button.displayName = 'Button';
