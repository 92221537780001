import { useMemo } from 'react';
import { Props } from './ErrorPage.types';
import { Typography } from 'components';
import { Wrapper } from './ErrorPage.styles';

const defaultErrorDescription =
  'It seems like our server is unavailable at the time. Pleae try later';

export const ErrorPage = ({ description = defaultErrorDescription, status, children }: Props) => {
  const errorTitle = useMemo(() => {
    switch (status) {
      case 404:
        return '404. Not found!';
      case 500:
        return '500. Server not working!';
      default:
        return 'Something went wrong!';
    }
  }, [status]);

  return (
    <Wrapper>
      <Typography variant='h1'>{errorTitle}</Typography>
      <Typography variant='h2'>{description}</Typography>
      {children}
    </Wrapper>
  );
};
