export const initialFormData = {
  rowNumber: '',
  transactionDate: '',
  valueDate: '',
  amount: '',
  balanceAmount: '',
  fullDescription: '',
  currency: '',
  bank: '',
  account: '',
  country: '',
  classification: '',
  refinedDescription: 'ipi transfer dtb bank charge paymeent',
};

export const editableTextFields = [
  'amount',
  'balanceAmount',
  'account',
  'bank',
  'country',
  'currency',
  'fullDescription',
  'refinedDescription',
  'transactionDate',
  'valueDate',
];

export const textFieldNameToLabel = {
  amount: 'Amount',
  balanceAmount: 'Balance Amount',
  account: 'Account',
  bank: 'Bank',
  country: 'Country',
  currency: 'Currency',
  fullDescription: 'Full description',
  refinedDescription: 'Refined description',
  transactionDate: 'Transaction date',
  valueDate: 'Value date',
};
