import { ModalTabs, IconButton, Typography } from 'components';
import { Wrapper } from './ModalHeader.styles';
import CloseIcon from '@mui/icons-material/Close';
import { ModalHeaderProps } from './ModalHeader.types';

export const ModalHeader = ({
  tabsOptions,
  onClose,
  onChange,
  tabsValue,
  title,
}: ModalHeaderProps) => {
  const handleChange = (value: string) => {
    onChange?.(value);
  };

  return (
    <Wrapper>
      {tabsOptions ? (
        <ModalTabs options={tabsOptions} value={tabsValue ?? ''} onChange={handleChange} />
      ) : (
        <Typography variant='h3' sx={{ mb: '1rem' }}>
          {title}
        </Typography>
      )}
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Wrapper>
  );
};
