import { styled } from 'theme';

export const Wrapper = styled('div')`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;
