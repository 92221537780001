import { useMemo, useState } from 'react';
import {
  Wrapper,
  GaugePlaceholder,
  GaugeFiller,
  DescriptionWrapper,
  DescriptionItem,
  FlexContainer,
  Marker,
  DescriptionItemContainer,
} from './CreditGauge.styles';
import { Props } from './CreditGauge.types';
import { Typography, IconButton } from 'components';
import { Collapse } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { formatNumber } from 'utils/formatNumber';
import { DefaultCurrency } from 'Constants';

export const CreditGauge = ({
  limit,
  title,
  utilised,
  gap,
  isGeneral,
  breakdown,
  currency = DefaultCurrency,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const utilisedPercentage = useMemo(() => {
    return (utilised * 100) / limit;
  }, [limit, utilised]);

  const toggleCollapsed = () => {
    setIsCollapsed((collapsed) => !collapsed);
  };

  return (
    <Wrapper>
      <Typography variant='h3'>{title}</Typography>
      <GaugePlaceholder>
        <GaugeFiller width={utilisedPercentage} />
      </GaugePlaceholder>
      <DescriptionWrapper>
        <DescriptionItem>
          <FlexContainer>
            <Typography variant='body1'>Limit</Typography>
            <Marker marked={false} />
          </FlexContainer>
          <Typography variant='body2'>{formatNumber(limit, currency)}</Typography>
        </DescriptionItem>
        <DescriptionItem>
          <FlexContainer>
            {isGeneral ? (
              <DescriptionItemContainer>
                <IconButton sx={{ padding: 0 }} onClick={toggleCollapsed}>
                  {isCollapsed ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
                <Typography variant='body1'>Utilised</Typography>
                <Marker marked={true} />
              </DescriptionItemContainer>
            ) : (
              <>
                <Typography variant='body1'>Utilised</Typography>
                <Marker marked={true} />
              </>
            )}
          </FlexContainer>
          <Typography variant='body2'>{formatNumber(utilised, currency)}</Typography>
        </DescriptionItem>
        {isGeneral ? (
          <Collapse in={isCollapsed}>
            <DescriptionItem>
              <FlexContainer>
                <Typography variant='body1'>Utilised IBF</Typography>
                <Marker marked={false} />
              </FlexContainer>
              <Typography variant='body2'>
                {formatNumber(breakdown?.utilisedIbf as number, currency)}
              </Typography>
            </DescriptionItem>
            <DescriptionItem>
              <FlexContainer>
                <Typography variant='body1'>Utilised RBF</Typography>
                <Marker marked={false} />
              </FlexContainer>
              <Typography variant='body2'>
                {formatNumber(breakdown?.utilisedRbf as number, currency)}
              </Typography>
            </DescriptionItem>
          </Collapse>
        ) : null}
        <DescriptionItem moveUp={isGeneral && !isCollapsed}>
          <FlexContainer>
            <Typography variant='body1'>Limit gap</Typography>
            <Marker marked={false} />
          </FlexContainer>
          <Typography variant='body2'>{formatNumber(gap, currency)}</Typography>
        </DescriptionItem>
      </DescriptionWrapper>
    </Wrapper>
  );
};
