import React, { useState } from 'react';
import { CurrenciesMap } from 'Constants';
import { LmsModal, Select, TextField, Typography } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { detailedLoanSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';
import { expenseCategoryOpts, initformData } from './RegisterExpenses.config';
import { registerNewExpense } from './store/registerExpenses.thunk';

type Props = {
  isOpen: boolean;
  onClose(): void;
};

export const RegisterExpensesModal = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch();
  const loan = useSelector(detailedLoanSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initformData);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const { payload } = await dispatch(registerNewExpense(formData));

      if (payload) {
        onClose?.();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((formData) => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const isFormValid = () => {
    const { amount, note, paymentDate, expenseCategory } = formData;

    const isExpenseCategorySelected = expenseCategory.length > 0;
    const isAmountFilled = amount.length > 0;
    const isNoteFilled = note.length > 0;
    const isPaymentDateSet = paymentDate.length > 0;

    return isAmountFilled && isNoteFilled && isPaymentDateSet && isExpenseCategorySelected;
  };

  return (
    <LmsModal
      open={isOpen}
      wrapperProps={{ width: '25rem', height: '55vh' }}
      headerProps={{
        onClose,
        title: 'Register a expense',
      }}
      footerProps={{
        label: 'Register expense',
        onClick: handleSubmit,
        isLoading,
        isDisabled: !isFormValid(),
      }}
    >
      <TextField
        fullWidth
        name='amount'
        label='Amount'
        onChange={handleChange}
        value={formData.amount}
        type='number'
        inputProps={{ step: 0.1 }}
        endIcon={
          <Typography variant='body2' sx={{ mr: '.5rem', color: 'grey.700' }}>
            {CurrenciesMap[loan.currency]}
          </Typography>
        }
      />

      <Select
        fullWidth
        label={'Expense category'}
        options={expenseCategoryOpts}
        value={formData.expenseCategory}
        onChange={(e) =>
          setFormData((formData) => ({ ...formData, expenseCategory: e.target.value as string }))
        }
      />

      <TextField
        fullWidth
        name='paymentDate'
        label='Payment Date'
        onChange={handleChange}
        value={formData.paymentDate}
        type='date'
      />

      <TextField
        fullWidth
        name='note'
        label='Note'
        onChange={handleChange}
        value={formData.note}
        multiline
        minRows={3}
      />
    </LmsModal>
  );
};
