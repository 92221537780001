import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export const Loader = () => {
  return (
    <Box display='flex' width='100%' height='100%' justifyContent='center' alignItems='center'>
      <CircularProgress />
    </Box>
  );
};
