import { TableCell } from '@mui/material';
import { useMemo } from 'react';
import { Transaction } from 'bst-types';
import { IconButton, Checkbox } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { EventType } from 'hooks/useFormData';
import {
  handleOpenEditTransactionModal,
  removeTransaction,
  selectTransaction,
  selectedTransactionsSelector,
} from 'pages/DetailedCompany/store/detailedCompanySlice';
import EditIcon from '@mui/icons-material/Edit';
import { TransactionKey } from './Transactions.types';

const TableCellCheckbox = ({ transaction }: { transaction: Transaction }) => {
  const dispatch = useDispatch();
  const selectedTransactions = useSelector(selectedTransactionsSelector);

  const checked = useMemo(() => {
    return selectedTransactions.includes(transaction.rowNumber);
  }, [selectedTransactions]);

  const handleChange = (evt: EventType) => {
    const updatedState = evt.target.value as boolean;

    if (updatedState) {
      dispatch(selectTransaction(transaction.rowNumber));
      return;
    }

    dispatch(removeTransaction(transaction.rowNumber));
  };

  return (
    <TableCell sx={{ pr: 0 }}>
      <Checkbox checked={checked} onChange={handleChange} />
    </TableCell>
  );
};

const EditTransactionCell = ({ transaction }: { transaction: Transaction }) => {
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(handleOpenEditTransactionModal(transaction));
  };

  return (
    <TableCell sx={{ position: 'sticky', right: 0, backgroundColor: 'common.white' }}>
      <IconButton onClick={handleOpenModal}>
        <EditIcon />
      </IconButton>
    </TableCell>
  );
};

export const renderCells = (key: TransactionKey, transaction: Transaction) => {
  const renderKey = transaction.rowNumber + transaction.valueDate + key;

  switch (key) {
    case 'select-all':
      return <TableCellCheckbox key={renderKey} transaction={transaction} />;
    case 'stickyHeader':
      return <EditTransactionCell key={renderKey} transaction={transaction} />;
    default:
      return <TableCell key={renderKey}>{transaction[key]}</TableCell>;
  }
};
