import { defaultQueryParams } from 'pages/Loans/Loans.config';

export const loanStatsList = [
  { title: 'Late', loanStatus: 'LATE', id: 'late' },
  { title: 'Overdue', loanStatus: 'OVERDUE', id: 'overdue' },
  { title: 'Waiting Disbursements', loanStatus: 'APPROVED', id: 'waitingDisbursement' },
  { title: 'Default Review', loanStatus: 'DEFAULT_REVIEW', id: 'defaultReview' },
  { title: 'Default Provisioning', loanStatus: 'DEFAULT_PROVISION', id: 'defaultProvision' },
];

export const computeLoansBasePath = () => {
  let loansPath = '/loans?';

  defaultQueryParams.forEach((param) => {
    loansPath = loansPath + 'include=' + param + '&';
  });

  return loansPath;
};
