import { PopperOption } from 'components/organisms/PopperMenu/PopperMenu.types';
import { useSelector } from 'hooks';
import {
  transactionsSliceBanksSelector,
  transactionsSliceCurrenciesSelector,
} from 'pages/DetailedCompany/store/detailedCompanySlice';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import deleteParamsEntry from 'utils/deleteParamsEntry';
import { SelectFilterTypes } from '../Filters.types';
import { classificationOpts } from '../Filters.config';

type FilterEffectsArgs = {
  setFilterOptions: React.Dispatch<
    React.SetStateAction<{
      [key in SelectFilterTypes]: PopperOption[];
    }>
  >;
  setRangeFiltersData: React.Dispatch<
    React.SetStateAction<{
      amount: {
        from: string;
        to: string;
      };
      balanceAmount: {
        from: string;
        to: string;
      };
      transactionDate: {
        from: string;
        to: string;
      };
    }>
  >;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
};

export const useFilterEffects = ({
  searchValue,
  setFilterOptions,
  setSearchValue,
  setRangeFiltersData,
}: FilterEffectsArgs) => {
  const timerRef = useRef<NodeJS.Timeout>();

  const banks = useSelector(transactionsSliceBanksSelector);
  const currencies = useSelector(transactionsSliceCurrenciesSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearch = (searchValue: string) => {
    if (searchValue) {
      setSearchParams((searchParams) => {
        searchParams.set('search', searchValue);
        return searchParams;
      });
    } else {
      setSearchParams((searchParams) => deleteParamsEntry(searchParams, 'search'));
    }
  };

  useEffect(() => {
    const querySearch = searchParams.get('search');

    if (querySearch) {
      setSearchValue(querySearch);
    }

    const rangeFiltersQueryData = {
      amount: searchParams.get('amount'),
      balanceAmount: searchParams.get('balance_amount'),
      transactionDate: searchParams.get('transaction_date'),
    };

    for (const key in rangeFiltersQueryData) {
      const fieldKey = key as keyof typeof rangeFiltersQueryData;
      const filterQueryValue = rangeFiltersQueryData[fieldKey];

      if (!filterQueryValue) continue;

      const [from, to] = filterQueryValue.split(',');

      setRangeFiltersData((rangeFilters) => ({
        ...rangeFilters,
        [fieldKey]: {
          from: from.includes('inf') ? '' : from,
          to: to.includes('inf') ? '' : to,
        },
      }));
    }
  }, []);

  useEffect(() => {
    const queryBank = searchParams.get('bank');
    const queryCurrency = searchParams.get('currency');
    const queryClassification = searchParams.get('classification');

    const mappedBanks = banks.map((bank) => ({
      name: bank,
      id: bank,
      selected: queryBank === bank,
    }));
    const mappedCurrencies = currencies.map((currency) => ({
      name: currency,
      id: currency,
      selected: queryCurrency === currency,
    }));

    const mappedClassification = classificationOpts.map((classification) => ({
      ...classification,
      selected: queryClassification === classification.id,
    }));

    setFilterOptions((filterOptions) => ({
      ...filterOptions,
      bank: mappedBanks,
      currency: mappedCurrencies,
      classification: mappedClassification,
    }));
  }, [banks, currencies]);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      handleSearch(searchValue);
    }, 700);

    return () => clearTimeout(timerRef.current);
  }, [searchValue]);
};
