import { createAsyncThunk } from '@reduxjs/toolkit';
import { DisbursementsResponse } from './disbursementsSliceTypes';
import { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios.config';
import { ServerError } from 'lms-types';
import { addNotification } from 'store/slices/globalSlice';
import { defaultErrorMessage } from 'Constants';

export const getAllDisbursements = createAsyncThunk<
  DisbursementsResponse,
  URLSearchParams,
  {
    rejectValue: null;
  }
>('disbursementsSlice/getDisbursements', async (params, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.get(
      `/disbursementRequests?${params}`,
    )) as AxiosResponse<DisbursementsResponse>;

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});
