import palette from './palette/palette';

const typography = {
  root: {
    fontSize: 16,
    color: palette.primary.dark,
    fontWeightBold: 'normal',
    fontWeightRegular: 'normal',
    fontWeightLight: 'initial',
    fontWeightMedium: 'initial',
    fontWeight: 400,
    fontFamily: 'Roboto',
  },
  h1: {
    fontSize: '1.5rem',
    fontWeight: '500 !important',
    fontFamily: 'Readex Pro',
    lineHeight: '2.125rem',
  },
  h2: {
    fontSize: '1.25rem',
    fontWeight: '400 !important',
    lineHeight: '1.5rem',
    fontFamily: 'Readex Pro',
  },
  h3: {
    fontSize: '1rem',
    fontWeight: '400 !important',
    lineHeight: '1.4rem',
    fontFamily: 'Readex Pro',
  },
  body1: {
    fontSize: '0.875rem',
    fontWeight: '400 !important',
    lineHeight: '1.2rem',
    fontFamily: 'IBM Plex Sans, sans-serif',
    color: palette.common.black,
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: '500 !important',
    lineHeight: '1.2rem',
    fontFamily: 'IBM Plex Sans, sans-serif',
    color: palette.common.black,
  },
  button: {
    fontSize: '0.875rem',
    fontWeight: '500 !important',
    lineHeight: '1.3rem',
    fontFamily: 'IBM Plex Sans, sans-serif',
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: '500 !important',
    lineHeight: '1rem',
    fontFamily: 'IBM Plex Sans, sans-serif',
    color: palette.common.black,
  },
};

export default typography;
