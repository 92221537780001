import { Typography } from 'components';
import { DropdownProps } from './Dropdown.types';
import { Wrapper } from './Dropdown.styles';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { forwardRef } from 'react';

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ id, label, isActive = false, children, onClick }, ref) => {
    const handleClick = () => {
      onClick?.(id ?? undefined);
    };

    return (
      <Wrapper onClick={handleClick} isActive={isActive} ref={ref}>
        {label ? (
          <Typography variant='body2' fontSize={'15px'}>
            {label}
          </Typography>
        ) : (
          children
        )}
        <ArrowDropDownOutlinedIcon />
      </Wrapper>
    );
  },
);

Dropdown.displayName = 'Dropdown';
