import { Wrapper, DataGroup, SummaryGroup } from './LoanDetails.styles';
import { Typography, Status } from 'components';
import { formatNumber } from 'utils/formatNumber';
import { formatPercent } from 'utils/formatPercent';
import { renderDashIfNotApproved } from './LoanDetails.helpers';
import { Divider } from '@mui/material';
import { useMemo } from 'react';
import { Props, WrittenOffKeys } from './LoanDetails.types';
import { LoanActions } from './components';
import { DetailedLoanWithLinks } from 'lms-types';
import { writtenOffTitles } from './LoanDetails.config';
import { LoanDetail } from '..';

export const LoanDetails = ({
  loan,
  openDisbursementModal,
  openRegisterExpensesModal,
  openReconcilePaymentModal,
  openRequestDisbursementModal,
}: Props) => {
  const { currency } = loan;

  const loanStatus = useMemo(() => {
    return loan.loanStatus;
  }, [loan.loanStatus]);

  const renderDashletData = (value: string | number) => {
    const result = renderDashIfNotApproved(value, loanStatus);

    return result === '-' ? result : formatNumber(result as number, currency);
  };

  const renderWrittenOffData = (key: WrittenOffKeys, loan: DetailedLoanWithLinks) => {
    if (loan.loanStatus !== 'Written off' || !writtenOffTitles[key]) return null;

    const { currency } = loan;

    return <LoanDetail title={writtenOffTitles[key]} message={formatNumber(loan[key], currency)} />;
  };

  return (
    <>
      <LoanActions
        loan={loan}
        openDisbursementModal={openDisbursementModal}
        openRegisterExpensesModal={openRegisterExpensesModal}
        openReconcilePaymentModal={openReconcilePaymentModal}
        openRequestDisbursementModal={openRequestDisbursementModal}
      />
      <Wrapper>
        <DataGroup>
          <LoanDetail title='Name' message={loan.name ?? '-'} />
          <LoanDetail title='Id' message={loan.loanId ?? '-'} />
          <LoanDetail title='Product' message={loan.loanType} />
          <LoanDetail title='Product version' message={loan.productVersion ?? '-'} />
          {loan.invoicesAmount ? (
            <LoanDetail
              title='Invoice(s) amount'
              message={formatNumber(loan.invoicesAmount, currency)}
            />
          ) : null}
          {loan.invoicesDiscountRate ? (
            <LoanDetail title='Discount Rate' message={formatPercent(loan.invoicesDiscountRate)} />
          ) : null}
          <LoanDetail title='Principal' message={formatNumber(loan.principalAmount, currency)} />
          {loan.originationFee ? (
            <LoanDetail
              title='Origination fee'
              message={formatNumber(loan.originationFee, currency)}
            />
          ) : null}
          <LoanDetail title='Interest' message={formatNumber(loan.interestAmount, currency)} />
          <LoanDetail
            title='Repayment amount'
            message={formatNumber(loan.repaymentAmount, currency)}
          />
          <LoanDetail title='Tenor' message={loan.tenor} />
          {loan.loanType === 'RBF' && loan.rbfRevenueShare ? (
            <LoanDetail title='Revenue share' message={formatPercent(loan.rbfRevenueShare)} />
          ) : null}
          <LoanDetail title='Payment Type' message={loan.repaymentType} />
          {loan.interestAllocationType ? (
            <LoanDetail title='Interest allocation' message={loan.interestAllocationType} />
          ) : null}
          {loan.collectionMethod ? (
            <LoanDetail title='Collection method' message={loan.collectionMethod} />
          ) : null}
          {loan.disbursementMethod ? (
            <LoanDetail title='Disbursement method' message={loan.disbursementMethod} />
          ) : null}
        </DataGroup>
        <DataGroup>
          {loan.dailyRate ? (
            <LoanDetail title='Daily rate' message={formatPercent(loan.dailyRate)} />
          ) : null}
          <LoanDetail title='Monthly rate' message={formatPercent(loan.monthlyRate)} />
          <LoanDetail title='APR' message={formatPercent(loan.annualRate)} />
          <LoanDetail title='Loan Rate' message={formatPercent(loan.loanRate)} />

          <Divider />
          <LoanDetail title='Penalty type' message={loan.penaltyType} />
          <LoanDetail title='Penalty' message={formatPercent(loan.penaltyPercent)} />
          <LoanDetail
            title='Grace period'
            message={`${loan.gracePeriodDays} ${loan.gracePeriodDays === 1 ? 'day' : 'days'}`}
          />
          <Divider />
          <LoanDetail title='Creation date' message={loan.createdAt} />
          <LoanDetail title='Approval date' message={loan.loanApprovalDate ?? 'Not approved'} />
          {loan?._links?.getApprovalPendingDisbursementRequest !== undefined ? (
            <LoanDetail title='Disbursement'>
              <Status status='PENDING' />
            </LoanDetail>
          ) : null}
          <LoanDetail title='Disbursement date' message={loan.disbursementDate ?? '-'} />
          {loan._links?.getLoanSchedule ? (
            <LoanDetail
              title='Instalments'
              message={`${loan.currentPaymentNumber}/${loan.numberOfPayments}`}
            />
          ) : null}
          {loan.firstInstalmentDate ? (
            <LoanDetail title='First instalment date' message={loan.firstInstalmentDate} />
          ) : null}
          <LoanDetail title='Next payment date' message={loan.nextPaymentDate ?? '-'} />
          <LoanDetail title='Maturity date' message={loan.maturityDate ?? '-'} />
          <LoanDetail title='Paid off date' message={loan.payoffDate ?? '-'} />
          {loan.loanStatus === 'Written off' ? (
            <>
              <Divider />
              {renderWrittenOffData('waivedAmount', loan)}
            </>
          ) : null}
          <Divider />
          {loan.delinquentAmount ? (
            <LoanDetail
              title='Delinquent amount'
              message={formatNumber(loan.delinquentAmount, currency)}
              color='red'
            />
          ) : null}

          <LoanDetail
            title='Total Outstanding amount'
            message={formatNumber(loan.totalOutstandingAmount, currency)}
          />
          <LoanDetail
            title='Disbursement offset'
            message={formatNumber(loan.disbursedOffsetAmount, currency)}
          />
          <LoanDetail
            title='Payment offset'
            message={formatNumber(loan.totalOffsetAmount, currency)}
          />
        </DataGroup>
        <DataGroup>
          <SummaryGroup>
            <Typography variant='h3'>Principal</Typography>
            <LoanDetail
              title='Disbursed'
              message={formatNumber(loan.principalDisbursedAmount, currency)}
              color='red'
            />
            {loan.originationFeeAmount ? (
              <LoanDetail
                title='Origination fee'
                message={formatNumber(loan.originationFeeAmount, currency)}
              />
            ) : null}
            <LoanDetail
              title='Repaid'
              message={renderDashletData(loan.paidPrincipalAmount)}
              color='green'
            />
            {renderWrittenOffData('writtenOffPrincipalAmount', loan)}
            <LoanDetail title='NET Payable' message={renderDashletData(loan.netPayablePrincipal)} />
          </SummaryGroup>
          <SummaryGroup>
            <Typography variant="h3">Interest</Typography>
            {loan.loanType === 'IBF2' ? (
              <>
                <LoanDetail
                  title="Expected interest"
                  message={renderDashletData(loan.interestAmount)}
                />
                <LoanDetail
                  title="Current expected interest"
                  message={renderDashletData(loan.currentExpectedInterest)}
                />
                <LoanDetail
                  title="Discounted interest"
                  message={renderDashletData(loan.discountInterestAmount)}
                />
                <LoanDetail
                  title="Accrued interest"
                  message={renderDashletData(loan.accruedInterest)}
                />
              </>
            ) : <LoanDetail title="Total" message={renderDashletData(loan.interestAmount)} />
            }
            <LoanDetail
              title="Repaid"
              message={renderDashletData(loan.paidInterestAmount)}
              color='green'
            />
            {renderWrittenOffData('writtenOffInterestAmount', loan)}
            <LoanDetail title='NET Payable' message={renderDashletData(loan.netPayableInterest)} />
          </SummaryGroup>
          <SummaryGroup>
            <Typography variant='h3'>Penalties</Typography>
            <LoanDetail title='Total' message={renderDashletData(loan.totalPenaltyAmount)} />
            <LoanDetail title='Repaid' message={renderDashletData(loan.paidPenaltiesAmount)} />
            {renderWrittenOffData('waivedPenaltyAmount', loan)}
            <LoanDetail title='NET Payable' message={renderDashletData(loan.netPayablePenalties)} />
          </SummaryGroup>
          <Typography variant='h3'>Total</Typography>
          {loan.totalExpensesAmount ? (
            <LoanDetail
              title='Total Expenses'
              message={formatNumber(loan.totalExpensesAmount, currency)}
            />
          ) : null}
          <LoanDetail title='Repaid' message={renderDashletData(loan.paidTotalAmount)} />
          {renderWrittenOffData('totalWrittenOffAmount', loan)}
          <LoanDetail title='Total NET Payable' message={renderDashletData(loan.netPayableTotal)} />
        </DataGroup>
      </Wrapper>
    </>
  );
};
