import { GroupedFieldsType } from '../../CustomerAndLoanModal.types';

export const initialFormData = {
  companyLegalName: '',
  incorporationCountry: '',
  incorporationDate: '',
  companyType: '',
  industry: '',
  email: '',
  partnerCompanyIdentifier: '',
  phone: '',
  website: '',
  contactName: '',
  loanOfficerUserId: '',
  creditLimit: '',
  ibfCreditLimit: '',
  rbfCreditLimit: '',
  creditScore: '',
  nextReviewDate: '',
  isComplianceApproved: false,
  nextComplianceReviewDate: '',
  bankName: '',
  iban: '',
  swift: '',
  bankAddress: '',
  accountNumber: '',
  branchCode: '',
  isPartner: false,
};

export type FormDataType = typeof initialFormData;

export const CURRENCY_PLACEHOLDER = '{currency}';

export const groupedFields: GroupedFieldsType<FormDataType> = {
  'Company details': [
    { name: 'companyLegalName', label: 'Company legal name', type: 'text' },
    { name: 'incorporationCountry', label: 'Incorporation country', type: 'select' },
    { name: 'contactName', label: 'Customer contact name', type: 'text' },
    { name: 'phone', label: 'Phone number', type: 'text' },
    { name: 'incorporationDate', label: 'Date of incorporation', type: 'text', inputType: 'date' },
    { name: 'companyType', label: 'Entity type', type: 'text' },
    { name: 'industry', label: 'Industry', type: 'select' },
    { name: 'email', label: 'Email', type: 'text' },
    { name: 'website', label: 'Website', type: 'text' },
  ],
  'Partner data': [
    { name: 'partnerCompanyIdentifier', label: 'Partner', type: 'select' },
    { name: 'isPartner', label: 'Mark as Partner', type: 'check' },
  ],
  'Risk data': [
    { name: 'creditScore', label: 'Credit Score', type: 'select' },
    { name: 'creditLimit', label: `General Credit limit(${CURRENCY_PLACEHOLDER})`, type: 'number' },
    { name: 'ibfCreditLimit', label: `IBF Credit limit(${CURRENCY_PLACEHOLDER})`, type: 'number' },
    { name: 'rbfCreditLimit', label: `RBF Credit limit(${CURRENCY_PLACEHOLDER})`, type: 'number' },
    { name: 'nextReviewDate', label: 'Next Review Date', type: 'text', inputType: 'date' },
    {
      name: 'nextComplianceReviewDate',
      label: 'Next Compliance Review Date',
      type: 'text',
      inputType: 'date',
    },
    { name: 'isComplianceApproved', label: 'Compliance Approved', type: 'check' },

    { name: 'loanOfficerUserId', label: 'Loan officer', type: 'select' },
  ],
  'Banking details': [
    { name: 'bankName', label: 'Bank Name', type: 'text' },
    { name: 'bankAddress', label: 'Bank Address', type: 'text' },
    { name: 'swift', label: 'Swift', type: 'text' },
    { name: 'iban', label: 'IBAN', type: 'text' },
    { name: 'accountNumber', label: 'Account number', type: 'text' },
    { name: 'branchCode', label: 'Branch code', type: 'text' },
  ],
};
