/* eslint-disable no-case-declarations */
import { TableCell } from '@mui/material';
import { RevioPurchase } from 'lms-types';

import { formatNumber } from 'utils/formatNumber';

export const renderCells = (key: string, purchase: RevioPurchase) => {
  switch (key) {
    case 'status':
      return (
        <TableCell
          key={key}
          sx={{
            fontWeight: '500 !important',
          }}
        >
          {purchase.status}
        </TableCell>
      );
    case 'amount':
      return (
        <TableCell key={key}>
          {purchase.purchase?.currency
            ? formatNumber(purchase.purchase?.total ?? 0, purchase.purchase.currency)
            : '-'}
        </TableCell>
      );
    case 'netAmount':
      return (
        <TableCell key={key}>
          {purchase.payment?.currency
            ? formatNumber(purchase.payment?.netAmount / 100 ?? 0, purchase.payment.currency)
            : '-'}
        </TableCell>
      );
    case 'feeAmount':
      return (
        <TableCell key={key}>
          {purchase.payment?.currency
            ? formatNumber(purchase.payment?.feeAmount / 100 ?? 0, purchase.payment.currency)
            : '-'}
        </TableCell>
      );
    case 'paidOn':
      return (
        <TableCell key={key}>
          {purchase.payment?.paidOn
            ? new Date(purchase.payment?.paidOn * 1000).toLocaleString()
            : '-'}
        </TableCell>
      );
    default:
      return <TableCell key={key}>{purchase[key as keyof typeof purchase] as string}</TableCell>;
  }
};
