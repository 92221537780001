export const tableHeaders = [
  { name: 'Id', id: 'revenueId' },
  { name: 'Amount', id: 'amount' },
  { name: 'Currency', id: 'currency' },
  { name: 'Period', id: 'reportingPeriod' },
];

export const mappedColumnIdsToNames = tableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);
