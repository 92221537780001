import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification, addLmsError } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { DisbursementWithLinks, ServerError } from 'lms-types';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import { RootState } from 'store';
import { ApproveDisbursementResults } from '../ApproveDisbursementModal.types';
import { DisbursementRequestLmsError } from '../../LmsErrorModal/LmsErrorModal.types';

export const approveDisbursementRequest = createAsyncThunk<
  DisbursementWithLinks,
  { requestId: string; code: string },
  { rejectValue: DisbursementRequestLmsError; state: RootState }
>(
  'approveDisbursementModalSlice/approveDisbursementRequest',
  async ({ code, requestId }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post(`/disbursementRequests/${requestId}/approve`, {
        totp: code,
      })) as AxiosResponse<DisbursementWithLinks>;

      dispatch(
        addNotification({
          severity: 'success',
          message: 'Disbursement request approved successfully!',
        }),
      );
      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      const displayError = serverError?.response?.data?.error?.displayError;
      if (!displayError) {
        dispatch(
          addNotification({
            severity: 'error',
            message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
          }),
        );
      }
      dispatch(addLmsError(displayError));
      return rejectWithValue({
        disbursementRequestId: requestId,
        error: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
        displayError: displayError,
      } as DisbursementRequestLmsError);
    }
  },
);

export const approveDisbursementRequests = createAsyncThunk<
  ApproveDisbursementResults,
  { requestIds: string[]; code: string },
  { rejectValue: null; state: RootState }
>(
  'approveDisbursementModalSlice/approveDisbursementRequests',
  async ({ code, requestIds }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post('/disbursementRequests/approveAll', {
        totp: code,
        disbursementRequestIds: requestIds,
      })) as AxiosResponse<ApproveDisbursementResults>;

      const { successfullyUpdated, unsuccessfullyUpdated } =
        response.data.disbursementRequestResults.reduce(
          (counters, disbursementRequestResult) => {
            if (disbursementRequestResult.isSuccess) {
              counters.successfullyUpdated++;
            } else {
              counters.unsuccessfullyUpdated++;
            }
            return counters;
          },
          { successfullyUpdated: 0, unsuccessfullyUpdated: 0 },
        );

      dispatch(
        addNotification({
          severity: unsuccessfullyUpdated === 0 ? 'success' : 'error',
          message: `Successfully updated ${successfullyUpdated} requests! ${unsuccessfullyUpdated} failed!`,
        }),
      );

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(addLmsError(serverError.response?.data?.error?.displayError));
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const cancelDisbursementRequest = createAsyncThunk<
  DisbursementWithLinks,
  { requestId: string },
  { rejectValue: null; state: RootState }
>(
  'approveDisbursementModalSlice/cancelDisbursementRequest',
  async ({ requestId }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.post(
        `/disbursementRequests/${requestId}/cancel`,
      )) as AxiosResponse<DisbursementWithLinks>;

      dispatch(
        addNotification({
          severity: 'success',
          message: 'Disbursement request canceled successfully!',
        }),
      );

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(addLmsError(serverError.response?.data?.error?.displayError));
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);
