import React from 'react';
import { FormControl, SelectChangeEvent, Box } from '@mui/material';
import type { SelectProps } from '.';
import { StyledSelect, MenuItem } from './Select.styles';
import { Typography } from 'components';
import DoneIcon from '@mui/icons-material/Done';
import { defaultSelectOption } from 'Constants';

const Select: React.FC<SelectProps> = ({
  options,
  label,
  id,
  value,
  helperText,
  showDefaultOption = true,
  onChange,
  ...props
}) => {
  const renderOptions = () => {
    return options?.map((option) => (
      <MenuItem key={option.value} disabled={option.disabled} value={option.value} disableRipple>
        {option.name}
        {option.value === value && <DoneIcon />}
      </MenuItem>
    ));
  };

  const renderValue = (selectedValue: unknown) => {
    const selectedOption = options?.find((option) => option.value === selectedValue);

    return selectedOption?.name;
  };

  const delegateHandleChange = (evt: SelectChangeEvent<unknown>) => {
    onChange?.(evt as SelectChangeEvent<string | number>);
  };

  return (
    <FormControl fullWidth={props.fullWidth} size={props.size}>
      {label && (
        <Box marginBottom={'.3rem'}>
          <Typography variant='caption' color='secondary'>
            {label}
          </Typography>
        </Box>
      )}
      <StyledSelect
        {...props}
        id={id}
        value={value}
        renderValue={renderValue}
        onChange={delegateHandleChange}
      >
        {showDefaultOption ? (
          <MenuItem value={defaultSelectOption[0].value} disableRipple>
            None
          </MenuItem>
        ) : null}
        {renderOptions()}
      </StyledSelect>
      {helperText && (
        <Box mt={'.3rem'}>
          <Typography fontSize='13px' lineHeight={1} color='error'>
            {helperText}
          </Typography>
        </Box>
      )}
    </FormControl>
  );
};

export default Select;
