import { PopperOption } from 'components/organisms/PopperMenu/PopperMenu.types';
import { useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import deleteParamsEntry from 'utils/deleteParamsEntry';

const emptyIncludableColumnMapping = {};
const emptyIncludableTableHeaders: Array<{ id: string; name: string }> = [];

export default (
  includeValues: Array<string>,
  fetcherFuncion: (params: URLSearchParams) => void,
  defaultColumnMapping: { [key: string]: string },
  defaultTableHeaders: Array<{ id: string; name: string }>,
  includableColumnMapping: { [key: string]: string } = emptyIncludableColumnMapping,
  includableTableHeaders: Array<{ id: string; name: string }> = emptyIncludableTableHeaders,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [includedColumns, setIncludedColumns] = useState<Array<PopperOption>>([]);

  useEffect(() => {
    const queryColumns = searchParams.getAll('include');
    const allColumns = { ...includableColumnMapping, ...defaultColumnMapping };
    setIncludedColumns(
      includeValues
        .filter((value) => allColumns[value])
        .map((value) => ({
          id: value,
          name: allColumns[value as keyof typeof includableColumnMapping] || `-${value}-`,
          selected: queryColumns.includes(value),
        })),
    );
  }, [includeValues]);

  useEffect(() => {
    fetcherFuncion(searchParams);
  }, [searchParams]);

  const tableHeaders = useMemo(() => {
    const excludedColumns = searchParams.getAll('include');

    if (defaultTableHeaders[defaultTableHeaders?.length - 1].id === 'stickyHeader') {
      const computedDefaultTableHeaders: Array<{ id: string; name: string }> = [
        ...defaultTableHeaders,
      ];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const stickyHeader = computedDefaultTableHeaders.pop()!;
      return [
        ...computedDefaultTableHeaders,
        ...includableTableHeaders.filter((header) => excludedColumns.includes(header.id)),
        stickyHeader,
      ];
    } else {
      return [
        ...defaultTableHeaders,
        ...includableTableHeaders.filter((header) => excludedColumns.includes(header.id)),
      ];
    }
  }, [searchParams]);

  const handleIncludeRows = (id: string, selected?: boolean) => {
    setIncludedColumns((columns) =>
      columns.map((column) => (column.id === id ? { ...column, selected: selected } : column)),
    );
    if (selected) {
      setSearchParams((searchParams) => {
        searchParams.append('include', id);
        return searchParams;
      });
    } else {
      setSearchParams((searchParams) => deleteParamsEntry(searchParams, 'include', id));
    }
  };

  const handleSearch = (searchValue: string) => {
    if (searchValue) {
      setSearchParams((searchParams) => {
        searchParams.set('search', searchValue);
        return searchParams;
      });
    } else {
      setSearchParams((searchParams) => deleteParamsEntry(searchParams, 'search'));
    }
  };

  const handleSort = (label: string) => {
    setSearchParams(() => {
      if (searchParams.get('sortBy') === label) {
        if (searchParams.get('orderBy') === 'desc') {
          searchParams.set('orderBy', 'asc');
          return searchParams;
        }

        searchParams.set('orderBy', 'desc');
        return searchParams;
      }

      searchParams.set('sortBy', label);
      searchParams.set('orderBy', 'desc');

      return searchParams;
    });
  };

  const handleChangePage = (newPage: number) => {
    setSearchParams((searchParams) => {
      searchParams.set('page', String(newPage + 1));
      return searchParams;
    });
  };

  const handleChangeRowsPerPage = (limit: string) => {
    setSearchParams((searchParams) => {
      searchParams.set('page', '1');
      searchParams.set('limit', limit);

      return searchParams;
    });
  };

  return {
    includedColumns,
    searchParams,
    tableHeaders,
    handleSearch,
    handleIncludeRows,
    handleSort,
    setSearchParams,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
