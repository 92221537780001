import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import { ServerError, Transaction } from 'bst-types';
import { DetailedCompanyResponse } from './detailedCompanySlice.types';

export const getDetailedCompany = createAsyncThunk<
  DetailedCompanyResponse,
  { name: string; params: URLSearchParams },
  {
    rejectValue: null;
  }
>(
  'detailedCompanySlice/getDetailedCompany',
  async ({ name, params }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/statements/${name}/transactions?${params}`,
      )) as AxiosResponse<DetailedCompanyResponse>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const classifySelectedTransactions = createAsyncThunk<
  { transactions: Array<number>; classification: string },
  { name: string; transactions: Array<number>; classification: string },
  {
    rejectValue: null;
  }
>(
  'detailedCompanySlice/classifySelectedTransactions',
  async ({ name, transactions, classification }, { dispatch, rejectWithValue }) => {
    try {
      (await axios.post(`/statements/${name}/transactions/classify`, {
        classification,
        transactions,
      })) as AxiosResponse<string>;

      return { transactions, classification };
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const editTransaction = createAsyncThunk<
  Transaction,
  { transaction: Transaction; companyId: string },
  {
    rejectValue: null;
  }
>(
  'detailedCompanySlice/editTransaction',
  async ({ companyId, transaction }, { dispatch, rejectWithValue }) => {
    try {
      (await axios.patch(`/statements/${companyId}/transactions`, [
        transaction,
      ])) as AxiosResponse<Transaction>;

      return transaction;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);
