import { useState } from 'react';

type ModalStateReturn = [() => void, () => void, boolean];

export const useModalState = (initialState = false): ModalStateReturn => {
  const [isModalOpen, setIsModalOpen] = useState(initialState);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return [handleOpenModal, handleCloseModal, isModalOpen];
};
