import { Wrapper, FlexContainer } from './CollapseBox.styles';
import { Props } from './CollapseBox.types';
import { Typography } from 'components';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';

export const CollapseBox = ({ icon, isCollapsed, title, id, children, onOpen, onClose }: Props) => {
  const handleOpen = () => {
    onOpen(id);
  };

  const handleClose = () => {
    onClose(id);
  };

  return (
    <Wrapper>
      <FlexContainer onClick={isCollapsed ? handleOpen : handleClose}>
        <div>
          {icon}
          <Typography variant='h3'>{title}</Typography>
        </div>
        {isCollapsed ? <ExpandLessIcon color='secondary' /> : <ExpandMoreIcon color='secondary' />}
      </FlexContainer>
      <Collapse in={isCollapsed}>{children}</Collapse>
    </Wrapper>
  );
};
