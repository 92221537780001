import { defaultMetaData } from 'Constants';
import { Table } from 'components';
import { useDispatch, useSelector } from 'hooks';
import {
  detailedLoadAccruedInterestSelector,
  detailedLoanSelector,
} from 'pages/DetailedLoan/store/detailedLoanSlice';
import { renderCells } from './AccruedInterest.helpers';
import {
  mappedColumnIdsToNames,
  tableHeaders as initialTableHeaders,
} from './AccruedInterest.config';
import useSortingParams from 'hooks/useSortingParams';
import { getDetailedLoanAccruedInterest } from 'pages/DetailedLoan/store/detailedLoanThunk';

const emptyArr: Array<string> = [];

export const AccruedInterest = () => {
  const dispatch = useDispatch();
  const detailedLoan = useSelector(detailedLoanSelector);
  const accruedInterests = useSelector(detailedLoadAccruedInterestSelector);

  const fetchAccruedInterest = (params: URLSearchParams) => {
    dispatch(getDetailedLoanAccruedInterest({ loanId: detailedLoan.loanId, params }));
  };

  const { searchParams, tableHeaders, handleChangePage, handleChangeRowsPerPage, handleSort } =
    useSortingParams(emptyArr, fetchAccruedInterest, mappedColumnIdsToNames, initialTableHeaders);

  return (
    <Table
      data={accruedInterests}
      metaData={defaultMetaData}
      renderCells={renderCells}
      tableHeaders={tableHeaders}
      onChangePage={handleChangePage}
      sortBy={searchParams.get('sortBy') ?? ''}
      orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
      removableColumns={[]}
      sortFields={emptyArr}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onSort={handleSort}
      onSelect={() => null}
      disablePagination
    />
  );
};
