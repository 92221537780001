import { RouterProvider } from 'react-router-dom';
import { appRouter } from 'routes/AppRoutes';
import { authRouter } from 'routes/AuthRoutes';
import { bankStatementRouter } from 'routes/BankStatementsRoutes';
import { useAuthHub, useSelector } from 'hooks';
import { authUserSelector } from 'store/slices/authSlice';
import { useMemo } from 'react';
import { NotificationContainer } from 'components';
import { appMode } from 'lms.config';
import './App.css';

const appRoutes = {
  LMS: appRouter,
  BANK_STATEMENT: bankStatementRouter,
};

function App() {
  useAuthHub();
  const user = useSelector(authUserSelector);

  const isAuthed = useMemo(() => {
    return Boolean(user) && user?.challengeName !== 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED';
  }, [user, user?.challengeName]);

  return (
    <>
      <NotificationContainer />
      <RouterProvider router={isAuthed ? appRoutes[appMode] : authRouter} />
    </>
  );
}

export default App;
