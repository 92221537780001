import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { DetailedLoanWithLinks, LoanWithLinks } from 'lms-types';
import { RootState } from 'store';
import {
  getDetailedLoanActionLogs,
  getDetailedLoan,
  getDetailedLoanTransactions,
  getDetailedLoanRevioPurchases,
  getDetailedLoanSchedule,
  setLoanDefaultReviewStatus,
  unsetLoanDefaultReviewStatus,
  writeOffLoan,
  setLoanDefaultProvisionStatus,
  handleCancelTransaction,
  getDetailedLoanRevioInformation,
  getDetailedLoanAccruedInterest,
  getDetailedLoanNotes,
  resetDetailedLoanRiskFlag,
  cancelLoan,
  getCustomerRelatedLoans,
} from './detailedLoanThunk';
import {
  DetailedLoanActionLogResponse,
  DetailedLoanTransactionsResponse,
  DetailedLoanRevioPurchasesResponse,
  LoanScheduleResponse,
  CollectionCompany,
  CollectionLoan,
  RecurringToken,
  AccruedInterest,
  DetailedLoanNotesResponse,
} from './detailedLoanSlice.types';
import { defaultMetaData } from 'Constants';
import { approveLoan } from 'pages/Loans/store/loansSlice.thunk';
import { addNewDisbursement } from 'components/modals/AddDisbursementModal/store/disbursementsModalSliceThunk';
import { reconcileNewPayment } from 'components/modals/ReconcilePaymentModal/store/reconcilePaymentSlice.thunk';
import { registerNewExpense } from 'components/modals/RegisterExpensesModal/store/registerExpenses.thunk';
import { requestDisbursement } from '../../../components/modals/RequestDisbursementModal/store/RequestDisbursementModalThunk';

interface DetailedCustomerState {
  loan: DetailedLoanWithLinks | null;
  actionLogs: DetailedLoanActionLogResponse | null;
  transactions: DetailedLoanTransactionsResponse | null;
  purchases: DetailedLoanRevioPurchasesResponse | null;
  loanSchedules: LoanScheduleResponse | null;
  isLoading: boolean;
  errorStatus: number | null;
  notes: DetailedLoanNotesResponse | null;
  cancelTransactionModal: {
    isOpen: boolean;
    isLoading: boolean;
    operationId: string | null;
  };
  revioInformation: {
    company: CollectionCompany | null;
    loan: CollectionLoan | null;
    recurringTokens: RecurringToken[];
  };
  accruedInterest: Array<AccruedInterest>;
  relatedLoans: { loansList: Array<LoanWithLinks> };
}

const initialState: DetailedCustomerState = {
  loan: null,
  actionLogs: null,
  transactions: null,
  purchases: null,
  isLoading: true,
  errorStatus: null,
  loanSchedules: null,
  notes: null,
  cancelTransactionModal: {
    operationId: null,
    isOpen: false,
    isLoading: false,
  },
  revioInformation: {
    company: null,
    loan: null,
    recurringTokens: [],
  },
  accruedInterest: [],
  relatedLoans: {
    loansList: [],
  },
};

const detailedLoanSlice = createSlice({
  name: 'detailedLoanSlice',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setTransactionModalOpen(state, action: PayloadAction<boolean>) {
      state.cancelTransactionModal.isOpen = action.payload;
    },
    setTransactionModalLoading(state, action: PayloadAction<boolean>) {
      state.cancelTransactionModal.isLoading = action.payload;
    },
    setTransactionModalOperationId(state, action: PayloadAction<string | null>) {
      state.cancelTransactionModal.operationId = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(getDetailedLoan.fulfilled, (state, action) => {
      state.loan = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getDetailedLoan.rejected, (state, action) => {
      state.isLoading = false;
      state.errorStatus = action.payload ?? null;
    });
    builder.addCase(getDetailedLoanActionLogs.fulfilled, (state, action) => {
      state.actionLogs = action.payload;
    });
    builder.addCase(approveLoan.fulfilled, (state, action) => {
      state.loan = action.payload;
    });
    builder.addCase(requestDisbursement.fulfilled, (state, action) => {
      state.loan = action.payload._embedded.loan;
    });
    builder.addCase(addNewDisbursement.fulfilled, (state, action) => {
      state.loan = action.payload._embedded.loan;

      state.transactions = {
        transactions: action.payload.transactions,
        metaData: action.payload.metaData,
        _embedded: action.payload._embedded,
      };
    });
    builder.addCase(getDetailedLoanTransactions.fulfilled, (state, action) => {
      state.transactions = action.payload;
    });
    builder.addCase(getDetailedLoanRevioPurchases.fulfilled, (state, action) => {
      state.purchases = action.payload;
    });
    builder.addCase(getDetailedLoanRevioInformation.fulfilled, (state, action) => {
      state.revioInformation = action.payload;
    });

    builder.addCase(handleCancelTransaction.fulfilled, (state, action) => {
      state.loan = action.payload._embedded.loan;
      state.transactions = action.payload;
    });

    builder.addCase(reconcileNewPayment.fulfilled, (state, action) => {
      state.loan = action.payload._embedded.loan;
      state.transactions = {
        transactions: action.payload.transactions,
        metaData: action.payload.metaData,
        _embedded: action.payload._embedded
      };
    });

    builder.addCase(registerNewExpense.fulfilled, (state, action) => {
      state.loan = action.payload._embedded.loan;
      state.transactions = {
        transactions: action.payload.transactions,
        metaData: action.payload.metaData,
        _embedded: action.payload._embedded
      };
    });

    builder.addCase(getDetailedLoanSchedule.fulfilled, (state, action) => {
      state.loanSchedules = action.payload;
    });
    builder.addCase(setLoanDefaultReviewStatus.fulfilled, (state, action) => {
      state.loan = action.payload;
    });
    builder.addCase(unsetLoanDefaultReviewStatus.fulfilled, (state, action) => {
      state.loan = action.payload;
    });
    builder.addCase(writeOffLoan.fulfilled, (state, action) => {
      state.loan = action.payload;
    });
    builder.addCase(setLoanDefaultProvisionStatus.fulfilled, (state, action) => {
      state.loan = action.payload;
    });
    builder.addCase(getDetailedLoanAccruedInterest.fulfilled, (state, action) => {
      state.accruedInterest = action.payload.accruedInterests;
    });
    builder.addCase(getDetailedLoanNotes.fulfilled, (state, action) => {
      state.notes = action.payload;
    });
    builder.addCase(resetDetailedLoanRiskFlag.fulfilled, (state, action) => {
      state.loan = action.payload;
    });
    builder.addCase(cancelLoan.fulfilled, (state, action) => {
      state.loan = action.payload;
    });
    builder.addCase(getCustomerRelatedLoans.fulfilled, (state, action) => {
      const sortedRelatedLoans = action.payload
        .filter((loan) => loan.loanStatus !== 'Canceled')
        .sort((prevLoan, curLoan) => {
          const curCreateDate = new Date(prevLoan.createdAt);
          const prevCreateDate = new Date(curLoan.createdAt);

          if (curCreateDate < prevCreateDate) {
            return 1;
          } else return -1;
        });

      state.relatedLoans.loansList = sortedRelatedLoans;
    });
  },
});

export const {
  setIsLoading,
  setTransactionModalLoading,
  setTransactionModalOpen,
  setTransactionModalOperationId,
} = detailedLoanSlice.actions;

export default detailedLoanSlice.reducer;

// Selectors

const detailedLoanSliceSelector = (state: RootState) => state.detailedLoanSlice;

export const isDetailedLoanLoadingSelector = createSelector(
  detailedLoanSliceSelector,
  (state) => state.isLoading,
);

export const detailedLoanSelector = createSelector(
  detailedLoanSliceSelector,
  (state) => state.loan ?? ({} as DetailedLoanWithLinks),
);

export const detailedLoanErrorStatusSelector = createSelector(
  detailedLoanSliceSelector,
  (state) => state.errorStatus,
);

export const detailedLoanActionLogsSelector = createSelector(
  detailedLoanSliceSelector,
  (state) =>
    state.actionLogs ??
    ({
      actionLogs: [],
      metaData: defaultMetaData,
      _embedded: { excludeValues: [], sortFields: [] },
    } as DetailedLoanActionLogResponse),
);

export const detailedLoanTransactionsSelector = createSelector(
  detailedLoanSliceSelector,
  (state) =>
    state.transactions ?? {
      transactions: [],
      metaData: defaultMetaData,
    },
);

export const detailedLoanRevioPurchasesSelector = createSelector(
  detailedLoanSliceSelector,
  (state) =>
    state.purchases ?? {
      purchases: [],
      metaData: defaultMetaData,
    },
);
export const detailedLoanRevioInformationSelector = createSelector(
  detailedLoanSliceSelector,
  (state) => state.revioInformation,
);

export const detailedLoanScheduleSelector = createSelector(
  detailedLoanSliceSelector,
  (state) => state.loanSchedules,
);

export const cancelTransactionModalSelector = createSelector(
  detailedLoanSliceSelector,
  (state) => state.cancelTransactionModal,
);

export const detailedLoadAccruedInterestSelector = createSelector(
  detailedLoanSliceSelector,
  (state) => state.accruedInterest,
);

export const detailedLoanNotesSelector = createSelector(
  detailedLoanSliceSelector,
  (state) => state.notes,
);

export const relatedLoansByCustomerSelector = createSelector(
  detailedLoanSliceSelector,
  (state) => state.relatedLoans,
);
