import { useDispatch, useSelector } from 'hooks';
import { Props } from './Notes.types';
import { getDetailedLoanNotes } from 'pages/DetailedLoan/store/detailedLoanThunk';
import { detailedLoanNotesSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';
import { Table } from 'components';
import { mappedColumnIdsToNames, tableHeaders as initTableHeaders } from './Notes.config';
import useSortingParams from 'hooks/useSortingParams';
import { defaultMetaData } from 'Constants';
import { renderCells } from './Notes.helpers';

const emptyArr: Array<string> = [];

export const Notes = ({ loanId }: Props) => {
  const dispatch = useDispatch();
  const loanNotes = useSelector(detailedLoanNotesSelector);

  const fetchNotes = (params: URLSearchParams) => {
    dispatch(getDetailedLoanNotes({ loanId, params }));
  };

  const { handleChangePage, handleChangeRowsPerPage, handleSort, searchParams, tableHeaders } =
    useSortingParams(emptyArr, fetchNotes, mappedColumnIdsToNames, initTableHeaders);

  return (
    <Table
      data={loanNotes?.loanNotes ?? []}
      metaData={loanNotes?.metaData ?? defaultMetaData}
      tableHeaders={tableHeaders}
      sortBy={searchParams.get('sortBy') ?? ''}
      orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
      removableColumns={[]}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onSelect={() => null}
      sortFields={emptyArr}
      renderCells={renderCells}
      onSort={handleSort}
    />
  );
};
