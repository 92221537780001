import { styled } from 'theme';

export const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem; 
`;
