import { styled } from 'theme';

export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.palette.grey[950]};
`;

export const InnerWrapper = styled('div')`
  padding: 2rem;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  background-color: ${(props) => props.theme.palette.common.white};
`;
