import { FlexContainer, ImageWrapper, Tabs } from '../../Navbar.styles';
import { Tab } from '@mui/material';
import { Props } from './LmsTabs.types';
import { useEffect } from 'react';
import logo from 'assets/images/flow48-logo.png';
import { isProd, showTimeMachine } from 'lms.config';
import { Button, TimeMachine } from 'components';

export const LmsTabs = ({ tabsValue, handleNavigate, handleTabChange, onOpenModal }: Props) => {
  useEffect(() => {
    handleNavigate('/customers')();
  }, []);

  return (
    <>
      <FlexContainer>
        <ImageWrapper>
          <img src={logo} alt='Flow48 logo' />
        </ImageWrapper>

        <Tabs value={tabsValue} onChange={handleTabChange}>
          <Tab label='Home' value='/' onClick={handleNavigate('/')} />
          <Tab label='Customers' value='/customers' onClick={handleNavigate('/customers')} />
          <Tab label='Loans' value='/loans' onClick={handleNavigate('/loans')} />
          <Tab
            label='Disbursements'
            value='/disbursements'
            onClick={handleNavigate('/disbursements')}
          />
        </Tabs>
      </FlexContainer>
      {isProd ? null : (
        <FlexContainer style={{ minWidth: '200px' }}>
          <Button onClick={onOpenModal}>Add</Button>
          {showTimeMachine ? (
            <FlexContainer>
              <TimeMachine />
            </FlexContainer>
          ) : null}
        </FlexContainer>
      )}
    </>
  );
};
