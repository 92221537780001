import { LmsModal, Select, Typography } from 'components';
import { Props } from './ClassifyModal.types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'hooks';
import {
  selectedTransactionsSelector,
  deselectAllTransactions,
} from 'pages/DetailedCompany/store/detailedCompanySlice';
import { TransactionClassification } from 'Constants';
import { TransactionClassificationTypes } from 'bst-types';
import { classifySelectedTransactions } from 'pages/DetailedCompany/store/detailedCompanySlice.thunk';
import { useParams } from 'react-router';

const mappedTransactionClassifications = TransactionClassification.map((classification) => ({
  name: classification,
  value: classification,
}));

export const ClassifyModal = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch();
  const param = useParams();

  const transactions = useSelector(selectedTransactionsSelector);

  const [classification, setClasification] = useState<'' | TransactionClassificationTypes>('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      await dispatch(
        classifySelectedTransactions({ name: param.companyId ?? '', classification, transactions }),
      );
      await dispatch(deselectAllTransactions());
      onClose?.();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LmsModal
      open={isOpen}
      wrapperProps={{ width: '25rem', height: '45vh' }}
      headerProps={{ title: 'Register revenue', onClose }}
      footerProps={{
        label: 'Classify',
        onClick: handleSubmit,
        isLoading,
      }}
    >
      <Typography variant='h3' sx={{ mb: '1rem' }}>
        Selected: {transactions.length}
      </Typography>
      <Select
        fullWidth
        label='Classification'
        value={classification}
        options={mappedTransactionClassifications}
        onChange={(e) => setClasification(e.target.value as TransactionClassificationTypes)}
      />
    </LmsModal>
  );
};
