import { Button } from 'components';
import { styled } from 'theme';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.palette.grey[700]};
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: relative;
`;

export const WrapperOverlay = styled('div')`
  border-radius: 0.6rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.palette.common.white};
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const Actions = styled('div')`
  display: flex;
  gap: 1rem;
`;

export const InfoRow = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: ${(props) => props.theme.palette.common.white};
  border-radius: 0.5rem;
  column-gap: 2rem;
  row-gap: 1rem;
`;

export const AlertButton = styled(Button)`
  border: 1px solid #e70033;
  color: #e70033;

  &:hover {
    background-color: #e70033;
    color: ${(props) => props.theme.palette.common.white};
    border-color: #e70033;
  }
`;
