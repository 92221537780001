import { Transaction } from 'bst-types';
import { LmsModal, Select, TextField } from 'components';
import {
  initialFormData,
  editableTextFields,
  textFieldNameToLabel,
} from './EditTransactionModal.config';
import React, { useState } from 'react';
import { TransactionClassification } from 'Constants';
import { useDispatch } from 'hooks';
import { editTransaction } from 'pages/DetailedCompany/store/detailedCompanySlice.thunk';
import { useParams } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  editedTransaction: Transaction | null;
  onClose(): void;
};

const mappedTransactionClassifications = TransactionClassification.map((classification) => ({
  name: classification,
  value: classification,
}));

export const EditTransactionModal = ({ isOpen, editedTransaction, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ ...initialFormData, ...editedTransaction });
  const [isLoading, setIsLoading] = useState(false);

  const param = useParams();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      await dispatch(
        editTransaction({ transaction: formData as Transaction, companyId: param.companyId ?? '' }),
      );

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((formData) => ({ ...formData, [evt.target.name]: evt.target.value }));
  };

  return (
    <LmsModal
      open={isOpen}
      wrapperProps={{ width: '25rem', height: '75vh' }}
      headerProps={{ title: `Edit transaction ${editedTransaction?.rowNumber}`, onClose }}
      footerProps={{
        label: 'Save',
        onClick: handleSubmit,
        isLoading,
      }}
    >
      <Select
        label='Classification'
        options={mappedTransactionClassifications}
        value={formData.classification ?? ''}
        onChange={(e) =>
          setFormData((formData) => ({ ...formData, classification: e.target.value as string }))
        }
        fullWidth
      />
      {editableTextFields.map((field) => (
        <TextField
          name={field}
          label={textFieldNameToLabel[field as keyof typeof textFieldNameToLabel]}
          key={field}
          value={formData[field as keyof typeof formData]}
          onChange={handleChange}
          fullWidth
        />
      ))}
    </LmsModal>
  );
};
