import { store } from 'store';
import {
  validateCreateCustomerField,
  validateCreateLoanField,
  createCustomer,
  createLoan,
} from 'store/thunks/customerAndLoanModalThunk';
import { FormDataType as CustomerFormDataType } from './components/CreateCustomer/CreateCustomer.config';
import { FormDataType as LoanFormDataType } from './components/CreateLoan/CreateLoan.config';

import emptyStringToNull from 'utils/emptyStringToNull';

const dispatch = store.dispatch;

export const handleSubmit = async (
  tabsValue: 'customer' | 'loan',
  formData: CustomerFormDataType | LoanFormDataType,
  setFieldError: (key: string, value?: string) => void,
) => {
  const isCustomerForm = tabsValue === 'customer';

  const validateFieldAction = isCustomerForm
    ? validateCreateCustomerField
    : validateCreateLoanField;

  const formDataCopyValidation = emptyStringToNull(formData);

  const { payload } = await dispatch(validateFieldAction(formDataCopyValidation));

  const keysArr = payload ? Object.keys(payload) : [];

  if (keysArr.length > 0) {
    keysArr.forEach((key) => {
      setFieldError(key, payload?.[key]);
    });
    throw new Error('form-invalid');
  }

  try {
    let response;
    if (isCustomerForm) {
      response = await dispatch(createCustomer(formDataCopyValidation));
    } else {
      response = await dispatch(createLoan(formDataCopyValidation));
    }

    return response;
  } catch (err) {
    throw new Error('request-failed');
  }
};
