export const tableHeaders = [
  { name: 'Disbursement Request', id: 'disbursementRequestId' },
  { name: 'Status', id: 'status' },
  { name: 'Amount', id: 'amount' },
  { name: 'Payment Id', id: 'paymentId'},
  { name: 'Payment Date', id: 'paymentDate'},
];

export const mappedColumnIdsToNames = tableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);
