import { useEffect } from 'react';
import { Hub } from 'aws-amplify/utils';
import { useDispatch } from './reduxHooks';
import { addNotification } from 'store/slices/globalSlice';

export const useAuthHub = () => {
  const dispatch = useDispatch();

  useEffect(() => {
   const unListener =  Hub.listen('auth', async ({ payload }) => {
      switch (payload.event) {
        case 'signInWithRedirect_failure':
        case 'tokenRefresh_failure':
          dispatch(
            addNotification({
              severity: 'error',
              message: payload.data.error?.message ?? 'An error occurred',
            }),
          );
          break;
        default:
          break;
      }
    });
   return () => {
     unListener();
   }
  }, []);
};
