import { RefinementRule } from 'bst-types';

export const editableTextFields = ['original', 'newValue', 'maxUsdAmount', 'minUsdAmount'];

export const editableSelects = ['operation', 'bank'];

export const editableFields = [...editableTextFields, ...editableSelects];

export const refinementSelectOptions = [
  { name: 'Remove', value: 'remove' },
  { name: 'Group', value: 'group' },
];

export const initFormData: RefinementRule = {
  bank: '',
  original: '',
  operation: '',
  newValue: '',
  minUsdAmount: '',
  maxUsdAmount: '',
  rowNumber: '',
};
