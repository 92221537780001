import { Paper, Popper, SxProps, TableCell, Theme } from '@mui/material';
import { LoanSchedule, LoanScheduleTransactionTypes } from 'lms-types';
import { formatNumber } from 'utils/formatNumber';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import UpdateIcon from '@mui/icons-material/Update';
import { Typography } from './LoanSchedule.styles';
import { useMemo, useRef } from 'react';
import { useModalState } from 'hooks';
import { format } from 'date-fns';

const getTransactionIcon = (type: LoanScheduleTransactionTypes) => {
  switch (type) {
    case 'Disbursement':
      return <ArrowUpwardIcon />;
    case 'Expected':
      return <HourglassEmptyIcon />;
    case 'Overdue':
      return <UpdateIcon />;

    default:
      return <ArrowDownwardIcon />;
  }
};

const getTransactionTextColor = (type: LoanScheduleTransactionTypes) => {
  switch (type) {
    case 'Disbursement':
      return '#E70033';
    case 'Paid':
      return '#6FAE1E';
    case 'Overdue':
      return '#EDB700';
    default:
      return '';
  }
};

const getExpectedAmountTextColor = (expected: number, forecasted: number) => {
  if (!expected || !forecasted) return undefined;
  if (expected > forecasted) return '#6FAE1E';
  if (expected < forecasted) return '#E70033';
  return undefined;
};

const TableCellWithPopover = ({
  transactionType,
  overdueDays = 0,
  sx,
}: LoanSchedule & { sx: SxProps<Theme> }) => {
  const tableCellRef = useRef<HTMLTableCellElement | null>(null);
  const [handleOpenPopover, handleClosePopover, isPopoverOpen] = useModalState();

  const isOverdueDaysPositive = useMemo(() => {
    return overdueDays > 0;
  }, [overdueDays]);

  return (
    <TableCell
      sx={sx}
      ref={tableCellRef}
      onMouseEnter={isOverdueDaysPositive ? handleOpenPopover : undefined}
      onMouseLeave={isOverdueDaysPositive ? handleClosePopover : undefined}
    >
      <Typography
        variant='body2'
        fontWeight={500}
        computedColor={getTransactionTextColor(transactionType)}
      >
        {getTransactionIcon(transactionType)}
        {transactionType}
        {(overdueDays ?? 0) > 0 ? <span>(L)</span> : null}
      </Typography>
      <Popper open={isPopoverOpen} anchorEl={tableCellRef.current}>
        <Paper sx={{ padding: '.5rem', bgcolor: 'secondary.main' }}>
          <Typography color='common.white'>
            {overdueDays} {overdueDays === 1 ? 'day' : 'days'} late
          </Typography>
        </Paper>
      </Popper>
    </TableCell>
  );
};

export const renderCells = (
  key: keyof LoanSchedule,
  schedule: LoanSchedule,
  idx: number,
  length: number,
) => {
  if (!schedule) return null;
  const { loanCurrency } = schedule;
  const isLastElement = idx === length - 1;

  const sx: SxProps<Theme> = {
    borderBottom: '',
    borderTop: '',
  };

  if (schedule.isCurrentPayment) {
    if (isLastElement) {
      sx.borderBottom = '2px solid #0036B3';
    } else {
      sx.borderTop = '2px solid #0036B3';
    }
  }

  switch (key) {
    case 'interestDue':
    case 'penaltiesDue':
    case 'totalPaid':
    case 'totalRepaymentDue':
    case 'penaltyPaid':
    case 'principalDue':
    case 'principalPaid':
    case 'interestPaid':
    case 'instalmentTotalDueAmount':
      return (
        <TableCell key={key} sx={sx}>
          <Typography variant='body2' fontWeight={500}>
            {schedule[key] ? formatNumber(Number(schedule[key]), loanCurrency) : '-'}
          </Typography>
        </TableCell>
      );
    case 'totalForecasted':
      return (
        <TableCell
          key={key}
          sx={{
            ...sx,
            textAlign: 'center',
          }}
        >
          <Typography
            variant='body2'
            fontWeight={500}
            hasBackground={Boolean(schedule.totalForecasted)}
          >
            {schedule[key] ? formatNumber(Number(schedule[key]), loanCurrency) : '-'}
          </Typography>
        </TableCell>
      );

    case 'totalExpected':
      return (
        <TableCell key={key} sx={sx}>
          <Typography
            variant='body2'
            fontWeight={500}
            computedColor={getExpectedAmountTextColor(
              schedule.totalExpected ?? 0,
              schedule.totalForecasted ?? 0,
            )}
          >
            {schedule[key] ? formatNumber(Number(schedule[key]), loanCurrency) : '-'}
          </Typography>
        </TableCell>
      );

    case 'transactionType':
      return <TableCellWithPopover key={key} {...schedule} sx={sx} />;

    default:
      return (
        <TableCell sx={sx} key={key}>
          {schedule[key] ?? '-'}
        </TableCell>
      );
  }
};

export const getChartData = (schedules: Array<LoanSchedule>, loanTotal: number) => {
  const filteredSchedules = schedules.filter((schedule, idx) =>
    idx > 0 ? Boolean(schedule?.totalForecasted) : true,
  );

  let forecastAmount = loanTotal;
  let totalPaid = loanTotal;

  return filteredSchedules.map((schedule, idx) => {
    if (idx > 0) {
      forecastAmount -= schedule.totalForecasted as number;
      if (schedule.totalPaid) {
        totalPaid -= schedule.totalPaid;
      }
    }

    return {
      month: schedule?.dueDate ? format(new Date(schedule?.dueDate), 'dd MMM') : null,
      forecasted: forecastAmount < 0 ? 0 : forecastAmount,
      actual: idx === 0 || schedule?.totalPaid ? totalPaid : null,
    };
  });
};
