import { AppPageWrapper, Typography } from 'components';
import React from 'react';

export const Reports = () => {
  return (
    <AppPageWrapper>
      <Typography variant='h1'>Reports</Typography>
    </AppPageWrapper>
  );
};
