import { useMemo, useState } from 'react';
import { ActionsContainer } from './LoanActions.styles';
import { Button, Status, Typography } from 'components';
import { useModalState, useDispatch } from 'hooks';
import { AlertButton } from '../../LoanDetails.styles';
import {
  cancelLoan,
  writeOffLoan,
  resetDetailedLoanRiskFlag,
  setLoanDefaultReviewStatus,
  unsetLoanDefaultReviewStatus,
  setLoanDefaultProvisionStatus,
} from 'pages/DetailedLoan/store/detailedLoanThunk';
import { LoanWithNotesModal } from 'pages/DetailedLoan/components/LoanWithNotesModal';
import { approveLoan } from 'pages/Loans/store/loansSlice.thunk';
import { openRevenueModal } from 'components/modals/RegisterRevenueModal/store/registerRevenueModalSlice';
import { Props } from './LoanActions.types';

export const LoanActions = ({
  loan,
  openDisbursementModal,
  openRegisterExpensesModal,
  openReconcilePaymentModal,
  openRequestDisbursementModal,
}: Props) => {
  const dispatch = useDispatch();
  const [
    handleOpenSetLoanDefaultReviewStatusModal,
    handleCloseSetLoanDefaultReviewStatusModal,
    isSetDefaultReviewNoteModalOpen,
  ] = useModalState();
  const [
    handleOpenUnsetLoanDefaultReviewStatusModal,
    handleCloseUnsetLoanDefaultReviewStatusModal,
    isUnsetDefaultReviewNoteModalOpen,
  ] = useModalState();

  const [handleOpenLoanWriteOffModal, handleCloseLoanWriteOffModal, isWriteOffLoanModalOpen] =
    useModalState();
  const [handleOpenResetLoanRiskModal, handleCloseResetLoanRiskModal, isResetLoanRiskModalOpen] =
    useModalState();
  const [handleOpenCancelLoanModal, handleCloseCancelLoanModal, isCancelModalOpen] =
    useModalState();
  const [noteForCancelLoan, setNoteForCancelLoan] = useState('');
  const [noteForSetDefaultReview, setNoteForSetDefaultReview] = useState('');
  const [noteForUnsetDefaultReview, setNoteForUnsetDefaultReview] = useState('');
  const [noteForWriteOffLoan, setNoteForWriteOffLoan] = useState('');
  const [noteForResetLoanRisk, setNoteForResetLoanRisk] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSetLoanDefaultReviewStatus = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        setLoanDefaultReviewStatus({ loanId: loan?.loanId, note: noteForSetDefaultReview }),
      );
      handleCloseSetLoanDefaultReviewStatusModal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnsetLoanDefaultReviewStatus = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        unsetLoanDefaultReviewStatus({ loanId: loan?.loanId, note: noteForUnsetDefaultReview }),
      );
      handleCloseUnsetLoanDefaultReviewStatusModal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleWriteOffLoan = async () => {
    setIsLoading(true);
    try {
      await dispatch(writeOffLoan({ loanId: loan?.loanId, note: noteForWriteOffLoan }));
      handleCloseLoanWriteOffModal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleTransitionToDefaultProvision = async () => {
    await dispatch(setLoanDefaultProvisionStatus(loan?.loanId));
  };

  const handleOpenRegisterRevenueModal = () => {
    dispatch(openRevenueModal());
  };

  const handleOpenReconcilePaymentModal = () => {
    openReconcilePaymentModal?.();
  };

  const handleApprove = () => {
    dispatch(approveLoan(loan?.loanId));
  };

  const handleResetLoanRisk = () => {
    setIsLoading(true);
    try {
      dispatch(resetDetailedLoanRiskFlag({ loanId: loan.loanId, note: noteForResetLoanRisk }));
      handleCloseResetLoanRiskModal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelLoan = async () => {
    setIsLoading(true);
    try {
      await dispatch(cancelLoan({ loanId: loan?.loanId, note: noteForCancelLoan }));
      handleCloseCancelLoanModal();
    } finally {
      setIsLoading(false);
    }
  };

  const displayRegisterRevenue = useMemo(
    () => loan?._links.registerRevenue && loan.repaymentType === 'Monthly variable',
    [loan?._links.registerRevenue, loan.repaymentType],
  );

  return (
    <>
      <ActionsContainer>
        <div>
          <Typography variant='h2'>{loan?.name}</Typography>
          <Status status={loan?.loanStatus} />
        </div>

        <div>
          {loan?._links.salesforce ? (
            <Button variant='outlined' href={loan?._links.salesforce.href}>
              Open in Salesforce
            </Button>
          ) : null}
          {displayRegisterRevenue ? (
            <Button onClick={handleOpenRegisterRevenueModal}>Register revenue</Button>
          ) : null}
          {loan?._links?.approveLoan ? <Button onClick={handleApprove}>Approve</Button> : null}
          {loan?._links?.requestDisbursement ? (
            <Button onClick={openRequestDisbursementModal}>Request disbursement</Button>
          ) : null}
          {loan?._links?.addDisbursement ? (
            <Button onClick={openDisbursementModal}>Add disbursement</Button>
          ) : null}

          {loan?._links?.addRepayments ? (
            <Button onClick={handleOpenReconcilePaymentModal}>Reconcile payment</Button>
          ) : null}
          {loan?._links?.addRepayments ? (
            <Button onClick={openRegisterExpensesModal}>Register expenses</Button>
          ) : null}
          {loan?._links.setLoanDefaultReviewStatus ? (
            <Button onClick={handleOpenSetLoanDefaultReviewStatusModal}>Set Default Review</Button>
          ) : null}
          {loan?._links.unsetLoanDefaultReviewStatus ? (
            <Button onClick={handleOpenUnsetLoanDefaultReviewStatusModal}>
              Unset Default Review
            </Button>
          ) : null}
          {loan?._links.transitToDefaultProvision ? (
            <Button onClick={handleTransitionToDefaultProvision}>Set Default Provisioning</Button>
          ) : null}
          {loan?._links.writeOffLoan ? (
            <Button onClick={handleOpenLoanWriteOffModal}>Write off</Button>
          ) : null}
          {loan?._links.resetRiskFlag ? (
            <Button onClick={handleOpenResetLoanRiskModal}>Reset Loan Risk</Button>
          ) : null}
          {loan._links.cancelLoan ? (
            <AlertButton onClick={handleOpenCancelLoanModal}>Cancel</AlertButton>
          ) : null}
          {/* <Button>Restructure</Button> */}
          {/* <Button>Edit</Button> */}
          {/* <AlertButton>Inactivate</AlertButton> */}
        </div>
      </ActionsContainer>
      {isSetDefaultReviewNoteModalOpen ? (
        <LoanWithNotesModal
          isLoading={isLoading}
          label='Save Default Review'
          note={noteForSetDefaultReview}
          isDisabled={!noteForSetDefaultReview}
          onChangeNote={setNoteForSetDefaultReview}
          onClose={handleCloseSetLoanDefaultReviewStatusModal}
          onSubmit={handleSetLoanDefaultReviewStatus}
        />
      ) : null}
      {isUnsetDefaultReviewNoteModalOpen ? (
        <LoanWithNotesModal
          isLoading={isLoading}
          label='Unset Default Review'
          note={noteForUnsetDefaultReview}
          isDisabled={!noteForUnsetDefaultReview}
          onChangeNote={setNoteForUnsetDefaultReview}
          onClose={handleCloseUnsetLoanDefaultReviewStatusModal}
          onSubmit={handleUnsetLoanDefaultReviewStatus}
        />
      ) : null}
      {isWriteOffLoanModalOpen ? (
        <LoanWithNotesModal
          isLoading={isLoading}
          label='Write off the loan'
          note={noteForWriteOffLoan}
          isDisabled={!noteForWriteOffLoan}
          onChangeNote={setNoteForWriteOffLoan}
          onClose={handleCloseLoanWriteOffModal}
          onSubmit={handleWriteOffLoan}
        />
      ) : null}
      {isResetLoanRiskModalOpen ? (
        <LoanWithNotesModal
          isLoading={isLoading}
          label='Reset risk level'
          note={noteForResetLoanRisk}
          isDisabled={!noteForResetLoanRisk}
          onChangeNote={setNoteForResetLoanRisk}
          onClose={handleCloseResetLoanRiskModal}
          onSubmit={handleResetLoanRisk}
        />
      ) : null}
      {isCancelModalOpen ? (
        <LoanWithNotesModal
          isLoading={isLoading}
          label='Cancel loan'
          note={noteForCancelLoan}
          isDisabled={!noteForCancelLoan}
          onChangeNote={setNoteForCancelLoan}
          onClose={handleCloseCancelLoanModal}
          onSubmit={handleCancelLoan}
        />
      ) : null}
    </>
  );
};
