import { ReactElement, forwardRef, cloneElement } from 'react';
import { Typography } from 'components';
import { Input } from './TextField.styles';
import { TextFieldProps as MuiTextFieldProps, Box } from '@mui/material';

export type TextFieldProps = Omit<MuiTextFieldProps, 'variant'> & {
  variant?: MuiTextFieldProps['variant'];
  endIcon?: ReactElement;
  startIcon?: ReactElement;
  parseValue?: boolean;
  warning?: boolean;
};

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      label,
      fullWidth,
      variant = 'outlined',
      sx,
      warning,
      endIcon,
      startIcon,
      parseValue = true,
      onChange,
      ...props
    },
    ref,
  ) => {
    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (props?.type === 'number' && parseValue) {
        const inputValue = (evt.target as HTMLInputElement).value;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, decimal] = inputValue.split('.');

        if (decimal?.length > 2) return;

        onChange?.(evt);
      }

      onChange?.(evt);
    };

    return (
      <Box width={fullWidth ? '100%' : '18rem'} sx={sx}>
        {label && (
          <Box marginBottom={'.3rem'}>
            <Typography variant='caption' color='secondary'>
              {label}
            </Typography>
          </Box>
        )}
        <Input
          warning={warning}
          fullWidth
          variant={variant}
          InputProps={{
            endAdornment: endIcon
              ? cloneElement(endIcon, { ...endIcon.props, id: 'end-adornment' })
              : null,
            startAdornment: startIcon
              ? cloneElement(startIcon, { ...startIcon.props, id: 'start-adornment' })
              : null,
          }}
          onChange={handleChange}
          {...props}
          ref={ref}
        />
      </Box>
    );
  },
);

TextField.displayName = 'TextField';
