import React from 'react';
import { Typography, TextField, Select } from 'components';
import { FormDataType } from './CreateCustomer.config';
import { Grid, Checkbox } from '../../CustomerAndLoanModal.styles';
import { useSelector } from 'hooks';
import { modalPicklistOptionsSelector } from 'store/slices/customerAndLoanModalSlice';
import { defaultSelectOption } from 'Constants';
import { EventType } from 'hooks/useFormData';
import { FieldDescription } from '../../CustomerAndLoanModal.types';

type Props = {
  title: string;
  fields: Array<FieldDescription<FormDataType>>;
  formData: FormDataType;
  fieldHelpers: Partial<FormDataType>;
  handleChange(evt: EventType): void;
  handleValidate(field: keyof FormDataType): () => void;
  removeValidationError(field: keyof FormDataType): () => void;
};

export const CustomerFormSection = ({
  title,
  fields,
  formData,
  fieldHelpers,
  handleChange,
  handleValidate,
  removeValidationError,
}: Props) => {
  const picklistOptions = useSelector(modalPicklistOptionsSelector);

  return (
    <React.Fragment>
      <Typography variant='h2' sx={{ mb: '1.5rem', mt: '1rem' }}>
        {title}
      </Typography>
      <Grid>
        {fields.map(({ label, name, type, inputType }) => {
          const selectOptions = picklistOptions?.[name] ?? defaultSelectOption;

          const commonProps = {
            label,
            name,
            onChange: handleChange,
            onBlur: handleValidate(name),
            onFocus: removeValidationError(name),
          };

          if (formData.incorporationCountry === 'ZA') {
            if (name === 'iban' || name === 'swift') return null;
          }

          if (formData.incorporationCountry !== 'ZA') {
            if (name === 'branchCode' || name === 'accountNumber') return null;
          }

          if (type === 'check') {
            return (
              <Checkbox
                key={name}
                {...commonProps}
                isDisabled={
                  name === 'isPartner' ? formData.partnerCompanyIdentifier?.length > 0 : false
                }
                checked={formData[name] as boolean}
                align={
                  name === 'isPartner'
                    ? Boolean(fieldHelpers.partnerCompanyIdentifier)
                    : Boolean(fieldHelpers.nextComplianceReviewDate)
                }
              />
            );
          } else if (type === 'select') {
            return (
              <Select
                key={name}
                {...commonProps}
                disabled={name === 'partnerCompanyIdentifier' ? formData.isPartner : false}
                error={fieldHelpers[name] ? Boolean(fieldHelpers[name]) : undefined}
                helperText={fieldHelpers[name] as string}
                options={selectOptions}
                value={formData[name] as string}
              />
            );
          } else {
            return (
              <TextField
                key={name}
                {...commonProps}
                type={inputType ?? type}
                error={fieldHelpers[name] ? Boolean(fieldHelpers[name]) : undefined}
                helperText={fieldHelpers[name] as string}
                value={formData[name] as string}
              />
            );
          }
        })}
      </Grid>
    </React.Fragment>
  );
};
