import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { CustomerWithLinks, ServerError } from 'lms-types';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import { CustomerLoansResponse, CustomerLogsResponse } from './detailedCustomerSlice.types';

export const getDetailedCustomer = createAsyncThunk<
  CustomerWithLinks,
  string,
  {
    rejectValue: number | null;
  }
>(
  'detailedCustomerSlice/getDetailedCustomer',
  async (identifier, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/companies/${identifier}`,
      )) as AxiosResponse<CustomerWithLinks>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(serverError.response?.status ?? null);
    }
  },
);

export const getDetailedCustomersLoans = createAsyncThunk<
  CustomerLoansResponse,
  { customerId: string; params?: URLSearchParams },
  {
    rejectValue: null;
  }
>(
  'detailedCustomerSlice/getDetailedCustomerLoans',
  async ({ customerId, params = '' }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/companies/${customerId}/loans?${params}`,
      )) as AxiosResponse<CustomerLoansResponse>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const getDetailedCustomersActionLogs = createAsyncThunk<
  CustomerLogsResponse,
  { customerId: string; params?: URLSearchParams },
  {
    rejectValue: null;
  }
>(
  'detailedCustomerSlice/getDetailedCustomerActionLogs',
  async ({ customerId, params = '' }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/companies/${customerId}/actionLogs?${params}`,
      )) as AxiosResponse<CustomerLogsResponse>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);
