import { useDispatch, useModalState, useSelector } from 'hooks';
import { useParams } from 'react-router';
import { getDetailedCompany } from './store/detailedCompanySlice.thunk';
import { AppPageWrapper, Typography } from 'components';
import { Filters, Transactions, ClassifyModal, EditTransactionModal } from './components';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import {
  deselectAllTransactions,
  editModalStateSelector,
  handleCloseEditTransactionModal,
  resetState,
  selectAllTransactions,
  selectedTransactionsSelector,
} from './store/detailedCompanySlice';

export const DetailedCompany = () => {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const [searchParams] = useSearchParams();
  const selectedTransactions = useSelector(selectedTransactionsSelector);
  const { isOpen: isEditModalOpen, transaction: editedTransaction } =
    useSelector(editModalStateSelector);

  const areAnyTransactionsSelected = useMemo(() => {
    return selectedTransactions.length > 0;
  }, [selectedTransactions]);

  const [handleOpenClassifyModal, handleCloseClassifyModal, isClassifyModalOpen] = useModalState();

  const fetchDetailedCompany = (params: URLSearchParams) => {
    dispatch(getDetailedCompany({ name: companyId ?? '', params }));
  };

  useEffect(() => {
    dispatch(resetState());
  }, [companyId]);

  useEffect(() => {
    fetchDetailedCompany(searchParams);
  }, [searchParams]);

  const handleClassify = () => {
    handleOpenClassifyModal();
  };

  const handleHeaderClick = () => {
    if (areAnyTransactionsSelected) {
      dispatch(deselectAllTransactions());
    } else {
      dispatch(selectAllTransactions());
    }
  };

  const handleCloseEditModal = () => {
    dispatch(handleCloseEditTransactionModal());
  };

  return (
    <>
      {isClassifyModalOpen ? (
        <ClassifyModal isOpen={isClassifyModalOpen} onClose={handleCloseClassifyModal} />
      ) : null}
      {isEditModalOpen ? (
        <EditTransactionModal
          isOpen={isEditModalOpen}
          editedTransaction={editedTransaction}
          onClose={handleCloseEditModal}
        />
      ) : null}
      <AppPageWrapper>
        <Typography variant='h2'>{companyId}</Typography>
        <Filters onOpenClassifyModal={handleClassify} />
        <Transactions
          isChecked={areAnyTransactionsSelected}
          onHeaderCheckClick={handleHeaderClick}
        />
      </AppPageWrapper>
    </>
  );
};
