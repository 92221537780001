import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/globalSlice';
import axios from 'axios.config';
import { RevenueType, ServerError } from 'lms-types';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultErrorMessage } from 'Constants';
import { RevenuesResponse } from 'types/api';

export const getRevenues = createAsyncThunk<
  RevenuesResponse,
  { customerId: string; params?: URLSearchParams },
  {
    rejectValue: null;
  }
>(
  'revenueSlice/getRevenues',
  async ({ customerId, params = '' }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await axios.get(
        `/companies/${customerId}/revenues?${params}`,
      )) as AxiosResponse<RevenuesResponse>;

      return response.data;
    } catch (error) {
      const serverError = error as AxiosError<ServerError>;
      dispatch(
        addNotification({
          severity: 'error',
          message: serverError.response?.data?.error?.description ?? defaultErrorMessage,
        }),
      );
      return rejectWithValue(null);
    }
  },
);

export const registerRevenue = createAsyncThunk<
  RevenuesResponse,
  { companyId: string; data: RevenueType },
  { rejectValue: null }
>('revenueSlice/registerRevenue', async ({ companyId, data }, { dispatch, rejectWithValue }) => {
  try {
    const response = (await axios.post(
      `/companies/${companyId}/revenues`,
      data,
    )) as AxiosResponse<RevenuesResponse>;

    dispatch(
      addNotification({
        severity: 'success',
        message: 'Revenue saved successfully',
      }),
    );

    return response.data;
  } catch (error) {
    const serverError = error as AxiosError<ServerError>;
    dispatch(
      addNotification({
        severity: 'error',
        message: serverError?.response?.data?.error?.description ?? defaultErrorMessage,
      }),
    );
    return rejectWithValue(null);
  }
});
