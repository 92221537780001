import { Components } from '@mui/material';

export const MuiCheckbox: Components['MuiCheckbox'] = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
    size: 'small',
  },
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
};
