import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { LoanWithLinks } from 'lms-types';
import { RootState } from 'store';
import { LoansResponse } from './loansSlice.types';
import { approveLoan, getAllLoans } from './loansSlice.thunk';

interface LoansState extends LoansResponse {
  isLoading: boolean;
}

const initialState: LoansState = {
  isLoading: false,
  loans: [],
  _embedded: {
    includeValues: [],
    sortFields: [],
  },
  _links: {},
  metaData: {
    itemsCount: 0,
    limit: 10,
    page: 1,
  },
};

const loansSlice = createSlice({
  name: 'loansSlice',
  initialState,
  reducers: {
    addLoan(state, action: PayloadAction<LoanWithLinks>) {
      state.loans.push(action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllLoans.fulfilled, (state, action) => {
      state.loans = action.payload.loans;
      state._embedded = action.payload._embedded;
      state.metaData = action.payload.metaData;
      state._links = action.payload._links;
    });
    builder.addCase(approveLoan.fulfilled, (state, action) => {
      state.loans = state.loans.map((loan) =>
        loan.loanId === action.payload.loanId ? action.payload : loan,
      );
    });
  },
});

export const { addLoan } = loansSlice.actions;

export default loansSlice.reducer;

const loansSliceSelector = (state: RootState) => state.loansSlice;

export const loansSelector = createSelector(loansSliceSelector, (loans) => loans);
