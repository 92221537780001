import { TableCell } from '@mui/material';
import { Typography } from 'components';
import { useSelector } from 'hooks';
import { detailedLoanSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';
import { AccruedInterest } from 'pages/DetailedLoan/store/detailedLoanSlice.types';
import { formatNumber } from 'utils/formatNumber';

const CurrencyTableCell = ({ interest }: { interest: AccruedInterest }) => {
  const { currency } = useSelector(detailedLoanSelector);

  return (
    <TableCell>
      <Typography variant='body2' fontSize='0.85rem'>
        {formatNumber(interest.accruedInterest, currency)}
      </Typography>
    </TableCell>
  );
};

export const renderCells = (key: keyof AccruedInterest, interest: AccruedInterest) => {
  switch (key) {
    case 'accruedInterest':
      return <CurrencyTableCell interest={interest} />;
    default:
      return <TableCell key={key}>{interest[key]}</TableCell>;
  }
};
