import React from 'react';
import { Tabs, Tab } from './ModalTabs.styles';
import { TabOption } from 'lms-types';

type Props = {
  onChange(value: string): void;
  value: string;
  options: TabOption[];
};

export const ModalTabs = ({ onChange, value, options }: Props) => {
  const handleTabsChange = (_: React.SyntheticEvent, value: string) => {
    if (!value) return;
    onChange(value);
  };

  return (
    <Tabs value={value} onChange={handleTabsChange}>
      {options.map(({ label, value, disabled }, idx) => (
        <Tab key={idx} label={label} value={value} disabled={disabled} />
      ))}
    </Tabs>
  );
};
