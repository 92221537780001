import { styled } from 'theme';
import { Backdrop as MuiBackdrop } from '@mui/material';
import { Checkbox as DefaultCheckbox } from 'components/common/Checkbox/Checkbox';

export const Backdrop = styled(MuiBackdrop)`
  background-color: rgba(0, 8, 26, 0.8);
`;

export const Wrapper = styled('div')`
  max-width: 57rem;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.theme.palette.common.white};
  height: 100%;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TabsWrapper = styled('form')`
  padding: 1.5rem;
  overflow-y: scroll;
  height: calc(100% - 10rem);
`;

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 1rem;

  & > div {
    min-width: 20rem;
  }
`;

type CheckboxProps = {
  align?: boolean;
};

export const Checkbox = styled(DefaultCheckbox, {
  shouldForwardProp: (prop) => prop !== 'align',
})<CheckboxProps>`
  justify-content: flex-start !important;
  align-self: flex-end !important;
  margin-bottom: ${(props) => (props.align ? '1.2rem' : 0)};
`;
