import { EgyptFlag, EmiratesFlag, JordanFlag, SaudiFlag, SouthAfricaFlag } from 'assets/flags';
import { Currency, LoanStatus } from 'lms-types';

export const defaultSelectOption = [
  {
    name: 'No data',
    value: '',
  },
];

export const defaultErrorMessage = 'Something went wrong!';

export const mappedCountriesToFlags = {
  Egypt: <EgyptFlag />,
  Jordan: <JordanFlag />,
  'Saudi Arabia': <SaudiFlag />,
  'United Arab Emirates': <EmiratesFlag />,
  'South Africa': <SouthAfricaFlag />,
};

export const tableRowsOptions = [10, 25, 50];

export const HeaderKeys = {
  Authorization: 'Authorization',
  Accept: 'Accept',
  'Access-Control-Allow-Origin': 'Access-Control-Allow-Origin',
};

export const defaultMetaData = {
  itemsCount: 1,
  limit: 10,
  page: 1,
};

export const Months = [
  { name: 'January', value: '01' },
  { name: 'February', value: '02' },
  { name: 'March', value: '03' },
  { name: 'April', value: '04' },
  { name: 'May', value: '05' },
  { name: 'June', value: '06' },
  { name: 'July', value: '07' },
  { name: 'August', value: '08' },
  { name: 'September', value: '09' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' },
];

export const LoanStatuses: LoanStatus[] = [
  'Pending',
  'Approved',
  'Active',
  'Late',
  'Delinquent',
  'Default review',
  'Restructured',
  'Default provisioning',
  'Overdue',
  'Written off',
  'Paid off',
];

export const CountryCurrencies = {
  ZA: 'ZAR',
  AE: 'USD',
} as const;

export const CurrenciesMap: { [key in Currency]: string } = {
  'USD': '$',
  'ZAR': 'ZAR',
};

export const DefaultCurrency = 'USD';

export const appDateNames = ['date', 'Date'];

export const TransactionClassification = [
  'DR',
  'CR',
  'DD',
  'L-IN',
  'L-OUT',
  'L-PMT',
  'OT',
  'UNP',
  'TRF',
];

export enum Routes {
  COMPANIES = '/companies',
  REFINEMENT_RULES = '/refinement-rules',
}
