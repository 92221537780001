import { TableCell } from '@mui/material';
import { LoanNote } from 'pages/DetailedLoan/store/detailedLoanSlice.types';

export const renderCells = (key: keyof LoanNote, note: LoanNote, idx: number) => {
  const renderKey = key + note.noteId + idx;

  switch (key) {
    case 'user':
      return <TableCell key={renderKey}>{note.user.email}</TableCell>;

    default:
      return <TableCell key={renderKey}>{note[key]}</TableCell>;
  }
};
