export const tableHeaders = [
  { name: '', id: 'viewOperation' },
  { name: 'Operation ID', id: 'operationId' },
  { name: 'Transaction date', id: 'transactionDate' },
  { name: 'Status', id: 'status' },
  { name: 'Type', id: 'type' },
  { name: 'Amount', id: 'amount' },
  { name: 'Principal', id: 'principal' },
  { name: 'Interest', id: 'interest' },
  { name: 'Penalty', id: 'penalty' },
  { name: 'Author', id: 'user' },
  { name: 'StickyHeader', id: 'stickyHeader' },
];

export const mappedColumnIdsToNames = tableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);
