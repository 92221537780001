import { ButtonWrapper, Wrapper } from './ActionsPanel.styles';
import { Button, Typography } from 'components';
import { useDispatch, useSelector } from 'hooks';
import {
  openCustomerAndLoanModa,
  setInitialCreateLoanState,
} from 'store/slices/customerAndLoanModalSlice';
import { detailedCustomerSelector } from 'pages/DetailedCustomer/store/detailedCustomerSlice';

export const ActionsPanel = () => {
  const dispatch = useDispatch();
  const customer = useSelector(detailedCustomerSelector);

  const handleCreateLoanClick = () => {
    dispatch(
      setInitialCreateLoanState({
        companyIdentifier: customer.companyIdentifier,
        loanOfficerUserId: customer.loanOfficerUserId,
      }),
    );
    dispatch(openCustomerAndLoanModa());
  };

  return (
    <Wrapper>
      <Typography variant='h3'>Company Actions</Typography>
      <ButtonWrapper>
        { customer?._links?.salesforce ? ( 
          <Button label='Open in Salesforce' variant='outlined' href={customer._links.salesforce.href} />
        ) : null }
        <Button label='Create Loan' variant='outlined' onClick={handleCreateLoanClick} />
      </ButtonWrapper>
    </Wrapper>
  );
};
