import { TextField, Select, Typography, LmsModal } from 'components';
import { Props } from './RegisterRevenueModal.types';
import { useDispatch, useFormData, useSelector } from 'hooks';
import { closeRevenueModal } from './store/registerRevenueModalSlice';
import yearsOpts, { initialFormData } from './RegisterRevenueModal.config';
import { CurrenciesMap, Months } from 'Constants';
import { detailedLoanSelector } from 'pages/DetailedLoan/store/detailedLoanSlice';
import { FormEvent, useState } from 'react';
import { RevenueType } from 'lms-types';
import { registerRevenue } from 'store/thunks/revenueSliceThunk';
import { useParams } from 'react-router-dom';

export const RegisterRevenueModal = ({ isOpen }: Props) => {
  const dispatch = useDispatch();
  const { formData, handleChange } = useFormData(initialFormData);
  const loan = useSelector(detailedLoanSelector);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    dispatch(closeRevenueModal());
  };

  const handleSubmit = async (evt?: FormEvent<HTMLFormElement>) => {
    evt?.preventDefault();
    setIsLoading(true);
    try {
      const reqBody: RevenueType = {
        amount: formData.amount,
        currency: loan.currency,
        reportingPeriod: `${formData.year}-${formData.month}`,
      };

      const response = await dispatch(
        registerRevenue({
          companyId: params?.customerId ?? loan.companyIdentifier,
          data: reqBody,
        }),
      );

      if (response.payload) {
        handleCloseModal();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LmsModal
      open={isOpen}
      wrapperProps={{ width: '25rem', height: '45vh' }}
      headerProps={{ title: 'Register revenue', onClose: handleCloseModal }}
      footerProps={{
        label: 'Save revenue',
        onClick: handleSubmit,
        isLoading,
      }}
    >
      <TextField
        fullWidth
        label='Amount'
        name='amount'
        type='number'
        value={formData.amount}
        onChange={handleChange}
        endIcon={
          <Typography variant='body2' sx={{ mr: '.5rem', color: 'grey.700' }}>
            {CurrenciesMap[loan.currency]}
          </Typography>
        }
      />
      <Select
        fullWidth
        label='Month'
        name='month'
        value={formData.month}
        onChange={handleChange}
        options={Months}
      />
      <Select
        fullWidth
        label='Year'
        name='year'
        onChange={handleChange}
        value={formData.year}
        options={yearsOpts}
      />
    </LmsModal>
  );
};
