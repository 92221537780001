import { LoanStatus } from 'lms-types';
import { LoanStatuses } from 'Constants';

const activeLoanStatusIndex = LoanStatuses.findIndex((status) => status === 'Active');

export const renderDashIfNotApproved = (value: string | number, status: LoanStatus) => {
  const currentLoanStatusIndex = LoanStatuses.findIndex((enumStatus) => enumStatus === status);

  if (currentLoanStatusIndex < activeLoanStatusIndex) return '-';

  return value;
};
