export const tableHeaders = [
  { name: 'ID', id: 'noteId' },
  { name: 'Date', id: 'createdAt' },
  { name: 'Note', id: 'note' },
  { name: 'Author', id: 'user' },
];

export const mappedColumnIdsToNames = tableHeaders.reduce(
  (prevVal, curVal) => ({ ...prevVal, [curVal.id]: curVal.name }),
  {},
);
