import { useDispatch, useSelector } from 'hooks';
import {
  detailedLoanScheduleSelector,
  detailedLoanSelector,
} from 'pages/DetailedLoan/store/detailedLoanSlice';
import { Checkbox, Table, Typography } from 'components';
import { getDetailedLoanSchedule } from 'pages/DetailedLoan/store/detailedLoanThunk';
import useSortingParams from 'hooks/useSortingParams';
import { mappedColumnIdsToNames, tableHeaders as rawTableHeaders } from './LoanSchedule.config';
import { useMemo, useState } from 'react';
import { defaultMetaData } from 'Constants';
import { getChartData, renderCells } from './LoanSchedule.helpers';
import { useParams } from 'react-router';
import { ControlsWrapper, HeaderWrapper } from './LoanSchedule.styles';
import { LoanSchedule as LoanScheduleType } from 'lms-types';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

const emptyArr: Array<string> = [];

export const LoanSchedule = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const detailedLoan = useSelector(detailedLoanSelector);
  const loanSchedule = useSelector(detailedLoanScheduleSelector);
  const [filterOnlyForecasted, setFilterOnlyForecasted] = useState(detailedLoan.loanType === 'RBF');

  const chartData = useMemo(() => {
    if (detailedLoan.loanType === 'RBF' && detailedLoan.repaymentType === 'Monthly variable') {
      return getChartData(
        [loanSchedule?.disbursement as LoanScheduleType, ...(loanSchedule?.schedule ?? [])],
        detailedLoan.principalAmount +
          detailedLoan.interestAmount +
          detailedLoan.netPayablePenalties,
      );
    } else return null;
  }, [detailedLoan, loanSchedule]);

  const mappedLoanSchedules = useMemo(() => {
    if (!loanSchedule) return [];

    let schedule: Array<LoanScheduleType> = loanSchedule.schedule;

    if (filterOnlyForecasted) {
      schedule = schedule.filter(
        (schedule) => Boolean(schedule.totalForecasted) || Boolean(schedule.totalExpected),
      );
    }

    return [loanSchedule?.disbursement, ...schedule];
  }, [loanSchedule, filterOnlyForecasted]);

  const fetchDetailedLoanSchedule = () => {
    dispatch(getDetailedLoanSchedule({ loanId: params.loanId ?? '' }));
  };

  const { tableHeaders, searchParams, handleSort } = useSortingParams(
    emptyArr,
    fetchDetailedLoanSchedule,
    mappedColumnIdsToNames,
    rawTableHeaders,
  );

  const toggleForecastedFiltering = () => {
    setFilterOnlyForecasted((value) => !value);
  };

  return (
    <>
      {chartData ? (
        <ResponsiveContainer width={'100%'} height={'100%'} maxHeight={500}>
          <LineChart data={chartData}>
            <Line
              dataKey={'forecasted'}
              type='monotone'
              stroke='#0036B3'
              dot={false}
              strokeDasharray={'1 0 4'}
              strokeWidth={1.5}
            />
            <Line dataKey={'actual'} dot={false} type='monotone' stroke='#6FAE1E' strokeWidth={3} />
            <CartesianGrid />
            <XAxis dataKey={'month'} />
            <YAxis />
          </LineChart>
        </ResponsiveContainer>
      ) : null}
      <HeaderWrapper>
        <Typography variant='h3'>Loan Schedule</Typography>
        {detailedLoan.loanType === 'RBF' && detailedLoan.repaymentType === 'Monthly variable' ? (
          <ControlsWrapper>
            <Checkbox
              label='Show only with forecast'
              checked={filterOnlyForecasted}
              onChange={toggleForecastedFiltering}
            />
            {/* <Button variant='outlined'>Edit forecast</Button> */}
          </ControlsWrapper>
        ) : null}
      </HeaderWrapper>
      <Table
        data={mappedLoanSchedules}
        metaData={defaultMetaData}
        tableHeaders={tableHeaders}
        sortBy={searchParams.get('sortBy') ?? ''}
        orderBy={(searchParams.get('orderBy') as 'asc' | 'desc') ?? 'asc'}
        removableColumns={[]}
        sortFields={emptyArr}
        onSort={handleSort}
        onSelect={() => null}
        renderCells={renderCells}
        disablePagination
      />
    </>
  );
};
