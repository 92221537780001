import { styled } from 'theme';

export const Wrapper = styled('div')`
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 1500;
`;
