import React from 'react';
import { Breadcrumbs } from './Breadcrumbs.styles';
import { Link } from 'react-router-dom';
import { CustomerWithLinks } from 'lms-types';

type Props = {
  customer: CustomerWithLinks;
};

export const BreadcrumbsComponent = ({ customer }: Props) => {
  return (
    <Breadcrumbs aria-label='breadcrumb'>
      <Link color='inherit' to='/'>
        Customers
      </Link>
      <Link to={`/customers/${customer.companyIdentifier}`}>{customer.companyLegalName}</Link>
    </Breadcrumbs>
  );
};
